import React, { Component } from 'react'
import { GenDashboard, VehicleDashboardCounts2, RefuelStatusTypesCount, SiteAccessDashboardCounts, StatusTypesCount, 
    getVehicleMonthlyMillage, getRoutingMonthlyMillage, getRefuelMonthlyMillage, getPgMonthlyMillage, getPisMonthlyTarget,
    pisCompletionVsPending, routingServiceStatusCount, completionVsPendingChart, routingDashCount, getAllRolesByLoggedUser} from "../api/index";
import MultipleRadialBars from '../components/charts/multiple-radialBars'
import LineCharts from '../components/charts/line-charts'
import SimplePieChart from '../components/charts/simple-pie-chart'
import SimpleDonutChart from '../components/charts/simple-donut-chart'
import DonutWithPattern from '../components/charts/donut-with-pattern'
import GradientDonutChart from '../components/charts/gradient-donut-chart'
import BasicRadialBarChart from '../components/charts/basic-radial-bar-chart'
import SpeedMeterChat from '../components/charts/speed-meter'
import SemiCircleGaugeChat from '../components/charts/semi-circle-gauge'
import GaugeChart from 'react-gauge-chart'
import { Select, DatePicker, Space } from "antd";
import { Line, Pie, Column } from '@ant-design/plots';
import moment from 'moment';
import { getDonationCategories, getData } from "../actions/index";
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import { Badge, Card, Col } from 'antd';
import { WifiOutlined, UserOutlined, UsergroupAddOutlined, HomeOutlined, GlobalOutlined, EnvironmentOutlined } from '@ant-design/icons';

import ReactApexChart from 'react-apexcharts'
const { RangePicker } = DatePicker;

class DashboardGen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            setLogin: false,
            getAllCount: [],
            pendingCountArray: [],
            appVisionUserCount: [],
            monthltyReqCount: [],
            allReqCountArray: [],
            allPGReqCountArray: [],
            allVehicleReqCountArray: [],
            allRefuleReqCountArray: [],
            allSiteAccessReqCountArray: [],
            
            allPisSCReqCountArray: [],
            allPisACReqCountArray: [],
            allPisCRReqCountArray: [],
            allImageReqCountArray: [],
            allRoutineReqCountArray: [],
            allFuelProcumentReqCountArray: [],
            
            requestsOverviewData: [],
            selectReqType: "All",
            latasVersionappVisionUserCount: null,

            loadVehicle: false,
            loadRefule: false,
            loadSiteAccess: false,
            loadPg: false,
            loadPendingReq: false,
            loadAppVersion: false,
            loadAllReqLoading: true,
            vehicleSeriesCount: [],
            vehicleSerieslabels: [],
            refuleSeriesCount: [],
            refuleSerieslabels: [],
            siteAccessSeriesCount: [],
            siteAccessSerieslabels: [],
            reqPendingSeriesCount: [],
            reqPendingSerieslabels: [],
            reqPendingTotal: [],
            pGSeriesCount: [],
            pGSerieslabels: [],
            appVersionCount: [],
            appVersionlabels: [],
            selectedDataFrom: moment(moment().format("YYYY-MM-DD").slice(0, -2) + '01').format("YYYY-MM-DD"),
            selectedDataTo: moment().format("YYYY-MM-DD"),
            vehicleMilageChart: false,
            vehicleMilageChartValue: null,
            vehicleMilageChartTarget: null,
            vehicleMilageChartRealValue: null,
            refuelMilageChart: false,
            refuelMilageChartValue: null,
            refuelMilageChartTarget: null,
            refuelMilageChartRealValue: null,
            routineMilageChart: false,
            routineMilageChartValue: null,
            routineMilageChartTarget: null,
            routineMilageChartRealValue: null,
            pgMilageChart: false,
            pgMilageChartValue: null,
            pgMilageChartTarget: null,
            pgMilageChartRealValue: null,
            pisSiteCleaningChart: false,
            pisSiteCleaningChartValues: null,
            pisSiteCleaningChartTarget: null,
            pisSiteCleaningChartRealValue: null,
            pisAcChartValues: null,
            pisAcChart: false,
            pisAcChartTarget: null,
            pisAcChartRealValue: null,
            pisGenChartValues: null,
            pisGenChart: false,
            pisGenChartTarget: null,
            pisGenChartRealValue: null,
            pisCrChartValues: null,
            pisCrChart: false,
            pisCrChartTarget: null,
            pisCrChartRealValue: null,

            complitionVsPendingSC: null,
            complitionVsPendingSCShow: false,

            complitionVsPendingGEN: null,
            complitionVsPendingGENShow: false,

            complitionVsPendingAC: null,
            complitionVsPendingACShow: false,

            complitionVsPendingSCR: null,
            complitionVsPendingSCRhow: false,

            routingPieChartData: null,
            routingPieChartDataShow: false,
        };
    }
    


    componentDidMount() {
        if (JSON.parse(localStorage.getItem('serviceTypeSet')) === null) {
            this.setState({ setLogin: true });
        }

        this.getAllRolesByLoggedUserFunction();
        this.executeAsyncFunctions();
    }

    executeAsyncFunctions = async () => {
        try {
            await this.props.getData();
            await this.getAllDashboardData();
            
            await this.chartPendingReq();
            
            await this.getVehicleDashboardCout();
            
            await this.getRefuelDashboardCout();
            
            await this.getSiteAccessDashboardCout();
            
            await this.getPGDashboardCout();
            await this.getDataVehicleMonthlyMillage();

            await this.getDataRoutingMonthlyMillage();
            await this.getDataRefuelMonthlyMillage();
            await this.getDataPgMonthlyMillage();
            await this.getDataPisMonthlyTarget();

            await this.fetchPISCompletionVsPending();
            await this.fetchRoutingServiceStatusCount();
            await this.getNewPieChartData();
            await this.getNewPieChartDataRouting();
        } catch (error) {
            console.error("Error executing async functions:", error);
        }
    };

    getAllRolesByLoggedUserFunction = async () => {
        const response = await getAllRolesByLoggedUser(localStorage.getItem('userId'));
        if (response.success) {
            localStorage.setItem('vehicle_permissions', JSON.stringify(response?.data?.results));
        }
    }

    fetchPISCompletionVsPending = async () => {
        const res = await pisCompletionVsPending();
        console.log('pisCompletionVsPending');
        console.log(res);
        if (res.success) {
            //
        }
    };

    fetchRoutingServiceStatusCount = async () => {
        const res = await routingServiceStatusCount();
        console.log('routingServiceStatusCount');
        console.log(res);
        if (res.success) {
            //
        }
    };
    
    getDataPisMonthlyTarget = async () => {
        var res = await getPisMonthlyTarget();
        console.log('getPisMonthlyTarget')
        console.log(res)
        if (res.success) {

            if (res?.data?.sc != null) {
                let setvaluess = (res?.data?.sc?.value / res?.data?.sc?.target) * 100

                console.log('setvaluess' + setvaluess)
                this.setState({
                    pisSiteCleaningChartValues: setvaluess.toFixed(2),
                    pisSiteCleaningChartTarget: res?.data?.sc?.target,
                    pisSiteCleaningChartRealValue: res?.data?.sc?.value,
                }, () => {
                    this.setState({
                        pisSiteCleaningChart: true
                    })
                })
            }
            if (res.data?.ac != null) {
                let setvaluessac = (res.data?.ac.value / res.data?.ac.target) * 100

                console.log('setvaluessac' + setvaluessac)
                this.setState({
                    pisAcChartValues: setvaluessac.toFixed(2),
                    pisAcChartTarget: res.data?.ac.target,
                    pisAcChartRealValue: res.data?.ac.value,
                }, () => {
                    this.setState({
                        pisAcChart: true
                    })
                })
            }
            if (res.data?.gen != null) {
                let setvaluessgen = (res.data?.gen.value / res.data?.gen.target) * 100

                console.log('setvaluessgen' + setvaluessgen)
                this.setState({
                    pisGenChartValues: setvaluessgen.toFixed(2),
                    pisGenChartTarget: res.data?.gen.target,
                    pisGenChartRealValue: res?.data?.gen?.value,
                }, () => {
                    this.setState({
                        pisGenChart: true
                    })
                })
            }
            if (res.data?.cr != null) {
                let setvaluesscr = (res?.data?.cr.value / res?.data?.cr.target) * 100

                console.log('setvaluesscr' + setvaluesscr)
                this.setState({
                    pisCrChartValues: setvaluesscr.toFixed(2),
                    pisCrChartTarget: res.data?.cr.target,
                    pisCrChartRealValue: res.data?.cr.value,
                }, () => {
                    this.setState({
                        pisCrChart: true
                    })
                })
            }
        }
    }

    getNewPieChartData = async (requestData) => {
        this.setState({
            complitionVsPendingSCShow:false,
            complitionVsPendingGENShow: false,
            complitionVsPendingACShow: false,
            complitionVsPendingSCRhow: false,
            vehiclePieChartDataShow: null,
            refuelPieChartDataShow: null,
            siteAccessPieChartDataShow: null,
            pgPieChartDataShow: null,
           
            complitionVsPendingSC: null,
            complitionVsPendingGEN: null,
            complitionVsPendingAC: null,
            complitionVsPendingSCR: null,
        })
 
        var response = await completionVsPendingChart(requestData);
        try {
this.setState({
    complitionVsPendingSC: [
        response.data?.sc?.service_completed ? response.data?.sc?.service_completed : 0,
        response.data?.sc?.approval_completed ? response.data?.sc?.approval_completed : 0,
        response.data?.sc?.pending ? response.data?.sc?.pending : 0,
        response.data?.sc?.cancel ? response.data?.sc?.cancel : 0
    ],
    complitionVsPendingSCShow: true,
   
    complitionVsPendingGEN: [
        response.data?.gen?.service_completed ? response.data?.gen?.service_completed : 0,
        response.data?.gen?.approval_completed ? response.data?.gen?.approval_completed : 0,
        response.data?.gen?.pending ? response.data?.gen?.pending : 0,
        response.data?.gen?.cancel ? response.data?.gen?.cancel : 0
    ],
    complitionVsPendingGENShow: true,
   
    complitionVsPendingAC: [
        response.data?.ac?.service_completed ? response.data?.ac?.service_completed : 0,
        response.data?.ac?.approval_completed ? response.data?.ac?.approval_completed : 0,
        response.data?.ac?.pending ? response.data?.ac?.pending : 0,
        response.data?.ac?.cancel ? response.data?.ac?.cancel : 0
    ],
    complitionVsPendingACShow: true,
   
    complitionVsPendingSCR: [
        response.data?.cr?.service_completed ? response.data?.cr?.service_completed : 0,
        response.data?.cr?.approval_completed ? response.data?.cr?.approval_completed : 0,
        response.data?.cr?.pending ? response.data?.cr?.pending : 0,
        response.data?.cr?.cancel ? response.data?.cr?.cancel : 0
    ],
    complitionVsPendingSCRhow: true,
});
        } catch(error){
            console.log(response);
        }
    }

getNewPieChartDataRouting = async (data) => {
    this.setState({
        routingPieChartDataShow: false,
        routinePieChartData: null,
    })
    var response = await routingDashCount(data);
    try {
        this.setState({
            routingPieChartData: [
                response.data?.compleated ? response.data?.compleated : 0,
                response.data?.reviewed ? response.data?.reviewed : 0,
                response.data?.partialyCompleated ? response.data?.partialyCompleated : 0],
            routingPieChartDataShow: true,
        });
    } catch(error){
        console.log(response);
    }
}
getRefuelDashboardCout = async (data) => {
    this.setState({
        loadRefule: false
    })
    var res = await RefuelStatusTypesCount(data);
    if (res.success) {
        let result = [
            res.data[0]["filling_pending"] || 0,
            res.data[0]["approval_pending"] || 0,
            res.data[0]["filled"] || 0,
            res.data[0]["cancel"] || 0,
        ];
        this.setState({
            refuleSeriesCount: result,
            refuleSerieslabels: ["Filling Pending", "Approval Pending", "Filled", "Cancelled"]
        }, () => { this.setState({ loadRefule: true }) })

    }
};

getPGDashboardCout = async (form) => {
    this.setState({
        loadPg: false
    })
    var res = await StatusTypesCount(form);
    if (res.success) {
        let result = [
            res.data[0]["REQUESTED"] || 0,
            res.data[0]["INSTALLED"] || 0,
            res.data[0]["REMOVED"] || 0,
            res.data[0]["CANCELLED"] || 0,
        ];
        this.setState({
            pGSeriesCount: result,
            pGSerieslabels: ["Requested", "Installed", "Removed", "Cancelled"]
        }, () => {
            this.setState({
                loadPg: true
            })
        })
        console.log(result)
    }
};

    refecting = (date, dateString) => {
        this.setState({
            vehiclePieChartDataShow: false,
            refuelPieChartDataShow: false,
            siteAccessPieChartDataShow: false,
            pgPieChartDataShow: false,
            complitionVsPendingSC: null,
            complitionVsPendingGEN: null,
            complitionVsPendingAC: null,
            complitionVsPendingSCR: null, 
        });
        console.log(date, dateString);
        const requestData = new FormData();
        requestData.append('year', date.format('YYYY'));
        requestData.append('month', date.format('MM'));

        this.getNewPieChartDataRouting(requestData);
        this.getRefuelDashboardCout(requestData);
        this.getNewPieChartData(requestData);
        this.getPGDashboardCout(requestData);

        const firstDayofmonth = date.clone().startOf('month').format('YYYY-MM-DD');
        const lastDayOfMonth = date.clone().endOf('month').format('YYYY-MM-DD');
        this.getVehicleDashboardCout(firstDayofmonth, lastDayOfMonth);
    }
    
    getDataPgMonthlyMillage = async () => {
        var res = await getPgMonthlyMillage();

        console.log('getPgMonthlyMillage')
        console.log(res)
        if (res.success) {

            let setvaluess = (res.data?.value / res.data?.target)

            console.log('setvaluess' + setvaluess)
            this.setState({
                pgMilageChartValue: setvaluess,
                pgMilageChartRealValue: res.data?.value,
                pgMilageChartTarget: res.data?.target
            }, () => {
                this.setState({
                    pgMilageChart: true
                })
            })
        }
    }

    getDataRefuelMonthlyMillage = async () => {
        var res = await getRefuelMonthlyMillage();

        console.log('getRefuelMonthlyMillage')
        console.log(res)
        if (res.success) {

            let setvaluess = (res.data?.value / res.data?.target)

            console.log('setvaluessgetRefuelMonthlyMillage' + setvaluess)
            this.setState({
                refuelMilageChartValue: setvaluess,
                refuelMilageChartTarget: res.data?.target,
                refuelMilageChartRealValue: res.data?.value,

            }, () => {
                this.setState({
                    refuelMilageChart: true
                })
            })
        }
    }

    getDataRoutingMonthlyMillage = async () => {
        var res = await getRoutingMonthlyMillage();

        console.log('getRoutingMonthlyMillage')
        console.log(res)
        if (res.success) {
            let setvaluess = (res.data?.value / res.data?.target) * 100


            this.setState({
                routineMilageChartValue: setvaluess.toFixed(2),
                routineMilageChartTarget: res.data?.target,
                routineMilageChartRealValue: res.data?.value,

            }, () => {
                this.setState({
                    routineMilageChart: true
                })
            })
        }
    }


    getDataVehicleMonthlyMillage = async () => {
        var res = await getVehicleMonthlyMillage();

        console.log('getVehicleMonthlyMillage')
        console.log(res)
        if (res.success) {

            let setvaluess = (res.data?.value / res.data?.target)


            this.setState({
                vehicleMilageChartValue: setvaluess,
                vehicleMilageChartTarget: res.data?.target,
                vehicleMilageChartRealValue: res.data?.value,
            }, () => {
                this.setState({
                    vehicleMilageChart: true
                })
            })

        }
    }
    chartPendingReq = async () => {

    }

    getSiteAccessDashboardCout = async () => {
        var data = new FormData();

        data.append('scheduleDateFrom', this.state.selectedDataFrom);
        data.append('scheduleDateTo', this.state.selectedDataTo);
        data.append('regions', '');
        data.append('subregions', '');
        data.append('depots', '');
        var res = await SiteAccessDashboardCounts(data);

        console.log('SiteAccessDashboardCounts')
        console.log(res)
        if (res.success) {
            let arraySet = res?.data
            delete arraySet?.total;
            delete arraySet?.NO_NEED_ACCESS_PERMITION;
            delete arraySet?.GET_APPROVAL_FOR_EDIT_BY_USER;
            delete arraySet?.ACCESS_COMPLEATE;
            delete arraySet?.USER_CANCEL_REQUEST;
            console.log('SiteAccessDashboardCounts12')



            let str = JSON.stringify(Object.values(arraySet), (k, v) => v === null ? 0 : v);
            let resultofnewArray = JSON.parse(str);


            this.setState({
                siteAccessSeriesCount: resultofnewArray,
                siteAccessSerieslabels: ['Access Permission Received', 'Rejected by Site', 'Initiated', 'Rejected by Handler', 'Pending'],
            }, () => {
                this.setState({
                    loadSiteAccess: true
                })
            })
        }

    }

    getVehicleDashboardCout = async (firstDate, lastdate) => {
        this.setState({
            loadVehicle: false,
            vehicleSeriesCount: [],
        })
        var data = new FormData();
        data.append('scheduleDateFrom', firstDate ? firstDate : this.state.selectedDataFrom);
        data.append('scheduleDateTo', lastdate ? lastdate : this.state.selectedDataTo);
        data.append('regions', '');
        data.append('subregions', '');
        var res = await VehicleDashboardCounts2(data);

        // if (res.success) {
        //     let arraySet = res.data
        //     delete arraySet.drivers;
        //     delete arraySet.vehicles;
        // }
        if (res.success) {
            const cancelledData = Number(res?.data?.results?.cancelled);
            const completedData = Number(res?.data?.results?.completedJourneys);
            const ongoingData = Number(res?.data?.results?.ongoingJourneys);
            const dataArray = [ongoingData, cancelledData, completedData];
            this.setState({
                vehicleSeriesCount: dataArray,
                vehicleSerieslabels: ['Ongoing Journeys', 'Cancelled Journeys', 'Completed Journeys']
            }, () => {
                this.setState({
                    loadVehicle: true
                })
            })
        }
    }
    
    getAllDashboardData = async () => {
        var data = new FormData();
        data.append('monthlyDateFrom', moment().subtract(12, 'months').startOf('month').format('YYYY-MM-DD'));
        data.append('monthlyDateTo', moment().format("YYYY-MM-DD"));

        var res = await GenDashboard(data);
        console.log('GenDashboard11')

        console.log(res);

        if (res.success) {
            this.setState({
                getAllCount: res.data?.counts,
                pendingCountArray: res.data.pendingCounts,
                appVisionUserCount: res.data.appUseCount,
                monthltyReqCount: res.data.monthlyCounts,
            }, () => {
                console.log(this.state.getAllCount.regions)
                this.setPendingReqCout(this.state.pendingCountArray)
                this.appUseCountList(this.state.appVisionUserCount)
                this.monthlyReqCountSet(this.state.monthltyReqCount)
            })
        }
    }
    
    monthlyReqCountSet(data) {
        console.log("RequestOverView Data Here ------------------------------------------------------------------------------")
        console.log(data)

        const mergedArray = [
            ...data.pg, 
            ...data.siteAccess, 
            ...data.refuel, 
            ...data.vehicle, 
            ...data.pisSC, 
            ...data.image, 
            ...data.pisAC, 
            ...data.pisCR, 
            ...data.routine, 
            ...data.fuelProcument];

        const changedData = mergedArray.map(item => ({
            month: `${item.year}-${item.month}`
            , count: item.count
        }));

        // all
        const result = changedData.reduce((acc, { month, count }) => ({ ...acc, [month]: { month, count: acc[month] ? acc[month].count + count : count } }), {});

        let sortedresult = Object.values(result).sort((a, b) => new Date(...a.month.split('/').reverse()) - new Date(...b.month.split('/').reverse()));
        //pg

        const pGchangedData = data.pg.map(item => ({
            month: `${item.year}-${item.month}`
            , count: item.count
        }));

        let sortedresultPg = pGchangedData.sort((a, b) => new Date(...a.month.split('/').reverse()) - new Date(...b.month.split('/').reverse()));
        // vehicle
        const vehiclechangedData = data.vehicle.map(item => ({
            month: `${item.year}-${item.month}`
            , count: item.count
        }));
        let sortedresultVehicle = vehiclechangedData.sort((a, b) => new Date(...a.month.split('/').reverse()) - new Date(...b.month.split('/').reverse()));
        // SiteAccess
        const siteAccessChangedData = data.siteAccess.map(item => ({
            month: `${item.year}-${item.month}`
            , count: item.count
        }));
        let sortedresultSiteAccess = siteAccessChangedData.sort((a, b) => new Date(...a.month.split('/').reverse()) - new Date(...b.month.split('/').reverse()));
        //Reful
        const refuelChangedData = data.refuel.map(item => ({
            month: `${item.year}-${item.month}`
            , count: item.count
        }));
        let sortedresultRefule = refuelChangedData.sort((a, b) => new Date(...a.month.split('/').reverse()) - new Date(...b.month.split('/').reverse()));

        // pisSC
        const pisSCChangedData = data.pisSC.map(item => ({
            month: `${item.year}-${item.month}`
            , count: item.count
        }));
        let sortedresultPisSC = pisSCChangedData.sort((a, b) => new Date(...a.month.split('/').reverse()) - new Date(...b.month.split('/').reverse()));

        // pisAC
        const pisACChangedData = data.pisAC.map(item => ({
            month: `${item.year}-${item.month}`
            , count: item.count
        }));
        let sortedresultPisAC = pisACChangedData.sort((a, b) => new Date(...a.month.split('/').reverse()) - new Date(...b.month.split('/').reverse()));

        // pisCR
        const pisCRChangedData = data.pisCR.map(item => ({
            month: `${item.year}-${item.month}`
            , count: item.count
        }));
        let sortedresultPisCR = pisCRChangedData.sort((a, b) => new Date(...a.month.split('/').reverse()) - new Date(...b.month.split('/').reverse()));

        // image
        const imageChangedData = data.image.map(item => ({
            month: `${item.year}-${item.month}`
            , count: item.count
        }));
        let sortedresultImage = imageChangedData.sort((a, b) => new Date(...a.month.split('/').reverse()) - new Date(...b.month.split('/').reverse()));

        // routine
        const routineChangedData = data.routine.map(item => ({
            month: `${item.year}-${item.month}`
            , count: item.count
        }));
        let sortedresultRoutine = routineChangedData.sort((a, b) => new Date(...a.month.split('/').reverse()) - new Date(...b.month.split('/').reverse()));

        // fuelProcument
        const fuelProcumentChangedData = data.fuelProcument.map(item => ({
            month: `${item.year}-${item.month}`
            , count: item.count
        }));
        let sortedresultFuelProcument = fuelProcumentChangedData.sort((a, b) => new Date(...a.month.split('/').reverse()) - new Date(...b.month.split('/').reverse()));


        console.log("data.pg")
        console.log(sortedresultRefule)

        this.setState({
            requestsOverviewData: sortedresult,
            allReqCountArray: sortedresult,
            allPGReqCountArray: sortedresultPg,
            allRefuleReqCountArray: sortedresultRefule,
            allSiteAccessReqCountArray: sortedresultSiteAccess,
            allVehicleReqCountArray: sortedresultVehicle,

            allPisSCReqCountArray: sortedresultPisSC,
            allPisACReqCountArray: sortedresultPisAC,
            allPisCRReqCountArray: sortedresultPisCR,
            allImageReqCountArray: sortedresultImage,
            allRoutineReqCountArray: sortedresultRoutine,
            allFuelProcumentReqCountArray: sortedresultFuelProcument,

        }, () => {
            this.setState({
                loadAllReqLoading: false
            })
        })


    }
    appUseCountList(data) {
        let fullLength = data.length;
        console.log("fullLength")

        let newSetArray = data.sort((a, b) => (a.version < b.version) ? 1 : -1)
        const latastFiveVersionAarray = newSetArray.slice(0, 5);

        const OtherVersionArray = newSetArray.slice(5, fullLength);

        let otherVersionUserCount = OtherVersionArray.reduce((a, v) => a = a + v.count, 0)


        let newArra = [...latastFiveVersionAarray, { count: otherVersionUserCount, version: 'other' }]
        let resultsireas = newArra.map(option => option.count);
        let resultLables = newArra.map(option => option.version);
        console.log(resultsireas)
        console.log(resultLables)
        this.setState({
            latasVersionappVisionUserCount: newArra,
            appVersionCount: resultsireas,
            appVersionlabels: resultLables,
        }, () => {
            console.log(this.state.latasVersionappVisionUserCount)
            this.setState({
                loadAppVersion: true
            })
        })

    }
    setPendingReqCout(dataSet) {
        delete dataSet.pisACCount;
        delete dataSet.pisCRCount;
        delete dataSet.pisSCCount;

        const data = dataSet

        const itemCopy = { ...data };
        const keys = Object.keys(itemCopy).filter(key => key !== "date");
        const sum = keys.reduce((sum, key) => sum + itemCopy[key], 0);
        console.log(sum)
        keys.forEach(key => itemCopy[key] = Math.round(100 * itemCopy[key] / sum));
        // itemCopy.top3 = keys.sort((key1, key2) => data[key2] - data[key1]).slice(0, 4); // Asked in comment below
        console.log(itemCopy)
        this.setState({
            reqPendingSeriesCount: Object.values(itemCopy),
            reqPendingSerieslabels: ['PG', 'Vehicle', 'Site Access', 'Refuel'],
            reqPendingTotal: sum,
        }, () => {
            this.setState({
                loadPendingReq: true
            })
        })
    }
    dataonChange = (date, dateString) => {
        this.setState({
            loadVehicle: false,
            loadRefule: false,
            loadSiteAccess: false,
            loadPg: false,
        })

        console.log(dateString);
        this.setState({
            selectedDataFrom: dateString[0],
            selectedDataTo: dateString[1],
        }, () => {
            this.getVehicleDashboardCout()
            this.getRefuelDashboardCout()
            this.getSiteAccessDashboardCout()
            this.getPGDashboardCout();
        })
        // this.setState({
        //   filter_date: dateString[0],
        //   filter_date_to: dateString[1],
        // });
    }


    chengeReq = (name) => {
        this.setState({
            loadAllReqLoading: true
        })
        if (name == 'pg') {
            this.setState({
                requestsOverviewData: this.state.allPGReqCountArray,
                selectReqType: "PG",

            }, () => {
                this.setState({
                    loadAllReqLoading: false
                })
            })
        }
        if (name == 'all') {
            this.setState({
                requestsOverviewData: this.state.allReqCountArray,
                selectReqType: "All",

            }, () => {
                this.setState({
                    loadAllReqLoading: false
                })
            })
        }
        if (name == 'siteAccess') {
            this.setState({
                requestsOverviewData: this.state.allSiteAccessReqCountArray,
                selectReqType: "Site Access",

            }, () => {
                this.setState({
                    loadAllReqLoading: false
                })
            })
        }
        if (name == 'refuel') {
            this.setState({
                requestsOverviewData: this.state.allRefuleReqCountArray,
                selectReqType: "Refuel",

            }, () => {
                this.setState({
                    loadAllReqLoading: false
                })
            })
        }
        if (name == 'vehicle') {
            this.setState({
                requestsOverviewData: this.state.allVehicleReqCountArray,
                selectReqType: "Vehicle",

            }, () => {
                this.setState({
                    loadAllReqLoading: false
                })
            })
        }
        if (name == 'pisSC') {
            this.setState({
                requestsOverviewData: this.state.allPisSCReqCountArray,
                selectReqType: "PIS SC",

            }, () => {
                this.setState({
                    loadAllReqLoading: false
                })
            })
        }
        if (name == 'pisAC') {
            this.setState({
                requestsOverviewData: this.state.allPisACReqCountArray,
                selectReqType: "PIS AC",

            }, () => {
                this.setState({
                    loadAllReqLoading: false
                })
            })
        }
        if (name == 'pisCR') {
            this.setState({
                requestsOverviewData: this.state.allPisCRReqCountArray,
                selectReqType: "PIS CR",

            }, () => {
                this.setState({
                    loadAllReqLoading: false
                })
            })
        }
        if (name == 'image') {
            this.setState({
                requestsOverviewData: this.state.allImageReqCountArray,
                selectReqType: "Image",

            }, () => {
                this.setState({
                    loadAllReqLoading: false
                })
            })
        }
        if (name == 'routine') {
            this.setState({
                requestsOverviewData: this.state.allRoutineReqCountArray,
                selectReqType: "Routine",

            }, () => {
                this.setState({
                    loadAllReqLoading: false
                })
            })
        }
        if (name == 'fuelProcument') {
            this.setState({
                requestsOverviewData: this.state.allFuelProcumentReqCountArray,
                selectReqType: "Fuel Procument",

            }, () => {
                this.setState({
                    loadAllReqLoading: false
                })
            })
        }
    }

    // get persentage and make it last two decimal points
    presentatageCount = (max, min) => {
        let result = ((min / max) * 100).toFixed(2)
        return result
    }

    formatNumberWithCommas(number) {
        if (typeof number !== 'number') {
            return 'Invalid input';
        }
        return number.toLocaleString();
    }

    render() {
        const config = {
            data: this.state.requestsOverviewData,
            xField: 'month',
            yField: 'count',
            height: 283,
            label: {},
            point: {
                size: 5,
                shape: "circle",
                radius: 3,
                style: {
                    fill: 'white',
                    stroke: '#5B8FF9',
                    lineWidth: 4,
                    height: 280
                },
            },
            stroke: {
                show: true,
                curve: 'straight',
                colors: '#2D5FF4',
                width: 4,
            },
            tooltip: {
                showMarkers: false,
            },
            state: {
                active: {
                    style: {
                        shadowBlur: 4,
                        stroke: '#000',
                        fill: 'red',
                    },
                },
            },
            interactions: [
                {
                    type: 'marker-active',
                },
            ],
        };

        
        const { vehicleSeriesCount } = this.state
        const { vehicleSerieslabels } = this.state
        const { refuleSeriesCount } = this.state
        const { refuleSerieslabels } = this.state
        const { siteAccessSeriesCount } = this.state
        const { siteAccessSerieslabels } = this.state
        const { pGSeriesCount } = this.state
        const { pGSerieslabels } = this.state
        const { reqPendingSeriesCount } = this.state
        const { reqPendingSerieslabels } = this.state
        const { reqPendingTotal } = this.state
        const { appVersionCount } = this.state
        const { appVersionlabels } = this.state
        const chartStyle = {
            height: 250,
        }
        return (
            <>
                {this.state.setLogin ? (<div className="container-fluid">
                    {
                        setTimeout(function () {
                            window.location.reload(false);
                        }, 1000)
                    }
                    <br></br>
                    <div className="row">
                        <div class="col-lg-12" style={{ textAlign: 'center' }}>
                            <div class="spinner-border spinner-border-custom-2 text-primary" role="status"></div>
                        </div>
                    </div>
                </div>) :
                    (<div class="container-fluid">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="page-title-box">
                                    <div class="row">
                                        <div class="col">
                                            <h4 class="page-title">Dashboard</h4>
                                            <ol class="breadcrumb">
                                                <li class="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                                <li class="breadcrumb-item"><a href="javascript:void(0);">General</a></li>
                                            </ol>
                                        </div>
                                        <div class="col-auto align-self-center">
                                            <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                                <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                                <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                            </a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div class="row justify-content-center">
                                <div class="col-md-6 col-lg-2">
                                    <div class="report-card bg-soft-primary" style={{ borderRadius: '20px' }}>
                                        <div class="card-body">
                                            <div class="row d-flex justify-content-center">
                                                <div class="col">
                                                    <p class="mb-0 fw-semibold" style={
                                                        {
                                                            fontSize: '14px'
                                                        }
                                                    }><b>Regions <EnvironmentOutlined style={{ color: "#1761fd" }} /> </b></p>
                                                    <h2 class="m-0">{this.state.getAllCount.regions ? (this.formatNumberWithCommas(this.state.getAllCount.regions)) : (0)}</h2>
                                                    <a href='/region'><p class="mb-0 text-truncate mt-1" style={{ color: "#2d81fe" }}>
                                                        See More <i class="las la-arrow-right"></i> &nbsp; </p></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-2">
                                    <div class="report-card bg-soft-success" style={{ borderRadius: '20px' }}>
                                        <div class="card-body">
                                            <div class="row d-flex justify-content-center">
                                                <div class="col">
                                                    <p class=" mb-0 fw-semibold" style={
                                                        {
                                                            fontSize: '14px'
                                                        }
                                                    }><b>Sub Regions <GlobalOutlined style={{ color: "#03d87f" }} /> </b></p>
                                                    <h2 class="m-0">{this.state.getAllCount.subRegions ? (this.formatNumberWithCommas(this.state.getAllCount.subRegions)) : (0)}</h2>
                                                    <a href='/region-list'><p class="mb-0 text-truncate mt-1" style={{ color: "#2d81fe" }}>See More <i class="las la-arrow-right"></i> &nbsp; </p></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-2">
                                    <div class="report-card bg-soft-pink" style={{ borderRadius: '20px' }}>
                                        <div class="card-body">
                                            <div class="row d-flex justify-content-center">
                                                <div class="col">
                                                    <p class="mb-0 fw-semibold" style={
                                                        {
                                                            fontSize: '14px'
                                                        }
                                                    }><b>Depots <HomeOutlined style={{ color: "#fd3c97" }} /></b></p>
                                                    <h2 class="m-0">{this.state.getAllCount.depots ? (this.formatNumberWithCommas(this.state.getAllCount.depots)) : (0)}</h2>
                                                    <a href='/depot'><p class="mb-0 text-truncate mt-1" style={{ color: "#2d81fe" }}>See More <i class="las la-arrow-right"></i> &nbsp;&nbsp;&nbsp;</p></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-2">
                                    <div class="report-card bg-soft-purple" style={{ borderRadius: '20px' }}>
                                        <div class="card-body">
                                            <div class="row d-flex justify-content-center">
                                                <div class="col">
                                                    <p class=" mb-0 fw-semibold" style={
                                                        {
                                                            fontSize: '14px'
                                                        }
                                                    }><b>Sites <WifiOutlined /></b></p>
                                                    <h2 class="m-0">{this.state.getAllCount.sites ? (this.formatNumberWithCommas(this.state.getAllCount.sites)) : (0)}</h2>
                                                    <a href='/site'><p class="mb-0 text-truncate mt-1" style={{ color: "#2d81fe" }} > See More  <i class="las la-arrow-right"></i> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-2">
                                    <div class="report-card bg-soft-warning" style={{ borderRadius: '20px' }}>
                                        <div class="card-body">
                                            <div class="row d-flex justify-content-center">
                                                <div class="col">
                                                    <p class=" mb-0 fw-semibold" style={
                                                        {
                                                            fontSize: '14px'
                                                        }
                                                    }><b>Users <UserOutlined style={{ color: "#ffb822" }} /></b></p>
                                                    <h2 class="m-0">{this.state.getAllCount.user ? (this.formatNumberWithCommas(this.state.getAllCount.user)) : (0)}</h2>
                                                    <a href='/users'><p class="mb-0 text-truncate mt-1" style={{ color: "#2d81fe" }}>See More <i class="las la-arrow-right"></i> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-2">
                                    <div class="report-card bg-soft-info" style={{ borderRadius: '20px' }}>
                                        <div class="card-body">
                                            <div class="row d-flex justify-content-center">
                                                <div class="col">
                                                    <p class=" mb-0 fw-semibold" style={
                                                        {
                                                            fontSize: '14px'
                                                        }
                                                    }><b>Vendors <UsergroupAddOutlined style={{ color: "#12a4ed" }} /></b></p>
                                                    <h2 class="m-0">{this.state.getAllCount.vendors ? (this.formatNumberWithCommas(this.state.getAllCount.vendors)) : (0)}</h2>
                                                    <a href='/vendor'><p class="mb-0 text-truncate mt-1" style={{ color: "#2d81fe" }}>See More <i class="las la-arrow-right"></i> &nbsp;&nbsp;</p></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <div className='col-md-12'>
                            <div class="card">
                                <div class="card-header">
                                    <div class="row align-items-center">
                                        <div class="col-md-4">
                                            <h4 class="card-title" style={{
                                                fontWeight: "bold", fontFamily: 'Roboto'
                                            }}>App Statistics (.Vs Monthly Target)</h4>
                                        </div>
                                        <div className="container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <div className="col">
                                                <span><Badge color="#2D5FF4" text="Value" style={{ marginLeft: "10px", fontFamily: 'Roboto', fontSize: '9px', fontWeight: 'bold' }} /></span>
                                                <span style={{ marginLeft: "10px", fontFamily: 'Roboto', fontSize: '9px', fontWeight: 'bold' }}><Badge color="#42B02A" text="Target" /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div className='row'>
                                        <div class="col-md-6 col-lg-3" style={{ textAlign: "center" }} >
                                            {this.state.pgMilageChart ? (
                                                <SemiCircleGaugeChat
                                                    setcolor="#2D5FF4"
                                                    setvalues={this.presentatageCount(this.state.pgMilageChartTarget, this.state.pgMilageChartRealValue)}
                                                    setTitel="PG - Cumulative Cost"
                                                    realValue={this.state.pgMilageChartRealValue}
                                                    targetValue={this.state.pgMilageChartTarget}
                                                />
                                            ) : (
                                                console.log("PG - Cumulative  : " + this.presentatageCount(this.state.pgMilageChartTarget, this.state.pgMilageChartRealValue))
                                            )}
                                        </div>
                                        <div class="col-md-6 col-lg-3" style={{ textAlign: "center" }} >
                                            {this.state.refuelMilageChart ? (
                                                <SemiCircleGaugeChat
                                                    setcolor="#2D5FF4"
                                                    setvalues={this.presentatageCount(this.state.refuelMilageChartTarget, this.state.refuelMilageChartRealValue)}
                                                    setTitel="Fuel Filling - No.of Litres Filled"
                                                    realValue={this.state.refuelMilageChartRealValue}
                                                    targetValue={this.state.refuelMilageChartTarget} />) : ('')}
                                        </div>
                                        {/* <div class="col-md-6 col-lg-3" style={{ textAlign: "center" }} >
                                            {this.state.vehicleMilageChart ? (
                                                <SemiCircleGaugeChat
                                                    setcolor="#2D5FF4"
                                                    setvalues={this.presentatageCount(this.state.vehicleMilageChartTarget, this.state.vehicleMilageChartRealValue)}
                                                    setTitel="Vehicle Milage"
                                                    realValue={this.state.vehicleMilageChartRealValue}
                                                    targetValue={this.state.vehicleMilageChartTarget} />) : ('')}
                                        </div> */}
                                        <div class="col-md-6 col-lg-3" style={{ textAlign: "center" }} >
                                            {this.state.routineMilageChart ? (
                                                <SemiCircleGaugeChat
                                                    setcolor="#2D5FF4" setvalues={this.state.routineMilageChartValue}
                                                    setTitel="Site Routine - Completion"
                                                    realValue={this.state.routineMilageChartRealValue}
                                                    targetValue={this.state.routineMilageChartTarget} />) : ('')}
                                        </div>
                                        <div class="col-md-6 col-lg-3" style={{ textAlign: "center" }} >
                                            {this.state.pisSiteCleaningChart ? (
                                                <SemiCircleGaugeChat
                                                    setcolor="#2D5FF4"
                                                    setvalues={this.state.pisSiteCleaningChartValues}
                                                    setTitel="PIS Site Cleaning - Completion"
                                                    realValue={this.state.pisSiteCleaningChartRealValue}
                                                    targetValue={this.state.pisSiteCleaningChartTarget} />) : ('')}
                                        </div>
                                        <div class="col-md-6 col-lg-3" style={{ textAlign: "center" }} >
                                            {this.state.pisGenChart ? (
                                                <SemiCircleGaugeChat
                                                    setcolor="#2D5FF4"
                                                    setvalues={this.state.pisGenChartValues}
                                                    setTitel="PIS Gen.Service - Completion"
                                                    realValue={this.state.pisGenChartRealValue}
                                                    targetValue={this.state.pisGenChartTarget} />) : ('')}
                                        </div>
                                        <div class="col-md-6 col-lg-3" style={{ textAlign: "center" }} >
                                            {this.state.pisAcChart ? (
                                                <SemiCircleGaugeChat
                                                    setcolor="#2D5FF4"
                                                    setvalues={this.state.pisAcChartValues}
                                                    setTitel="PIS AC.Service - Completion"
                                                    realValue={this.state.pisAcChartRealValue}
                                                    targetValue={this.state.pisAcChartTarget} />) : ('')}
                                        </div>
                                        <div class="col-md-6 col-lg-3" style={{ textAlign: "center" }} >
                                            {this.state.pisCrChart ? (
                                                <SemiCircleGaugeChat
                                                    setcolor="#2D5FF4"
                                                    setvalues={this.state.pisCrChartValues}
                                                    setTitel="PIS Civil Routine - Completion"
                                                    realValue={this.state.pisCrChartRealValue}
                                                    targetValue={this.state.pisCrChartTarget} />) : ('')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div class="card">
                                    <div class="card-header">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <h4 class="card-title" style={{
                                        fontWeight: "bold", fontFamily: 'Roboto'}}>Request status</h4>
                                            </div>
                                            <div class="col-auto">
                                                <div class="ant-space-item">                                        
                                                    <DatePicker 
                                                    onChange={this.refecting} 
                                                    defaultValue={moment()} picker="month" 
                                                    style={{ width: "100%", borderRadius: 7 }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card-body">
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                {this.state.loadVehicle ? (
                                                    <SimpleDonutChart
                                                    chartLabale={'Vehicle'}
                                                    colorArray={['#FFB200', '#E34234', '#2D5FF4']}
                                                    y1={this.state.vehicleSeriesCount}
                                                    x1={this.state.vehicleSerieslabels} />) : ('')}
                                            </div>
                                            <div className='col-md-4'>
                                                {this.state.loadRefule ? (
                                                <SimpleDonutChart 
                                                chartLabale={'Refuel'}
                                                colorArray={['#FFB200', '#42B02A', '#2D5FF4', '#E34234', '#EB0029', '#CBC300']}
                                                y1={this.state.refuleSeriesCount} 
                                                x1={this.state.refuleSerieslabels} />) : ('')}
                                            </div>
                                            {/* <div className='col-md-6'>
                                                {this.state.loadSiteAccess ? (
                                                    <SimpleDonutChart
                                                    chartLabale={'Site Access'}
                                                    colorArray={['#FFB200', '#42B02A', '#2D5FF4', '#C624FF', '#EB0029', '#CBC300']}
                                                    y1={this.state.siteAccessSeriesCount}
                                                    x1={this.state.siteAccessSerieslabels} />) : ('')}
                                            </div> */}
                                            <div className='col-md-4'>
                                                {this.state.loadPg ? (
                                                    <SimpleDonutChart
                                                        chartLabale={'PG'}
                                                        colorArray={['#FFB200', '#42B02A', '#2D5FF4', '#E34234', '#EB0029', '#CBC300']}
                                                        y1={this.state.pGSeriesCount}
                                                        x1={this.state.pGSerieslabels} />) : ('')}
                                            </div>

                                            <div className='col-md-4'>
                                                {this.state.complitionVsPendingSCShow? (
                                                    <SimpleDonutChart
                                                        chartLabale={'Site Cleaning'}
                                                        colorArray={['#FFB200', '#42B02A', '#2D5FF4', '#E34234', '#EB0029', '#CBC300']}
                                                        y1={this.state.complitionVsPendingSC}
                                                        x1={["Service Completed", "Approval Completed", "Pending", "Cancel"]}
                                                    />) : ('')}
                                            </div>

                                            <div className='col-md-4'>
                                                {this.state.complitionVsPendingGENShow? (
                                                    <SimpleDonutChart
                                                        chartLabale={'Gen'}
                                                        colorArray={['#FFB200', '#42B02A', '#2D5FF4', '#E34234', '#EB0029', '#CBC300']}
                                                        y1={this.state.complitionVsPendingGEN}
                                                        x1={["Service Completed", "Approval Completed", "Pending", "Cancel"]}
                                                    />) : ('')}
                                            </div>

                                            <div className='col-md-4'>
                                                {this.state.complitionVsPendingACShow? (
                                                    <SimpleDonutChart
                                                        chartLabale={'AC'}
                                                        colorArray={['#FFB200', '#42B02A', '#2D5FF4', '#E34234', '#EB0029', '#CBC300']}
                                                        y1={this.state.complitionVsPendingAC}
                                                        x1={["Service Completed", "Approval Completed", "Pending", "Cancel"]}
                                                    />) : ('')}
                                            </div>

                                            <div className='col-md-4'>
                                                {this.state.complitionVsPendingSCRhow? (
                                                    <SimpleDonutChart
                                                        chartLabale={'Civil Routing'}
                                                        colorArray={['#FFB200', '#42B02A', '#2D5FF4', '#E34234', '#EB0029', '#CBC300']}
                                                        y1={this.state.complitionVsPendingSCR}
                                                        x1={["Service Completed", "Approval Completed", "Pending", "Cancel"]}
                                                    />) : ('')}
                                            </div>

                                            <div className='col-md-4'>
                                                {this.state.routingPieChartDataShow? (
                                                    <SimpleDonutChart
                                                        chartLabale={'Routing'}
                                                        colorArray={['#FFB200', '#42B02A', '#E34234', '#C624FF', '#EB0029', '#CBC300']}
                                                        y1={this.state.routingPieChartData}
                                                        x1={['Completed', 'Reviewed', 'Partialy Compleated']}
                                                    />) : ('')}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <h4 class="card-title" style={{
                                        fontWeight: "bold", fontFamily: 'Roboto'}}>Request Overview</h4>
                                            </div>
                                            <div class="col-auto">
                                                <div class="dropdown">
                                                    <a href="#" class="btn btn-sm btn-outline-light dropdown-toggle" data-bs-toggle="dropdown" 
                                                    aria-haspopup="true" aria-expanded="false">
                                                        {this.state.selectReqType} <i class="las la-angle-down ms-1"></i>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-menu-end">
                                                        <a class="dropdown-item" onClick={() => this.chengeReq('all')}>All</a>
                                                        <a class="dropdown-item" onClick={() => this.chengeReq('pg')}>PG</a>
                                                        <a class="dropdown-item" onClick={() => this.chengeReq('refuel')}>Refuel </a>
                                                        {/* <a class="dropdown-item" onClick={() => this.chengeReq('siteAccess')}>Site Access</a> */}
                                                        {/* <a class="dropdown-item" onClick={() => this.chengeReq('vehicle')}>Vehicle</a> */}
                                                        <a class="dropdown-item" onClick={() => this.chengeReq('pisSC')}>PIS Site Cleaning</a>
                                                        <a class="dropdown-item" onClick={() => this.chengeReq('pisAC')}>PIS Air Conditioning</a>
                                                        <a class="dropdown-item" onClick={() => this.chengeReq('pisCR')}>PIS Civil Routine</a>
                                                        <a class="dropdown-item" onClick={() => this.chengeReq('image')}>Image</a>
                                                        <a class="dropdown-item" onClick={() => this.chengeReq('routine')}>Routine</a>
                                                        <a class="dropdown-item" onClick={() => this.chengeReq('fuelProcument')}>Fuel Procument</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="">
                                            {<Line {...config}
                                                loading={this.state.loadAllReqLoading}
                                            />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='row'>
                                    {/* <div class="col-md-12">
                                        <div class="card">
                                            <div class="card-header">
                                                <h4 class="card-title" style={{
                                        fontWeight: "bold", fontFamily: 'Roboto'}}>Pending Requests</h4>
                                            </div>
                                            <div class="card-body">
                                                <div class="chart-demo m-0">
                                                    {this.state.loadPendingReq ? (
                                                    <MultipleRadialBars 
                                                    pendingReqSeries={{ reqPendingSeriesCount }} 
                                                    pendingReqLable={{ reqPendingSerieslabels }} 
                                                    colorArray={['#FFB200', '#42B02A', '#2D5FF4', '#C624FF', '#EB0029', '#CBC300']}
                                                    pendingReqTotal={{ reqPendingTotal }} />) : ('')}
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className='col-md-12'>
                                        <div class="card">
                                            <div class="card-header">
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <h4 class="card-title" style={{
                                        fontWeight: "bold", fontFamily: 'Roboto'}}>App Version Utilization</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="text-center">
                                                    <div id="ana_device" class="apex-charts">
                                                        {this.state.loadAppVersion ? (
                                                        <BasicRadialBarChart 
                                                        refuleSeries={{ appVersionCount }} 
                                                        refuleLable={{ appVersionlabels }} 
                                                        colorArray={['#FFB200', '#42B02A', '#CBC300', '#C624FF', '#EB0029', '#2D5FF4']}
                                                        />) : ('')}
                                                    </div>
                                                </div>
                                                {/* <div class="table-responsive mt-2">
                                                    <table class="table border-dashed mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th  style={{fontFamily: 'Roboto'}}>Version</th>
                                                                <th class="text-end"  style={{fontFamily: 'Roboto'}}>User Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.latasVersionappVisionUserCount !== null ? (
                                                                <>
                                                                    {this.state.latasVersionappVisionUserCount.map((x) => {
                                                                        return <tr>
                                                                            <td>V {x.version}</td>
                                                                            <td class="text-end">{x.count}</td>
                                                                        </tr>
                                                                    })}
                                                                </>
                                                            ) : ('')}
                                                        </tbody>
                                                    </table>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        organization_count: state.organization.length,
        regions_count: state.regions.length,
        depots_count: state.depots.length,
        sites_count: state.sites.length,
        vendor_count: state.vendor.length,
        roles_count: state.roles.length,
    };
}

export default withRouter(connect(mapStateToProps, { getDonationCategories, getData })(DashboardGen));


