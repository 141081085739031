// sample react template

import React, { useState, useEffect } from 'react';
import {
    CarOutlined,
    NodeIndexOutlined,
    EditOutlined,
    DeleteOutlined,
    EyeOutlined,
    CheckOutlined,
    DashboardOutlined,
    SearchOutlined,
    PhoneOutlined,
    PaperClipOutlined,
    UploadOutlined,
    DownloadOutlined,
    CloudSyncOutlined,
    ApiOutlined 
} from '@ant-design/icons';
import { Checkbox, Avatar, Card, Skeleton, Switch, Table, Select, Input, Button, Row, Upload, Col, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Image, Empty, Spin} from 'antd';
import { getPGStatusSummary, getLastThreeRequestDetails, getUserSites, getPisHistoryData, getSitesWithFilter, getPisHistoryDetailsById, getAllPisStatus, getPGDataView } from "../../api/index";
import { useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../master-data/style.css';
import moment from 'moment';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
const baseImgURL = "https://storage.googleapis.com/dns_storage_v2/requestimages/";


const PgDetailsAllView = () => {
    const { id } = useParams(); 
    const [pgData, setPgData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [currentRequest, setCurrentRequest] = useState(null);

    useEffect(() => {
        getPGStatusDataByID(id);
    }, []);

    const getPGStatusDataByID = async (id) => {
        try {
            const formData = new FormData();
            formData.append('serviceRequestId', id);
            const data = await getPGStatusSummary(formData);
            setPgData(data?.data);
        } catch (error) {
            console.error('Error fetching PG status data:', error);
        }
    }

    const mapStyles = {
        height: "200px",
        width: "100%",
        borderRadius: "5px"
    };


    return (
        <div class="container-fluid">
            <Row>
            {
                pgData?.map((item, index) => {
                    return (
                            <Col span={6}>
                                <Card 
                                hoverable={true}
                                size='small'
                                onClick={() => {
                                    setOpenModal(true);
                                    setCurrentRequest(item);
                                }}
                                title={<span className='textStyles-small'>{item.statusName}</span>} 
                                bordered={false} 
                                style={{borderRadius: '10px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', padding: '10px', margin: '10px'}}>
                                    <span className='textStyles-small'>By: {item.createdUserName ? item.createdUserName : 'Did not perform yet'}</span><br/>
                                    <span className='textStyles-small'>On: {moment(item.createdOn).format('DD/MM/YYYY hh:mm A')}</span><br/>
                                </Card>
                            </Col>
                    );
                })
            }
            </Row>
            <Modal
                title={<span className='textStyles-small' style={{fontSize: 13}}>Request Details</span>}
                visible={openModal}
                onOk={() => {
                    setOpenModal(false);
                    setCurrentRequest(null);
                }}
                onCancel={() => {
                    setOpenModal(false);
                    setCurrentRequest(null);
                }}
                footer={false}
                width={800}
            >
                <div>
                    {
                        currentRequest?.statusName === 'Initiated' ? (
                            <Descriptions bordered column={1} size='small'>
                                <Descriptions.Item label={<span className='textStyles-small'>Created By</span>}>
                                    <span className='textStyles-small'>{currentRequest?.createdUserName}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Created Time</span>}>
                                    <span className='textStyles-small'>
                                    {moment(currentRequest?.createdOn).format('DD/MM/YYYY hh:mm A')}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Current Status</span>}>
                                    <Tag color='orange'>
                                        <span className='textStyles-small'>{currentRequest?.statusName}</span>
                                    </Tag>
                                </Descriptions.Item>
                            </Descriptions>
                        ) : null
                    }

                    {
                        currentRequest?.statusName === 'Approved' ? (
                            <Descriptions bordered column={1} size='small'>
                                <Descriptions.Item label={<span className='textStyles-small'>Created User</span>}>
                                    <span className='textStyles-small'>{currentRequest?.createdUserName}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Created Time</span>}>
                                    <span className='textStyles-small'>
                                        {moment(currentRequest?.createdOn).format('DD/MM/YYYY hh:mm A')}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Current Status</span>}>
                                    <Tag color='magenta'>
                                        <span className='textStyles-small'>{currentRequest?.statusName}</span>
                                    </Tag>
                                </Descriptions.Item>
                            </Descriptions>
                        ) : null
                    }

                    {
                        currentRequest?.statusName === 'Team Assign' ? (
                            <Descriptions bordered column={1} size='small'>
                                <Descriptions.Item label={<span className='textStyles-small'>Created User</span>}>
                                    <span className='textStyles-small'>{currentRequest?.createdUserName}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Created Time</span>}>
                                    <span className='textStyles-small'>
                                        {moment(currentRequest?.createdOn).format('DD/MM/YYYY hh:mm A')}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Current Status</span>}>
                                    <Tag color='magenta'>
                                        <span className='textStyles-small'>{currentRequest?.statusName}</span>
                                    </Tag>
                                </Descriptions.Item>
                            </Descriptions>
                        ) : null
                    }

                    {
                        currentRequest?.statusName === 'Dispatch' ? (
                            <Descriptions bordered column={1} size='small'>
                                <Descriptions.Item label={<span className='textStyles-small'>Created User</span>}>
                                    <span className='textStyles-small'>{currentRequest?.createdUserName}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Created Time</span>}>
                                    <span className='textStyles-small'>
                                        {moment(currentRequest?.createdOn).format('DD/MM/YYYY hh:mm A')}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Current Status</span>}>
                                    <Tag color='magenta'>
                                        <span className='textStyles-small'>{currentRequest?.statusName}</span>
                                    </Tag>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Dispatched Location</span>}>
                                    <LoadScript googleMapsApiKey="AIzaSyAYye4nok_qEPk0bHJgzEEhwzXaRxlkM0Y">
                                        <GoogleMap
                                                options={{
                                                disableDefaultUI: true, // Disable all default UI
                                                zoomControl: false,     // Disable zoom control
                                                mapTypeControl: false,  // Disable map type control
                                                scaleControl: false,    // Disable scale control
                                                streetViewControl: false, // Disable street view control
                                                rotateControl: false,   // Disable rotate control
                                                fullscreenControl: false // Disable fullscreen control
                                            }}
                                            mapContainerStyle={mapStyles}
                                            zoom={10}
                                            center={{ 
                                                lat: parseFloat(currentRequest?.latitude) ?? 0, 
                                                lng: parseFloat(currentRequest?.longitude) ?? 0 
                                            }}
                                        >
                                            <Marker
                                                position={{ 
                                                    lat: parseFloat(currentRequest?.latitude) ?? 0, 
                                                    lng: parseFloat(currentRequest?.longitude) ?? 0 
                                                }}
                                            />
                                        </GoogleMap>
                                    </LoadScript>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Dispatch Address</span>}>
                                    <span className='textStyles-small'>{currentRequest?.address}</span>
                                </Descriptions.Item>
                            </Descriptions>
                        ) : null
                    }

                    {
                        currentRequest?.statusName === 'Installed' ? (
                            <Descriptions bordered column={1} size='small'>
                                <Descriptions.Item label={<span className='textStyles-small'>Created User</span>}>
                                    <span className='textStyles-small'>{currentRequest?.createdUserName}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Created Time</span>}>
                                    <span className='textStyles-small'>
                                        {moment(currentRequest?.createdOn).format('DD/MM/YYYY hh:mm A')}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Current Status</span>}>
                                    <Tag color='magenta'>
                                        <span className='textStyles-small'>{currentRequest?.statusName}</span>
                                    </Tag>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Install Location</span>}>
                                <LoadScript googleMapsApiKey="AIzaSyAYye4nok_qEPk0bHJgzEEhwzXaRxlkM0Y">
                                            <GoogleMap
                                            options={{
                                                disableDefaultUI: true, // Disable all default UI
                                                zoomControl: false,     // Disable zoom control
                                                mapTypeControl: false,  // Disable map type control
                                                scaleControl: false,    // Disable scale control
                                                streetViewControl: false, // Disable street view control
                                                rotateControl: false,   // Disable rotate control
                                                fullscreenControl: false // Disable fullscreen control
                                            }}
                                                mapContainerStyle={mapStyles}
                                                zoom={10}
                                                center={{ lat: parseFloat(currentRequest?.details?.InstallLatitude) ?? 0, 
                                                    lng: parseFloat(currentRequest?.details?.InstallLongitude) ?? 0 }}
                                            >
                                                <Marker
                                                    position={{ lat: parseFloat(currentRequest?.details?.InstallLatitude) ?? 0, 
                                                        lng: parseFloat(currentRequest?.details?.InstallLongitude) ?? 0 }}
                                                />
                                            </GoogleMap>
                                        </LoadScript>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Install Image</span>}>
                                    <Image 
                                    width={50}
                                    height={50}
                                    style={{borderRadius: '5px'}}
                                    src={baseImgURL + currentRequest?.details?.InstallImage}/>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Install Address</span>}>
                                    <span className='textStyles-small'>{currentRequest?.details?.InstallAddress}</span>
                                </Descriptions.Item>
                            </Descriptions>
                        ) : null
                    }

                    {
                        currentRequest?.statusName === 'Installation Confirmed' ? (
                            <Descriptions bordered column={1} size='small'>
                                <Descriptions.Item label={<span className='textStyles-small'>Created User</span>}>
                                    <span className='textStyles-small'>{currentRequest?.createdUserName}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Created Time</span>}>
                                    <span className='textStyles-small'>
                                        {moment(currentRequest?.createdOn).format('DD/MM/YYYY hh:mm A')}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Current Status</span>}>
                                    <Tag color='magenta'>
                                        <span className='textStyles-small'>{currentRequest?.statusName}</span>
                                    </Tag>
                                </Descriptions.Item>
                            </Descriptions>
                        ) : null
                    }

                    {
                        currentRequest?.statusName === 'Removed' ? (
                            <Descriptions bordered column={1} size='small'>
                                <Descriptions.Item label={<span className='textStyles-small'>Created User</span>}>
                                    <span className='textStyles-small'>{currentRequest?.createdUserName}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Created On</span>}>
                                    <span className='textStyles-small'>
                                    {moment(currentRequest?.createdOn).format('DD/MM/YYYY hh:mm A')}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Current Status</span>}>
                                    <Tag color='magenta'>
                                        <span className='textStyles-small'>{currentRequest?.statusName}</span>
                                    </Tag>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Intalled Time</span>}>
                                    <span className='textStyles-small'>{currentRequest?.details?.InstalledTime ? moment(currentRequest?.details?.InstalledTime).format('DD/MM/YYYY hh:mm A') : 'Not Provided'}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Remove Request</span>}>
                                    <span className='textStyles-small'>{currentRequest?.details?.RemoveRequestTime ? moment(currentRequest?.details?.RemoveRequestTime).format('DD/MM/YYYY hh:mm A') : 'Not Provided'}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Removed Time</span>}>
                                    <span className='textStyles-small'>{currentRequest?.details?.RemovedTime ? moment(currentRequest?.details?.RemovedTime).format('DD/MM/YYYY hh:mm A'): 'Not Provided'}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Removal Proof</span>}>
                                    <Image 
                                    width={50}
                                    height={50}
                                    style={{borderRadius: '5px'}}
                                    src={baseImgURL + currentRequest?.details?.removeImage}/>
                                </Descriptions.Item>
                            </Descriptions>
                        ) : null
                    }

                    {
                        currentRequest?.statusName === 'DO Verified' ? (
                            <Descriptions bordered column={1} size='small'>
                            <Descriptions.Item label={<span className='textStyles-small'>Created User</span>}>
                                <span className='textStyles-small'>{currentRequest?.createdUserName}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Created Time</span>}>
                                <span className='textStyles-small'>
                                {moment(currentRequest?.createdOn).format('DD/MM/YYYY hh:mm A')}
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Current Status</span>}>
                                <Tag color='magenta'>
                                    <span className='textStyles-small'>{currentRequest?.statusName}</span>
                                </Tag>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Penalty</span>}>
                                <span className='textStyles-small'>{currentRequest?.details?.pprovedPenaltyBandCost}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>SLA Breach</span>}>
                                <span className='textStyles-small'>{currentRequest?.details?.ApprovedIsSlaBreach ? 'True' : 'False'}</span>
                            </Descriptions.Item>
                        </Descriptions>
                        ) : null
                    }

                    {
                        currentRequest?.statusName === 'OM Verified' ? (
                            <Descriptions bordered column={1} size='small'>
                                <Descriptions.Item label={<span className='textStyles-small'>Created User</span>}>
                                    <span className='textStyles-small'>{currentRequest?.createdUserName}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Created Time</span>}>
                                    <span className='textStyles-small'>
                                        {moment(currentRequest?.createdOn).format('DD/MM/YYYY hh:mm A')}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Current Status</span>}>
                                    <Tag color='magenta'>
                                        <span className='textStyles-small'>{currentRequest?.statusName}</span>
                                    </Tag>
                                </Descriptions.Item>
                            </Descriptions>
                        ) : null
                    }

                    {
                        currentRequest?.statusName === 'Arrival' ? (
                            <Descriptions bordered column={1} size='small'>
                                <Descriptions.Item label={<span className='textStyles-small'>Created User</span>}>
                                    <span className='textStyles-small'>{currentRequest?.createdUserName}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Created Time</span>}>
                                    <span className='textStyles-small'>
                                        {moment(currentRequest?.createdOn).format('DD/MM/YYYY hh:mm A')}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Current Status</span>}>
                                    <Tag color='magenta'>
                                        <span className='textStyles-small'>{currentRequest?.statusName}</span>
                                    </Tag>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Arrived Location</span>}>
                                    <LoadScript googleMapsApiKey="AIzaSyAYye4nok_qEPk0bHJgzEEhwzXaRxlkM0Y">
                                        <GoogleMap
                                            options={{
                                                disableDefaultUI: true, // Disable all default UI
                                                zoomControl: false,     // Disable zoom control
                                                mapTypeControl: false,  // Disable map type control
                                                scaleControl: false,    // Disable scale control
                                                streetViewControl: false, // Disable street view control
                                                rotateControl: false,   // Disable rotate control
                                                fullscreenControl: false // Disable fullscreen control
                                            }}
                                            mapContainerStyle={mapStyles}
                                            zoom={10}
                                            center={{ 
                                                lat: parseFloat(currentRequest?.latitude) ?? 0, 
                                                lng: parseFloat(currentRequest?.longitude) ?? 0 }}
                                        >
                                            <Marker
                                                position={{ 
                                                    lat: parseFloat(currentRequest?.latitude) ?? 0, 
                                                    lng: parseFloat(currentRequest?.longitude) ?? 0 }}
                                            />
                                        </GoogleMap>
                                    </LoadScript>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Arrived Address</span>}>
                                    <span className='textStyles-small'>{currentRequest?.address}</span>
                                </Descriptions.Item>
                            </Descriptions>
                        ) : null
                    }

                    {
                        currentRequest?.statusName === 'Penalty Exclusion' ? (
                            <Descriptions bordered column={1} size='small'>
                                <Descriptions.Item label={<span className='textStyles-small'>Created User</span>}>
                                    <span className='textStyles-small'>{currentRequest?.createdUserName}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Created Time</span>}>
                                    <span className='textStyles-small'>
                                        {moment(currentRequest?.createdOn).format('DD/MM/YYYY hh:mm A')}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Current Status</span>}>
                                    <Tag color='magenta'>
                                        <span className='textStyles-small'>{currentRequest?.statusName}</span>
                                    </Tag>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Note</span>}>
                                    <span className='textStyles-small'>{currentRequest?.note}</span>
                                </Descriptions.Item>
                            </Descriptions>
                        ) : null
                    }

                    {
                        currentRequest?.statusName === 'Completed' ? (
                            <Descriptions bordered column={1} size='small'>
                                <Descriptions.Item label={<span className='textStyles-small'>Created User</span>}>
                                    <span className='textStyles-small'>{currentRequest?.createdUserName}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Created Time</span>}>
                                    <span className='textStyles-small'>
                                        {moment(currentRequest?.createdOn).format('DD/MM/YYYY hh:mm A')}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyles-small'>Current Status</span>}>
                                    <Tag color='magenta'>
                                        <span className='textStyles-small'>{currentRequest?.statusName}</span>
                                    </Tag>
                                </Descriptions.Item>
                            </Descriptions>
                        ) : null
                    }
                    
                    {
                        currentRequest?.statusName === 'Requested to Remove Correctly' ? (
                            <Descriptions bordered column={1} size='small'>
                            <Descriptions.Item label={<span className='textStyles-small'>Created User</span>}>
                                <span className='textStyles-small'>{currentRequest?.createdUserName}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Created Time</span>}>
                                <span className='textStyles-small'>
                                {moment(currentRequest?.createdOn).format('DD/MM/YYYY hh:mm A')}
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Current Status</span>}>
                                <Tag color='magenta'>
                                    <span className='textStyles-small'>{currentRequest?.statusName}</span>
                                </Tag>
                            </Descriptions.Item>
                        </Descriptions>
                        ) : null
                    }

                    {
                        currentRequest?.statusName === 'Installation Rejected by DO' ? (
                            <Descriptions bordered column={1} size='small'>
                            <Descriptions.Item label={<span className='textStyles-small'>Created User</span>}>
                                <span className='textStyles-small'>{currentRequest?.createdUserName}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Created Time</span>}>
                                <span className='textStyles-small'>
                                {moment(currentRequest?.createdOn).format('DD/MM/YYYY hh:mm A')}
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Current Status</span>}>
                                <Tag color='magenta'>
                                    <span className='textStyles-small'>{currentRequest?.statusName}</span>
                                </Tag>
                            </Descriptions.Item>
                        </Descriptions>
                        ) : null
                    }

                    {
                        currentRequest?.statusName === 'Removal Requested By DO' ? (
                            <Descriptions bordered column={1} size='small'>
                            <Descriptions.Item label={<span className='textStyles-small'>Created User</span>}>
                                <span className='textStyles-small'>{currentRequest?.createdUserName}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Created Time</span>}>
                                <span className='textStyles-small'>
                                {moment(currentRequest?.createdOn).format('DD/MM/YYYY hh:mm A')}
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Current Status</span>}>
                                <Tag color='magenta'>
                                    <span className='textStyles-small'>{currentRequest?.statusName}</span>
                                </Tag>
                            </Descriptions.Item>
                        </Descriptions>
                        ) : null
                    }

                    {
                        currentRequest?.statusName === 'Requested to Reinstall PG' ? (
                            <Descriptions bordered column={1} size='small'>
                            <Descriptions.Item label={<span className='textStyles-small'>Created User</span>}>
                                <span className='textStyles-small'>{currentRequest?.createdUserName}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Created Time</span>}>
                                <span className='textStyles-small'>
                                {moment(currentRequest?.createdOn).format('DD/MM/YYYY hh:mm A')}
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Current Status</span>}>
                                <Tag color='magenta'>
                                    <span className='textStyles-small'>{currentRequest?.statusName}</span>
                                </Tag>
                            </Descriptions.Item>
                        </Descriptions>
                        ) : null
                    }

                    {
                        currentRequest?.statusName === 'Removal Rejected by OM' ? (
                            <Descriptions bordered column={1} size='small'>
                            <Descriptions.Item label={<span className='textStyles-small'>Created User</span>}>
                                <span className='textStyles-small'>{currentRequest?.createdUserName}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Created Time</span>}>
                                <span className='textStyles-small'>
                                {moment(currentRequest?.createdOn).format('DD/MM/YYYY hh:mm A')}
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Current Status</span>}>
                                <Tag color='magenta'>
                                    <span className='textStyles-small'>{currentRequest?.statusName}</span>
                                </Tag>
                            </Descriptions.Item>
                        </Descriptions>
                        ) : null
                    }

                    {
                        currentRequest?.statusName === 'OM Rejected' ? (
                            <Descriptions bordered column={1} size='small'>
                            <Descriptions.Item label={<span className='textStyles-small'>Created User</span>}>
                                <span className='textStyles-small'>{currentRequest?.createdUserName}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Created Time</span>}>
                                <span className='textStyles-small'>
                                {moment(currentRequest?.createdOn).format('DD/MM/YYYY hh:mm A')}
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Current Status</span>}>
                                <Tag color='magenta'>
                                    <span className='textStyles-small'>{currentRequest?.statusName}</span>
                                </Tag>
                            </Descriptions.Item>
                        </Descriptions>
                        ) : null
                    }

                    {
                        currentRequest?.statusName === 'DO Request Cancellation' ? (
                            <Descriptions bordered column={1} size='small'>
                            <Descriptions.Item label={<span className='textStyles-small'>Created User</span>}>
                                <span className='textStyles-small'>{currentRequest?.createdUserName}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Created Time</span>}>
                                <span className='textStyles-small'>
                                {moment(currentRequest?.createdOn).format('DD/MM/YYYY hh:mm A')}
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Current Status</span>}>
                                <Tag color='magenta'>
                                    <span className='textStyles-small'>{currentRequest?.statusName}</span>
                                </Tag>
                            </Descriptions.Item>
                        </Descriptions>
                        ) : null
                    }

                </div>
            </Modal>
        </div>
    )
}

export default PgDetailsAllView;

