import React, { useState, useEffect } from 'react';
import {
    CarOutlined,
    NodeIndexOutlined,
    EditOutlined,
    DeleteOutlined,
    EyeOutlined,
    CheckOutlined,
    DashboardOutlined,
    SearchOutlined,
    PhoneOutlined,
    PaperClipOutlined,
    UploadOutlined
} from '@ant-design/icons';
import { Checkbox, Avatar, Card, Skeleton, Switch, Table, Select, Input, Button, Row, Upload, Col, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Image, Empty } from 'antd';
import './style.css';
import checkPermissions from "../../utils/permissionManager";
import {
    allJobsData,
    getJourneyData,
    getVehicleByID,
    getSessionDataBySessionID,
    getAllJourneyDataFromDB,
    getCurrentTaskData,
    adminApprovalForJourney,
    getAllJourneyBySessionID,
    updateApprovalStatusForSession,
    updateSessionData,
    updateApprovalStatusForSessionRewamp,
    approveOrRejectAutomaticSessionRewamp
} from "../../api/index";

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import dayjs from 'dayjs';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import SVG from 'react-svg';
import { useParams } from 'react-router-dom';
const { Meta } = Card;
const { Option } = Select;
const baseImgURL = "https://storage.googleapis.com/dns_storage_v2/dnsrevampimages/";



const JobList = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [allJobs, setAllJobs] = useState([]);
    const [infoModel, setInfoModel] = useState(false);
    const [editModel, setEditModel] = useState(false);
    const [otFixModel, setOtFixModel] = useState(false);
    const [journeyDetails, setJourneyDetails] = useState([]);
    const [searchedData, setSearchedData] = useState(null);
    const [searchValue, setSearchValue] = useState(null);
    const [dropdownValue, setDropdownValue] = useState('All');
    const [singleVehicleDetails, setSingleVehicleDetails] = useState([]);
    const [sessionData, setSessionData] = useState([]);
    const [approveStatus, setApproveStatus] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visibleImage, setVisibleImage] = useState(false);
    const [scaleStep, setScaleStep] = useState(0.9);

    const [isImageVisible, setIsImageVisible] = useState(false);
    const [customScale, setCustomScale] = useState(0.9);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const [sessionStartDate, setSessionStartDate] = useState(null);
    const [sessionEndDate, setSessionEndDate] = useState(null);
    const [sessionStartTime, setSessionStartTime] = useState(null);
    const [sessionEndTime, setSessionEndTime] = useState(null);

    const [startMeaterReading, setStartMeaterReading] = useState(null);
    const [endMeaterReading, setEndMeaterReading] = useState(null);
    const [approvalRemark, setApprovalRemark] = useState(null);

    const [startMapCenter, setStartMapCenter] = useState({
        lat: 0,
        lng: 0
    });

    const [endMapCenter, setEndMapCenter] = useState({
        lat: 0,
        lng: 0
    });

    const { id } = useParams();

    React.useEffect(() => {
        fetchSessionData(id);
    }, []);

    const fetchAllJourneyDataByID = async (id) => {
        var allJobData = await getAllJourneyBySessionID(id);
        if (allJobData.success) {
            const allJobLen = allJobData.data.results.length;
            const approvedStatus = [];
            for (let i = 0; i < allJobLen; i++) {
                if (allJobData.data.results[i].approvalStatus === 'APPROVED' && allJobData.data.results[i].status === 'COMPLETED') {
                    approvedStatus.push('DONE');
                } else if (allJobData.data.results[i].approvalStatus === 'CANCELLED' && allJobData.data.results[i].status === 'CANCELLED') {
                    approvedStatus.push('DONE');
                } else {
                    approvedStatus.push('PENDING');
                }
            }
            if (approvedStatus.includes('PENDING')) {
                setApproveStatus(false);
            } else {
                setApproveStatus(true);
            }
            setAllJobs(allJobData.data.results);
            if (allJobData.data.results.length > 0) {
                // fetchSingleVehicle(allJobData.data?.results[0]?.vehicleId);
            } else {
                message.error('No Journeys For This Session');
            }
        } else {
            message.error('Error In Fetching API Request : ' + allJobData.message);
        }
    };

    const rapidFetchingData = () => {
        fetchAllJourneyDataByID(id);
    }

    const fetchSessionData = async (id) => {
        var responseAPI = await getSessionDataBySessionID(id);
        if (responseAPI.success) {
            console.log('Response API : ', responseAPI.data.results);
            setSessionData(responseAPI?.data?.results);
            fetchAllJourneyDataByID(id);
            fetchSingleVehicle(responseAPI?.data?.results?.vehicleDetails?.vehicleId);
        } else {
            console.log('Error In Fetching API Request : ', responseAPI.message);
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    };

    const updateApprovalStatusForSessionByID = async () => {
        var responseAPI = await updateApprovalStatusForSession(id);
        if (responseAPI.success) {
            console.log('Response API : ', responseAPI.data.results);
            message.success('Session Approved Successfully');
            fetchSessionData(id);
        } else {
            console.log('Error In Fetching API Request : ', responseAPI.message);
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    }


    const fetchSingleVehicle = async (id, option) => {
        var responseAPI = await getVehicleByID(id);
        if (responseAPI.success) {
            console.log('Response API : ', responseAPI.data.results);
            setSingleVehicleDetails([]);
            setSingleVehicleDetails(responseAPI.data.results);
            if (option === 'edit') {
                openEditModel();
            }
        } else {
            console.log('Error In Fetching API Request : ', responseAPI.message);
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    };

    const fetchSingleJourney = async (id) => {
        var responseAPI = await getJourneyData(id);
        if (responseAPI.success) {
            console.log('Response API : ', responseAPI.data.results);
            setJourneyDetails(responseAPI.data.results);
            fetchSessionData(responseAPI.data.results.sessionId);
            fetchSingleVehicle(responseAPI.data.results.vehicleId);
            setCurrentStep(0);
            if (responseAPI.data.results.status === 'COMPLETED' && responseAPI.data.results.approvalStatus === 'PENDING_APPROVAL') {
                setCurrentStep(1);
            } else if (responseAPI.data.results.status === 'COMPLETED' && responseAPI.data.results.approvalStatus === 'APPROVED') {
                setCurrentStep(2);
            } else {
                setCurrentStep(0);
            }
            setStartMapCenter({
                lat: responseAPI.data.results.journeyLocation.startLocationLatitude,
                lng: responseAPI.data.results.journeyLocation.startLocationLongitude
            });

            setEndMapCenter({
                lat: responseAPI.data.results.journeyLocation.endLocationLatitude,
                lng: responseAPI.data.results.journeyLocation.endLocationLongitude
            });

            setStartMeaterReading(responseAPI.data.results.startMeterReading);
            setEndMeaterReading(responseAPI.data.results.endMeterReading);

            opeInfoModel();
        } else {
            console.log('Error In Fetching API Request : ', responseAPI.message);
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    };

    const fetchSingleJourneyForEdit = async (id) => {
        var responseAPI = await getJourneyData(id);
        if (responseAPI.success) {
            console.log('Response API : ', responseAPI.data.results);
            setJourneyDetails(responseAPI.data.results);
            fetchSingleVehicle(responseAPI.data.results.vehicleId, 'edit');
        } else {
            console.log('Error In Fetching API Request : ', responseAPI.message);
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    };

    const fetchCurrentTaskData = async (id, startDateTime, endDateTime, adminRemark, startMeterReading, endMeterReading) => {
        var data = {
            "processInstanceId": id
        }
        var responseAPI = await getCurrentTaskData(data);
        if (responseAPI.success) {
            adminApprovalConfirmation(responseAPI.data.results, startDateTime, endDateTime, adminRemark, startMeterReading, endMeterReading);
        } else {
            console.log('Error In Fetching API Request : ', responseAPI.message);
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    };

    const adminApproval = async (id, startDateTime, endDateTime, adminRemark, startMeterReading, endMeterReading) => {

        const startMeterReadingVal = parseFloat(startMeterReading);
        const endMeterReadingVal = parseFloat(endMeterReading);

        let distanceCal = endMeterReadingVal - startMeterReadingVal;

        if (singleVehicleDetails?.distanceUnit === 'MILE') {
            distanceCal = distanceCal * 1.6;
        }

        if (distanceCal > 1500) {
            message.error("Distance Should Not Be Less Than 1500 Kms");
        } else {
            var data = {
                "startMeterReading": startMeterReadingVal,
                "startMeterProof": journeyDetails.startMeterProof,
                "startDateTime": startDateTime ? startDateTime : journeyDetails.startDateTime,
                "endMeterReading": endMeterReadingVal,
                "endMeterProof": journeyDetails.endMeterProof,
                "endDateTime": endDateTime ? endDateTime : journeyDetails.endDateTime,
                "totalDistance": distanceCal,
                "journeyDuration": journeyDetails.journeyDuration,
                "remark": adminRemark ? adminRemark : 'N/A'
            }

            var responseAPI = await adminApprovalForJourney(id, data);
            if (responseAPI.success) {
                console.log('Response API : ', responseAPI.data.results);
                message.success('Journey Approved Successfully');
                closeInfoModel();
                rapidFetchingData();
            } else {
                console.log('Error In Fetching API Request : ', responseAPI.message);
                message.error('Error In Fetching API Request : ' + responseAPI.message);
            }
        }
    };

    const adminApprovalConfirmation = (id, startDateTime, endDateTime, adminRemark, startMeterReading, endMeterReading) => {
        Modal.confirm({
            title: "Want To Approve Journey?",
            content: `Are You Sure To Approve This Journey?`,
            onOk: () => {
                adminApproval(id, startDateTime, endDateTime, adminRemark, startMeterReading, endMeterReading);
            },
            onCancel: () => { },
        });
    };

    const opeInfoModel = () => {
        setInfoModel(true);
    };

    const closeInfoModel = () => {
        setInfoModel(false);
    };

    const openEditModel = () => {
        setEditModel(true);
    };

    const closeEditModel = () => {
        setEditModel(false);
    };

    const openOtFixModel = () => {
        setOtFixModel(true);
    };

    const closeOtFixModel = () => {
        setOtFixModel(false);
    };

    const columns = [
        {
            title: <span className='textStyle'>
                Job Number </span>,
            dataIndex: 'code',
            key: 'code',
            render: (text) => <b className='textStyleChild'>
                {text ? text : 'N/A'}
            </b>,
        },
        {
            title: <span className="textStyle">Created On</span>,
            dataIndex: 'createdOn',
            key: 'createdOn',
            render: text => {
                return text ? (
                    (() => {
                        const dateObject = new Date(text);
                        const options = {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                        };
                        const formattedDate = dateObject.toLocaleString(undefined, options);
                        return <a className="textStyleChild">{formattedDate}</a>;
                    }
                    )()
                ) : (
                    <span className="textStyleChild">
                        N/A
                    </span>
                );
            }
        },
        {
            title: <span className="textStyle">Updated On</span>,
            dataIndex: 'updatedOn',
            key: 'updatedOn',
            render: text => {
                return text ? (
                    (() => {
                        const dateObject = new Date(text);
                        const options = {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                        };
                        const formattedDate = dateObject.toLocaleString(undefined, options);
                        return <a className="textStyleChild">{formattedDate}</a>;
                    })()
                ) : (
                    <span className="textStyleChild">
                        N/A
                    </span>
                );
            }
        },
        {
            title: <span className='textStyle'>Start Time</span>,
            dataIndex: 'startDateTime',
            key: 'startDateTime',
            render: text => {
                return text ? (
                    (() => {
                        const dateObject = new Date(text);
                        const options = {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                        };
                        const formattedDate = dateObject.toLocaleString(undefined, options);
                        return <a className="textStyleChild">{formattedDate}</a>;
                    })()
                ) : (
                    <span className="textStyleChild">
                        N/A
                    </span>
                );
            }
        },
        {
            title: <span className='textStyle'>End Time</span>,
            dataIndex: 'endDateTime',
            key: 'endDateTime',
            render: text => {
                return text ? (
                    (() => {
                        const dateObject = new Date(text);
                        const options = {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                        };
                        const formattedDate = dateObject.toLocaleString(undefined, options);
                        return <a className="textStyleChild">{formattedDate}</a>;
                    })()
                ) : (
                    <span className="textStyleChild">
                        N/A
                    </span>
                );
            }
        },
        {
            title: <span className='textStyle'>Journey Status</span>,
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (text === 'COMPLETED') {
                    return <Tag color="blue" className='textStyleChilds'
                        style={{ width: "80px", textAlign: "center", height: "25px", justifyContent: 'center', alignItems: 'center', display: 'flex' }}>Completed</Tag>;
                } else if (text === 'ON_GOING') {
                    return <Tag color="purple" className='textStyleChilds' style={{ width: "80px", textAlign: "center", height: "25px", justifyContent: 'center', alignItems: 'center', display: 'flex' }}>On Going</Tag>;
                } else {
                    return <Tag color="red" className='textStyleChilds' style={{ width: "80px", textAlign: "center", height: "25px", justifyContent: 'center', alignItems: 'center', display: 'flex' }}>Cancelled</Tag>;
                }
            }
        },
        {
            title: <span className='textStyle'>Approval Status</span>,
            dataIndex: 'approvalStatus',
            key: 'approvalStatus',
            render: (text, record) => {
                if (text === 'APPROVED') {
                    return <Tag color="green" className='textStyleChilds'
                        style={{ width: "80px", textAlign: "center", height: "25px", justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        Approved</Tag>;
                } else if (text === 'PENDING_APPROVAL') {
                    return <Tag color="orange" className='textStyleChilds'
                        style={{ width: "80px", textAlign: "center", height: "25px", justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        Pending</Tag>;
                } else {
                    return <Tag color="orange" className='textStyleChilds'
                        style={{ width: "80px", textAlign: "center", height: "25px", justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        Cancelled</Tag>;
                }
            }
        },
        {
            title: <span className='textStyle'>Action</span>,
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button
                        type="circle"
                        style={{ backgroundColor: "#bfabde", marginLeft: 10, width: "25px", height: "25px" }}
                        icon={<EyeOutlined style={{ color: "#2f066b", fontWeight: "bold", fontSize: "10px" }} />}
                        size="small"
                        onClick={(record) =>
                            fetchSingleJourney(text)}
                    />
                </div>
            )
        },
    ];

    const formateDate = (date) => {
        const dateObject = new Date(date);
        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        };

        const formattedDate = dateObject.toLocaleString(undefined, options);
        return formattedDate;
    }

    const formateOnyDate = (date) => {
        const dateObject = new Date(date);
        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        };

        const formattedDate = dateObject.toLocaleString(undefined, options);
        return formattedDate;
    }

    const mapStyles = {
        height: "110px",
        width: "100%",
        borderRadius: "5px"
    };

    const getOnlyDate = (dateTimeString) => {
        const dateTime = new Date(dateTimeString);
        const year = dateTime.getFullYear();
        const month = dateTime.getMonth() + 1;
        const day = dateTime.getDate();

        return `${day}-${month}-${year}`;
    }

    const handleFormSubmit = (values) => {

        let combinedDateTimeStart = null;
        let combinedDateTimeEnd = null;

        const combinedDateFromAPI = journeyDetails.startDateTime;
        const startDateFromAPI = combinedDateFromAPI.substring(0, 10);
        const startTimeFromAPI = combinedDateFromAPI.substring(11);

        const combineEndDateFromAPI = journeyDetails.endDateTime;
        const endDateFromAPI = combineEndDateFromAPI.substring(0, 10);
        const endTimeFromAPI = combineEndDateFromAPI.substring(11);

        if (startDate !== null && startTime !== null) {
            combinedDateTimeStart = `${startDate}T${startTime}`;
        } else if (startDate === null && startTime !== null) {
            combinedDateTimeStart = `${startDateFromAPI}T${startTime}`;
        } else if (startDate !== null && startTime === null) {
            combinedDateTimeStart = `${startDate}T${startTimeFromAPI}`;
        } else {
            combinedDateTimeStart = `${startDateFromAPI}T${startTimeFromAPI}`;
        }


        if (endDate !== null && endTime !== null) {
            combinedDateTimeEnd = `${endDate}T${endTime}`;
        } else if (endDate === null && endTime !== null) {
            combinedDateTimeEnd = `${endDateFromAPI}T${endTime}`;
        } else if (endDate !== null && endTime === null) {
            combinedDateTimeEnd = `${endDate}T${endTimeFromAPI}`;
        } else {
            combinedDateTimeEnd = `${endDateFromAPI}T${endTimeFromAPI}`;
        }

        const startMeterReading = values.startMeterReading ? values.startMeterReading : 0;
        const endMeterReading = values.endMeterReading ? values.endMeterReading : 0;

        const journeyStartTime = sessionData?.checkinDateTime;

        if (combinedDateTimeStart <= journeyStartTime) {
            message.error('Journey Start DateTime Must Be After Session Start Time');
        } else {
            if (combinedDateTimeEnd <= combinedDateTimeStart) {
                message.error('Journey End DateTime Must Be After Journey Start DateTime');
            } else {
                if (startMeterReading > endMeterReading) {
                    message.error('End Meter Reading Must Be Greater Than Start Meter Reading');
                } else {
                    const adminRemark = values.adminRemark ? values.adminRemark : 'N/A';
                    fetchCurrentTaskData(journeyDetails.camundaInstanceId, combinedDateTimeStart, combinedDateTimeEnd, adminRemark, startMeterReading, endMeterReading);
                }
            }
        }
    }

    const getTimeDifferenceInMinutes = (startTime, endTime) => {
        var start = new Date(startTime);
        var end = new Date(endTime);
        var timeDiff = end - start;
        var minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff;
    }

    const handleOTFormSubmit = (values) => {

        console.log(values);

        let combinedDateTimeStart = null;
        let combinedDateTimeEnd = null;

        const journeyStartDateFromAPI = sessionData?.checkinDateTime;
        const startDateFromAPI = journeyStartDateFromAPI.substring(0, 10);
        const startTimeFromAPI = journeyStartDateFromAPI.substring(11);

        const journeyEndDateFromAPI = sessionData?.checkoutDateTime;
        const endDateFromAPI = journeyEndDateFromAPI.substring(0, 10);
        const endTimeFromAPI = journeyEndDateFromAPI.substring(11);

        if (sessionStartDate !== null && sessionStartTime !== null) {
            combinedDateTimeStart = `${sessionStartDate}T${sessionStartTime}`;
        } else if (sessionStartDate === null && sessionStartTime !== null) {
            combinedDateTimeStart = `${startDateFromAPI}T${sessionStartTime}`;
        } else if (sessionStartDate !== null && sessionStartTime === null) {
            combinedDateTimeStart = `${sessionStartDate}T${startTimeFromAPI}`;
        } else {
            combinedDateTimeStart = `${startDateFromAPI}T${startTimeFromAPI}`;
        }

        if (sessionEndDate !== null && sessionEndTime !== null) {
            combinedDateTimeEnd = `${sessionEndDate}T${sessionEndTime}`;
        } else if (sessionEndDate === null && sessionEndTime !== null) {
            combinedDateTimeEnd = `${endDateFromAPI}T${sessionEndTime}`;
        } else if (sessionEndDate !== null && sessionEndTime === null) {
            combinedDateTimeEnd = `${sessionEndDate}T${endTimeFromAPI}`;
        } else {
            combinedDateTimeEnd = `${endDateFromAPI}T${endTimeFromAPI}`;
        }

        let updatedDoubleOT = 0;
        let updatedSingleOT = 0;

        if (sessionData?.overtime?.doubleOt > 0) {
            const inHours = values.doubleOtHours ? parseFloat(values.doubleOtHours) : 0;
            const inMins = values.doubleOtMinutes ? parseFloat(values.doubleOtMinutes) : 0;
            updatedDoubleOT = (inHours * 60) + inMins;
            console.log(inHours);
            console.log(inMins);
            console.log(updatedDoubleOT);
        }

        if (sessionData?.overtime?.normalOt > 0) {
            const inHours = values.normalOtHours ? parseFloat(values.normalOtHours) : 0;
            const inMins = values.normalOtMinutes ? parseFloat(values.normalOtMinutes) : 0;
            updatedSingleOT = (inHours * 60) + inMins;
            console.log(inHours);
            console.log(inMins);
            console.log(updatedSingleOT);
        }

        if (combinedDateTimeEnd <= combinedDateTimeStart) {
            message.error('Session End DateTime Must Be After Session Start DateTime');
        } else {
            const requestBody = {
                checkinDateTime: combinedDateTimeStart,
                checkoutDateTime: combinedDateTimeEnd,
                hoursWorked: getTimeDifferenceInMinutes(combinedDateTimeStart, combinedDateTimeEnd),
                normalOt: updatedSingleOT,
                doubleOt: updatedDoubleOT,
                specialAllowanceRate: sessionData?.overtime?.specialAllowanceRate,
                accommodationProof: sessionData?.overtime?.accommodationProof,
                specialAllowances: sessionData?.overtime?.specialAllowances ? sessionData?.overtime?.specialAllowances : 'N/A',
                specialAllowancesProof: sessionData?.overtime?.specialAllowancesProof ? sessionData?.overtime?.specialAllowancesProof : 'N/A',
                remark: values.remark ? values.remark : 'N/A',
                isAccommodationApproved: values.isAccommodationApproved ? values.isAccommodationApproved : false
            }
            const sessionID = sessionData?.id;
            adminApproveOverTime(sessionID, requestBody);
        }
    }

    const adminApproveOverTime = (sessionID, formData) => {
        Modal.confirm({
            title: "Want To Save That Changes?",
            onOk: () => {
                updateSessionDetails(sessionID, formData);
            },
            onCancel: () => { },
        });
    }

    const updateSessionDetails = async (sessionID, formData) => {
        var responseAPI = await updateApprovalStatusForSessionRewamp(sessionID, formData);
        if (responseAPI.success) {
            console.log('Response API : ', responseAPI.data.results);
            message.success('Session Updated Successfully');
            closeOtFixModel();
            fetchSessionData(id);
        } else {
            console.log('Error In Fetching API Request : ', responseAPI.message);
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    }

    const approveAutomaticSessionByAdmin = async () => {
        Modal.confirm({
            title: "Are You Want To Approve This Automatic Session?",
            onOk: () => {
                approveOrRejectAutomaticSessionFunction(1);
            },
            onCancel: () => { },
        });
    }

    const rejectAutomaticSessionByAdmin = async () => {
        Modal.confirm({
            title: "Are You Want To Reject This Automatic Session?",
            onOk: () => {
                approveOrRejectAutomaticSessionFunction(0);
            },
            onCancel: () => { },
        });
    }

    const approveOrRejectAutomaticSessionFunction = async (status) => {
        const sessionID = sessionData?.id;
        const reqData = approvalRemark ? approvalRemark : 'N/A';
        const requestData = {
            "approvalRemark": reqData
        }
        const apiRequest = await approveOrRejectAutomaticSessionRewamp(sessionID, status, requestData);
        if (apiRequest.success) {
            message.success('Session ' + (status === 1 ? 'Approved' : 'Rejected') + ' Successfully');
            fetchSessionData(id);
            setOtFixModel(false);
        } else {
            const errorMessage = apiRequest?.data?.errors[0]?.errorMessage;
            message.error('Error With ' + (status === 1 ? 'Approving' : 'Rejecting') + ' Session: '+ errorMessage);
        }
    }

    const convertMinutesToHoursAndMinutes = (minutes) => {
        if (isNaN(minutes)) {
            return '0';
        }

        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        return `${hours} hours ${remainingMinutes} minutes`;
    }

    const overtimeInfo = [
        {
            title: <span className='textStyle'>Normal OT</span>,
            dataIndex: 'normalOt',
            key: 'normalOt',
            render: text => (
                <span className='textStyleChild'>
                    {Math.floor(text / 60)} Hours {text % 60} Minutes
                </span>
            )

        },
        {
            title: <span className='textStyle'>Double OT</span>,
            dataIndex: 'doubleOt',
            key: 'doubleOt',
            render: text => (
                <span className='textStyleChild'>
                    {Math.floor(text / 60)} Hours {text % 60} Minutes
                </span>
            )
        },
        {
            title: <span className='textStyle'>Remark</span>,
            dataIndex: 'remark',
            key: 'remark',
            render: text => (
                <span className='textStyleChild'>
                    {sessionData?.sessionCreationType !== 'AUTOMATIC' ? (text ? text : 'N/A') : (sessionData?.approvalRemark ? sessionData?.approvalRemark : 'N/A')}
                </span>
            )
        },
        {
            title: <span className='textStyle'>Accommodation Proof</span>,
            dataIndex: 'accommodationProof',
            key: 'accommodationProof',
            render: text => <span className='textStyleChild'>
                {text ? (
                    <span onClick={() => setIsImageVisible(true)} style={{ color: '#2C5EF4', cursor: 'pointer' }}>
                        View Proof
                    </span>
                ) : (
                    'N/A'
                )}
            </span>
        },
        {
            title: <span className='textStyle'>Accommodation Proof Status</span>,
            dataIndex: 'isAccommodationApproved',
            key: 'isAccommodationApproved',
            render: (text, record) => (
                <span className='textStyleChild'>{
                    (() => {
                        if (sessionData?.sessionApprovalStatus === 'PENDING_APPROVAL') {
                            if (record?.accommodationProof !== null && record?.accommodationProof !== '') {
                                return (
                                    <Tag color="orange" style={{ width: "80px", textAlign: "center", height: "25px", justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                        Pending
                                    </Tag>
                                );
                            } else {
                                return (
                                    <Tag color="red" style={{ width: "80px", textAlign: "center", height: "25px", justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                        No A.P
                                    </Tag>
                                );
                            }
                        } else if (sessionData?.sessionApprovalStatus === 'APPROVED' || sessionData?.sessionApprovalStatus === 'REJECTED') {
                            if (record?.accommodationProof !== null && record?.accommodationProof !== '') {
                                if (record?.isAccommodationApproved === true) {
                                    return (
                                        <Tag color="green" style={{ width: "80px", textAlign: "center", height: "25px", justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                            Approve
                                        </Tag>
                                    );
                                } else if (record?.isAccommodationApproved === false) {
                                    return (
                                        <Tag color="red" style={{ width: "80px", textAlign: "center", height: "25px", justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                            Reject
                                        </Tag>
                                    );
                                }
                            } else {
                                return (
                                    <Tag color="red" style={{ width: "80px", textAlign: "center", height: "25px", justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                        No A.P
                                    </Tag>
                                );
                            }

                        }
                    })()
                }
                </span>
            )
        },
        {
            title: <span className='textStyle'>Actions </span>,
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                (sessionData?.sessionApprovalStatus === 'PENDING_APPROVAL' && sessionData?.sessionStatus === 'CLOSED') ? (
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        {
                            approveStatus ? (
                                (checkPermissions('FIXED-USER') || checkPermissions('VEHICLE-ADMIN') || checkPermissions('VEHICLE-SUPER-ADMIN')) ? (
                                    <Button
                                        type="primary"
                                        className='textStyleChild'
                                        style={{ width: "120px", height: "25px", color: 'white', backgroundColor: '#2C5EF4', border: 'none' }}
                                        onClick={() => openOtFixModel()}
                                        size="small"
                                    >
                                        Go To Approve
                                    </Button>
                                ) : null
                            ) : (
                                <span className='textStyleChild'></span>
                            )
                        }
                    </div>
                ) : (
                    <span className='textStyleChild'>
                    </span>
                )
            )
        }
    ]

    return (
        <body>
            <div style={{ margin: '0', padding: '10px', height: '100%' }}>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="row">
                                <div className="col">
                                    <h4 className="page-title" style={{ fontWeight: "bold" }}>
                                        Session Information
                                    </h4>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Vehicle </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Session Information </a>
                                        </li>
                                    </ol>
                                </div>
                                <div className="col-auto align-self-center">
                                    <a
                                        href="#"
                                        className="btn btn-sm btn-outline-primary"
                                        id="Dash_Date"
                                    >
                                        <span className="day-name" id="Day_Name">
                                            Today:
                                        </span>
                                        &nbsp;
                                        <span className="" id="Select_date">
                                            {new Date().toUTCString().slice(5, 16)}
                                        </span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            className="feather feather-calendar align-self-center icon-xs ms-1"
                                        >
                                            <rect
                                                x="3"
                                                y="4"
                                                width="18"
                                                height="18"
                                                rx="2"
                                                ry="2"
                                            ></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    title={
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                            <p style={{ fontSize: '15px', margin: 0, color: 'gray' }}>
                                <b>
                                    Session Information : {sessionData?.sessionCode ? sessionData?.sessionCode : 'N/A'}
                                </b>
                            </p>
                        </div>
                    }
                    visible={otFixModel}
                    onOk={closeOtFixModel}
                    onCancel={closeOtFixModel}
                    footer={[]}
                    width={800}
                    destroyOnClose={true}
                >
                    <div style={{ marginLeft: '50px' }}>
                        <div style={{ padding: '10px', backgroundColor: '#ffffff', borderRadius: '10px' }}>
                            <Form
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                layout="vertical"
                                onFinish={handleOTFormSubmit}
                            >
                                <div>
                                    {
                                        sessionData?.sessionCreationType !== 'AUTOMATIC' ? (
                                            <>
                                                <Row>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                                Session Start Date
                                                            </span>}
                                                            name="sessionStartDate"
                                                            format="DD-MM-YYYY"
                                                            initialValue={moment(sessionData?.checkinDateTime ? dayjs(sessionData?.checkinDateTime).format('DD-MM-YYYY') : null, 'DD-MM-YYYY')}
                                                        >
                                                            <DatePicker
                                                                className='textStyles-small'
                                                                style={{ width: "90%", borderRadius: "5px" }}
                                                                size='medium'
                                                                onChange={(date, dateString) => {
                                                                    setSessionStartDate(dateString);
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                                Session Start Time
                                                            </span>}
                                                            name="sessionStartTime"
                                                            initialValue={moment(sessionData?.checkinDateTime ? dayjs(sessionData?.checkinDateTime).format('HH:mm') : null, 'HH:mm')}
                                                        >
                                                            <TimePicker
                                                                style={{ width: "90%", borderRadius: "5px" }}
                                                                format="HH:mm"
                                                                size='medium'
                                                                className='textStyles-small'
                                                                onChange={(time, timeString) => {
                                                                    setSessionStartTime(timeString);
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                                Session End Date
                                                            </span>}
                                                            name="sessionEndtDate"
                                                            format="DD-MM-YYYY"
                                                            initialValue={moment(sessionData?.checkoutDateTime ? dayjs(sessionData?.checkoutDateTime).format('DD-MM-YYYY') : null, 'DD-MM-YYYY')}
                                                        >
                                                            <DatePicker
                                                                className='textStyles-small'
                                                                style={{ width: "90%", borderRadius: "5px" }}
                                                                size='medium'
                                                                onChange={(date, dateString) => {
                                                                    setSessionEndDate(dateString);
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                                Session End Time
                                                            </span>}
                                                            name="sessionEndTime"
                                                            initialValue={moment(sessionData?.checkoutDateTime ? dayjs(sessionData?.checkoutDateTime).format('HH:mm') : null, 'HH:mm')}
                                                        >
                                                            <TimePicker
                                                                style={{ width: "90%", borderRadius: "5px" }}
                                                                format="HH:mm"
                                                                size='medium'
                                                                className='textStyles-small'
                                                                onChange={(time, timeString) => {
                                                                    setSessionEndTime(timeString);
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : (
                                            <>
                                                <Row>
                                                    <Col span={24}>
                                                    {/* Below Session Created Time  (Both web and App) 

This approval session is auto generated due to no active session for the <Vehicle Master Code> - <Vehicle Number> 

For the <Vehicle Unavailable Date> 

Pls approve this session if the vehicle is available for the day, or Reject session to mark the vehicle as unavailable for the <Vehicle Unavailable Date>  */}

                                                        <Descriptions
                                                            bordered
                                                            column={1}
                                                            size='small'
                                                            style={{ width: '90%', marginBottom: '10px' }}
                                                        >
                                                            <Descriptions.Item
                                                                label={
                                                                    <span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                                        Session Created Time
                                                                    </span>
                                                                }>
                                                                <span className='textStyles-small'>
                                                                    {sessionData?.createdOn ? formateDate(sessionData?.createdOn) : 'N/A'}
                                                                </span>
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <div style={{ width: '90%' }}>
                                                        <span className='textStyles-small' style={{textAlign: 'justify', marginBottom: '10px', color: 'red'}}>
                                                                This approval session is auto generated due to no active session for the <b>{singleVehicleDetails?.vehicleMaster?.code} - {singleVehicleDetails?.registrationNumber}</b> for the <b>{sessionData?.createdOn ? formateOnyDate(sessionData?.createdOn) : 'N/A'}</b> Please approve this session if the vehicle is available for the day, or Reject session to mark the vehicle as unavailable for the <b>{sessionData?.createdOn ? formateOnyDate(sessionData?.createdOn) : 'N/A'}</b>
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>)
                                    }


                                    {sessionData?.overtime?.normalOt !== 0 ? (
                                        (() => {
                                            const totalMinutes = sessionData?.overtime?.normalOt;
                                            const hours = Math.floor(totalMinutes / 60);
                                            const remainingMinutes = totalMinutes % 60;
                                            return (
                                                <div>
                                                    <Row>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                                    OverTime (Hour)
                                                                </span>}
                                                                name="normalOtHours"
                                                                initialValue={hours ? hours : 0}
                                                                rules={[
                                                                    {
                                                                        validator(_, value) {
                                                                            if (value >= 0) {
                                                                                return Promise.resolve();
                                                                            }
                                                                            return Promise.reject(new Error('Please input a non-negative value for OverTime (Hour)!'));
                                                                        },
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    type="number"
                                                                    style={{ width: "90%", borderRadius: "5px" }}
                                                                    className='textStyles-small'
                                                                    placeholder="Hour"
                                                                />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={12}>
                                                            <Form.Item
                                                                label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                                    OverTime (Minutes)
                                                                </span>}
                                                                name="normalOtMinutes"
                                                                initialValue={remainingMinutes ? remainingMinutes : 0}
                                                                rules={[
                                                                    {
                                                                        validator(_, value) {
                                                                            if (value >= 0 && value <= 59) {
                                                                                return Promise.resolve();
                                                                            }
                                                                            return Promise.reject(new Error('Please input a non-negative value less than or equal to 59 for OverTime (Minutes)!'));
                                                                        },
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    type="number"
                                                                    style={{ width: "90%", borderRadius: "5px" }}
                                                                    className='textStyles-small'
                                                                    placeholder="Minutes"
                                                                    max="59"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </div>

                                            );
                                        })()
                                    ) : null}

                                    {sessionData?.overtime?.doubleOt !== 0 ? (
                                        (() => {
                                            const totalMinutes = sessionData?.overtime?.doubleOt;
                                            const hours = Math.floor(totalMinutes / 60);
                                            const remainingMinutes = totalMinutes % 60;

                                            return (
                                                <div>
                                                    <Row>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                                    Double OverTime (Hour)
                                                                </span>}
                                                                name="doubleOtHours"
                                                                initialValue={hours ? hours : 0}
                                                                rules={[
                                                                    {
                                                                        validator(_, value) {
                                                                            if (value >= 0) {
                                                                                return Promise.resolve();
                                                                            }
                                                                            return Promise.reject(new Error('Please input a non-negative value for Double OverTime (Hour)!'));
                                                                        },
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    type="number"
                                                                    style={{ width: "90%", borderRadius: "5px" }}
                                                                    className='textStyles-small'
                                                                    placeholder="Hour"
                                                                />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={12}>
                                                            <Form.Item
                                                                label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                                    Double OverTime (Minutes)
                                                                </span>}
                                                                name="doubleOtMinutes"
                                                                initialValue={remainingMinutes ? remainingMinutes : 0}
                                                                rules={[
                                                                    {
                                                                        validator(_, value) {
                                                                            if (value >= 0 && value <= 59) {
                                                                                return Promise.resolve();
                                                                            }
                                                                            return Promise.reject(new Error('Please input a non-negative value less than or equal to 59 for Double OverTime (Minutes)!'));
                                                                        },
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    type="number"
                                                                    style={{ width: "90%", borderRadius: "5px" }}
                                                                    className='textStyles-small'
                                                                    placeholder="Minutes"
                                                                    max="59"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </div>

                                            );
                                        }
                                        )()
                                    ) : null}
                                    <Row>
                                        {/* <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                    Special Allowance Rate
                                                </span>}
                                                name="specialAllowanceRate"
                                                initialValue={sessionData?.overtime?.specialAllowanceRate ? sessionData?.overtime?.specialAllowanceRate : 0}
                                            >
                                                <Input
                                                    style={{ width: "80%", borderRadius: "5px" }}
                                                    className='textStyles-small'
                                                    placeholder="Type Special Allowance Rate"
                                                />
                                            </Form.Item>
                                        </Col> */}
                                        {/* <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                    Special Allowances
                                                </span>}
                                                name="specialAllowances"
                                                initialValue={sessionData?.overtime?.specialAllowances ? sessionData?.overtime?.specialAllowances : 'No'}
                                            >
                                                <Select
                                                    style={{ width: "80%", borderRadius: "5px" }}
                                                    className='textStyles-small'
                                                    placeholder="Select Special Allowances"
                                                >
                                                    <Option value="Yes">Yes</Option>
                                                    <Option value="No">No</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col> */}
                                    </Row>
                                    {/* <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                    Remark
                                                </span>}
                                                name="remark"
                                                initialValue={sessionData?.overtime?.remark ? sessionData?.overtime?.remark : 'N/A'}
                                            >
                                                <TextArea
                                                    style={{ width: "90%", borderRadius: "5px" }}
                                                    className='textStyles-small'
                                                    placeholder="Type Remark"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row> */}
                                    <Row>
                                        {
                                            sessionData?.overtime?.accommodationProof || sessionData?.overtime?.specialAllowancesProof ? (
                                                <Col span={12}>
                                                    <Image
                                                        style={{ borderRadius: '5px' }}
                                                        width={275}
                                                        height={275}
                                                        src={sessionData?.overtime?.accommodationProof ? baseImgURL + sessionData?.overtime?.accommodationProof : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                        placeholder={
                                                            <Image
                                                                preview={false}
                                                                src={sessionData?.overtime?.accommodationProof ? baseImgURL + sessionData?.overtime?.accommodationProof : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                                width="90%"
                                                            />
                                                        }
                                                    /> <br></br>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                            Accommodation Proof Approve
                                                        </span>}
                                                        name="isAccommodationApproved"
                                                    >
                                                        <Switch checkedChildren="Approve" unCheckedChildren="Reject" />
                                                    </Form.Item>
                                                </Col>
                                            ) : null
                                        }

                                        <Col span={sessionData?.sessionCreationType !== 'AUTOMATIC' ? 12 : 24}>
                                            {/* <Image
                                                style={{ borderRadius: '5px' }}
                                                width={275}
                                                height={275}
                                                src={sessionData?.overtime?.specialAllowancesProof ? baseImgURL + sessionData?.overtime?.specialAllowancesProof : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                placeholder={
                                                    <Image
                                                        preview={false}
                                                        src={sessionData?.overtime?.specialAllowancesProof ? baseImgURL + sessionData?.overtime?.specialAllowancesProof : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                        width="90%"
                                                    />
                                                }
                                            /> <br></br> */}
                                            {/* <Form.Item
                                                style={{ marginTop: '10px' }}
                                                label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                    Special Allowances Proof
                                                </span>}
                                                name="specialAllowancesProof"
                                                getValueFromEvent={(e) => e.fileList}
                                                rules={[
                                                    {
                                                        validator: (rule, value) => {
                                                            if (value && value.length > 0) {
                                                                const isImage = value.every(file => file.type.startsWith('image/'));
                                                                if (!isImage) {
                                                                    return Promise.reject('You can only upload image files!');
                                                                }
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Upload
                                                    style={{ borderRadius: '5px' }}
                                                    name="fileRegisteration"
                                                    multiple={false}
                                                    beforeUpload={() => false} // Prevent automatic upload
                                                    accept=".png, .jpg"
                                                >
                                                    <Button icon={<UploadOutlined />} style={{
                                                        borderRadius: '5px',
                                                        borderColor: '#2D5FF4',
                                                        width: '275px',
                                                    }} >
                                                        Edit Image
                                                    </Button>
                                                </Upload>
                                            </Form.Item> */}

                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                    Remark
                                                </span>}
                                                name="remark"
                                                initialValue={sessionData?.overtime?.remark ? sessionData?.overtime?.remark : 'N/A'}
                                            >
                                                <TextArea
                                                    style={{ width: "90%", borderRadius: "5px" }}
                                                    className='textStyles-small'
                                                    placeholder="Type Remark"
                                                    onChange={(e) => setApprovalRemark(e.target.value)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={24} style={{ display: 'flex' }}>
                                            <Form.Item>
                                                {sessionData?.sessionCreationType !== 'AUTOMATIC' ? (
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        style={{ width: "150px", borderRadius: "5px" }}
                                                        className='textStyles-small'
                                                    >
                                                        Approve Session
                                                    </Button>) : (
                                                    <>
                                                        <Button
                                                            type="primary"
                                                            style={{ width: "150px", borderRadius: "5px" }}
                                                            className='textStyles-small'
                                                            onClick={() => approveAutomaticSessionByAdmin()}
                                                        >
                                                            Approve Session
                                                        </Button>

                                                        <Button
                                                            type="danger"
                                                            style={{ width: "150px", borderRadius: "5px", marginLeft: '10px' }}
                                                            className='textStyles-small'
                                                            onClick={() => rejectAutomaticSessionByAdmin()}
                                                        >
                                                            Reject Session
                                                        </Button>
                                                    </>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Modal>

                <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={sessionData?.overtime?.specialAllowancesProof ? baseImgURL + sessionData?.overtime?.specialAllowancesProof : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                    preview={{
                        visible,
                        scaleStep,
                        src: sessionData?.overtime?.specialAllowancesProof
                            ? baseImgURL + sessionData?.overtime?.specialAllowancesProof
                            : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png",
                        onVisibleChange: (value) => {
                            setVisible(value);
                        },
                    }}
                />

                <Image
                    width={200}
                    style={{
                        display: 'none',
                    }}
                    src={sessionData?.overtime?.accommodationProof ? baseImgURL + sessionData?.overtime?.accommodationProof : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                    preview={{
                        visible: isImageVisible,
                        scaleStep: customScale,
                        src: sessionData?.overtime?.accommodationProof ? baseImgURL + sessionData?.overtime?.accommodationProof :
                            "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png",
                        onVisibleChange: (value) => {
                            setIsImageVisible(value);
                        },
                    }}
                />


                <Modal
                    title={<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{ margin: 0 }}><b>Journey Overview</b></p>
                        <p style={{ margin: 0, fontSize: '20px' }}><b>
                            {journeyDetails.code ? journeyDetails.code : 'N/A'}
                        </b></p>
                    </div>}
                    visible={infoModel}
                    onOk={closeInfoModel}
                    onCancel={closeInfoModel}
                    footer={[]}
                    width={850}
                    destroyOnClose={true}
                >
                    <div>
                        <div style={{ padding: '20px', backgroundColor: '#ffffff', borderRadius: '10px', overflow: 'auto', maxHeight: '450px' }}>
                            <div>
                                <Steps
                                    size='small'
                                    style={{ color: '#000000' }}
                                    direction="horizontal"
                                    current={currentStep}
                                    items={[
                                        {
                                            title: <div><b>Journey Started</b></div>,
                                            description: <span style={{ fontSize: '12px' }} className='textStyleChild'>
                                                {journeyDetails.startDateTime ? getOnlyDate(journeyDetails.startDateTime) : 'N/A'} - {journeyDetails.startDateTime ? dayjs(journeyDetails.startDateTime).format('HH:mm') : 'N/A'}
                                            </span>,
                                        },
                                        {
                                            title: <div><b>Journey End</b></div>,
                                            description: <span style={{ fontSize: '12px' }} className='textStyleChild'>
                                                {journeyDetails.endDateTime ? getOnlyDate(journeyDetails.endDateTime) : 'N/A'} - {journeyDetails.endDateTime ? dayjs(journeyDetails.endDateTime).format('HH:mm') : 'N/A'}
                                            </span>
                                        },
                                        {
                                            title: <div><b>Admin Verified</b></div>,
                                        },
                                    ]}
                                />
                            </div>

                            <div style={{ height: '30px' }} />

                            <Row gutter={24}>
                                <Col span={12}>
                                    <div style={{ padding: 5, background: '#ffffff', borderRadius: 10, border: '1px solid #f0f0f0', padding: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                                        <LoadScript googleMapsApiKey="AIzaSyAYye4nok_qEPk0bHJgzEEhwzXaRxlkM0Y">
                                            <Row gutter={16}>
                                                {
                                                    journeyDetails?.status === 'ON_GOING' ? (
                                                        <>
                                                            <Col span={24}>
                                                                <h6 style={{ textAlign: 'center' }}><b className='textStyle'>
                                                                    Start Location
                                                                </b></h6>
                                                                <GoogleMap
                                                                    mapContainerStyle={mapStyles}
                                                                    zoom={10}
                                                                    center={startMapCenter}
                                                                >
                                                                    <Marker
                                                                        position={startMapCenter}
                                                                    // label='Start'
                                                                    />
                                                                </GoogleMap>
                                                            </Col>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Col span={12}>
                                                                <h6 style={{ textAlign: 'center' }}><b className='textStyle'>
                                                                    Start Location
                                                                </b></h6>
                                                                <GoogleMap
                                                                    mapContainerStyle={mapStyles}
                                                                    zoom={10}
                                                                    center={startMapCenter}
                                                                >
                                                                    <Marker
                                                                        position={startMapCenter}
                                                                    // label='Start'
                                                                    />
                                                                </GoogleMap>
                                                            </Col>
                                                            <Col span={12}>
                                                                <h6 style={{ textAlign: 'center' }}><b className='textStyle'>
                                                                    End Location
                                                                </b></h6>
                                                                <GoogleMap
                                                                    mapContainerStyle={mapStyles}
                                                                    zoom={10}
                                                                    center={endMapCenter}
                                                                >
                                                                    <Marker position={endMapCenter} />
                                                                </GoogleMap>
                                                            </Col>
                                                        </>
                                                    )
                                                }

                                            </Row>
                                        </LoadScript>
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <div style={{ padding: 5, background: '#ffffff', borderRadius: 10, border: '1px solid #f0f0f0', padding: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                                        <h6 style={{ textAlign: 'center' }}><b className='textStyle'>
                                            Mileage -Km
                                        </b></h6>
                                        <Row style={{ marginTop: '10px' }}>
                                            <div style={{ float: "right", width: "48%", backgroundColor: '#ffdede', borderRadius: '10px', padding: '10px' }}>
                                                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <div>
                                                        <DashboardOutlined style={{ fontSize: '35px', color: '#000000' }} />
                                                    </div>
                                                    <div style={{ marginLeft: '10px' }}>
                                                        <div style={{ fontSize: '9px', fontWeight: 'bold', color: '#252525' }}>
                                                            Start Meter Reading
                                                        </div>
                                                        <div style={{ fontSize: '13px', fontWeight: 'bold', color: '#252525' }}>
                                                            {journeyDetails.startMeterReading ? journeyDetails.startMeterReading : 0}
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>

                                            <div style={{ float: "right", width: "48%", backgroundColor: '#ffdede', borderRadius: '10px', padding: '10px', marginLeft: '9px' }}>
                                                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <div>
                                                        <DashboardOutlined style={{ fontSize: '35px', color: '#000000' }} />
                                                    </div>
                                                    <div style={{ marginLeft: '10px' }}>
                                                        <div style={{ fontSize: '9px', fontWeight: 'bold', color: '#252525' }}>
                                                            End Meter Reading
                                                        </div>
                                                        <div style={{ fontSize: '13px', fontWeight: 'bold', color: '#252525' }}>
                                                            {journeyDetails.endMeterReading ? journeyDetails.endMeterReading : 0}
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                        </Row>

                                        <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#E1F2DD', borderRadius: '10px', padding: '10px', marginTop: '10px' }}>
                                            <span style={{ fontSize: '13px', fontWeight: 'bold', color: '#252525' }}>
                                                {journeyDetails.totalDistance ? journeyDetails.totalDistance : 0} Km
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div>
                            <Row>
                                    <Descriptions
                                        bordered
                                        column={1}
                                        size='small'
                                        style={{ width: '100%', marginTop: '20px', borderRadius: '10px', border: '1px solid #f0f0f0', padding: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}
                                    >
                                        <Descriptions.Item
                                            label={
                                                <span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                    Start Meter Reading Proof
                                                </span>
                                            }>
                                            <Image
                                                width={50}
                                                height={50}
                                                style={{ borderRadius: '5px' }}
                                                src={journeyDetails?.startMeterProof ? baseImgURL + journeyDetails?.startMeterProof : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                placeholder={
                                                    <Image
                                                        preview={false}
                                                        src={journeyDetails?.startMeterProof ? baseImgURL + journeyDetails?.startMeterProof : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                        width={50}
                                                    />
                                                }
                                            />
                                        </Descriptions.Item>

                                        <Descriptions.Item
                                            label={
                                                <span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                    End Meter Reading Proof
                                                </span>
                                            }>
                                            <Image
                                                width={50}
                                                height={50}
                                                style={{ borderRadius: '5px' }}
                                                src={journeyDetails?.endMeterProof ? baseImgURL + journeyDetails?.endMeterProof : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                placeholder={
                                                    <Image
                                                        preview={false}
                                                        src={journeyDetails?.endMeterProof ? baseImgURL + journeyDetails?.endMeterProof : "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"}
                                                        width={50}
                                                    />
                                                }
                                            />
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Row>
                                <Row>
                                    <div style={{ padding: 5, background: '#ffffff', borderRadius: 10, border: '1px solid #f0f0f0', padding: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: '20px', width: '24%', marginRight: '5px' }}>
                                        <h6 style={{ textAlign: 'center' }} className='textStyle'>Journey Duration</h6>
                                        <h5 style={{ textAlign: 'center' }}><b className='textStyle2'>
                                            {journeyDetails?.journeyDuration ? convertMinutesToHoursAndMinutes(journeyDetails?.journeyDuration) : 'N/A'}
                                        </b></h5>
                                    </div>

                                    <div style={{ padding: 5, background: '#ffffff', borderRadius: 10, border: '1px solid #f0f0f0', padding: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: '20px', width: '24%', marginRight: '6px' }}>
                                        <h6 style={{ textAlign: 'center' }} className='textStyle'>Driver Name</h6>
                                        <h5 style={{ textAlign: 'center' }}><b className='textStyle2'>
                                            {singleVehicleDetails?.driverName ? singleVehicleDetails?.driverName : 'N/A'}
                                        </b></h5>
                                    </div>

                                    <div style={{ padding: 5, background: '#ffffff', borderRadius: 10, border: '1px solid #f0f0f0', padding: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: '20px', width: '24%', marginRight: '6px' }}>
                                        <h6 style={{ textAlign: 'center' }} className='textStyle'>Driver Contact</h6>
                                        <h5 style={{ textAlign: 'center' }}><b className='textStyle2'>
                                            {singleVehicleDetails?.driverContact ? singleVehicleDetails?.driverContact : 'N/A'}
                                        </b></h5>
                                    </div>

                                    <div style={{ padding: 5, background: '#ffffff', borderRadius: 10, border: '1px solid #f0f0f0', padding: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: '20px', width: '24%', marginRight: '6px' }}>
                                        <h6 style={{ textAlign: 'center' }} className='textStyle'>Vehicle Number</h6>
                                        <h5 style={{ textAlign: 'center' }}><b className='textStyle2'>
                                            {singleVehicleDetails?.registrationNumber ? singleVehicleDetails?.registrationNumber : 'N/A'}
                                        </b></h5>
                                    </div>

                                </Row>
                            </div>

                            {
                                journeyDetails?.approvalStatus === 'PENDING_APPROVAL' && journeyDetails?.status === 'COMPLETED' ? (
                                    <div style={{
                                        padding: '20px',
                                        backgroundColor: '#ffffff',
                                        background: '#ffffff',
                                        borderRadius: 10,
                                        border: '1px solid #f0f0f0',
                                        padding: '20px',
                                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                                        marginTop: '20px'
                                    }}>
                                        <Form
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            layout="vertical"
                                            onFinish={handleFormSubmit}
                                            size='small'
                                            title='Journey Approval'
                                        >
                                            <div>
                                                <span className='textStyle2'>
                                                    <b>Journey Approve</b>
                                                </span>
                                                <Row style={{ marginTop: '20px' }}>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                                Start Date
                                                            </span>}
                                                            name="startDate"
                                                            format="DD-MM-YYYY"
                                                            initialValue={moment(journeyDetails.startDateTime ? dayjs(journeyDetails.startDateTime).format('DD-MM-YYYY') : null, 'DD-MM-YYYY')}
                                                        >
                                                            <DatePicker
                                                                className='textStyles-small'
                                                                style={{ width: "90%", borderRadius: "5px" }}
                                                                size='medium'
                                                                onChange={(date, dateString) => {
                                                                    setStartDate(dateString);
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                                Start Time
                                                            </span>}
                                                            name="startTime"
                                                            rules={[{ required: true, message: 'Please input start time!' }]}
                                                            initialValue={moment(journeyDetails.startDateTime ? dayjs(journeyDetails.startDateTime).format('HH:mm') : null, 'HH:mm')}
                                                        >
                                                            <TimePicker
                                                                style={{ width: "90%", borderRadius: "5px" }}
                                                                format="HH:mm"
                                                                size='medium'
                                                                className='textStyles-small'
                                                                onChange={(time, timeString) => {
                                                                    setStartTime(timeString);
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                                End Date
                                                            </span>}
                                                            name="endDate"
                                                            rules={[{ required: true, message: 'Please input end date!' }]}
                                                            format="DD-MM-YYYY"
                                                            initialValue={moment(journeyDetails.endDateTime ? dayjs(journeyDetails.endDateTime).format('DD-MM-YYYY') : null, 'DD-MM-YYYY')}
                                                        >
                                                            <DatePicker
                                                                size='medium'
                                                                className='textStyles-small'
                                                                style={{ width: "90%", borderRadius: "5px" }}
                                                                onChange={(date, dateString) => {
                                                                    setEndDate(dateString);
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                                End Time
                                                            </span>}
                                                            name="endTime"
                                                            rules={[{ required: true, message: 'Please input end time!' }]}
                                                            initialValue={moment(journeyDetails.endDateTime ? dayjs(journeyDetails.endDateTime).format('HH:mm') : null, 'HH:mm')}
                                                        >
                                                            <TimePicker
                                                                className='textStyles-small'
                                                                style={{ width: "90%", borderRadius: "5px" }}
                                                                format="HH:mm"
                                                                size='medium'
                                                                onChange={(time, timeString) => {
                                                                    setEndTime(timeString);
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                                Start Meter Reading ({singleVehicleDetails?.distanceUnit ? singleVehicleDetails?.distanceUnit : ''})
                                                            </span>}
                                                            name="startMeterReading"
                                                            rules={[{ required: true, message: 'Please input start meter reading!' },
                                                            ({ getFieldValue }) => ({
                                                                validator(_, value) {
                                                                    if (value >= 0) {
                                                                        return Promise.resolve();
                                                                    }
                                                                    return Promise.reject(new Error('Please input a non-negative value for start meter reading!'));
                                                                },
                                                            }),
                                                            ]}
                                                            initialValue={journeyDetails.startMeterReading ? journeyDetails.startMeterReading : 0}
                                                        >
                                                            <Input
                                                                type="number"
                                                                style={{ width: "90%", borderRadius: "5px" }}
                                                                className='textStyles-small'
                                                                placeholder="Type Start Meter Reading"
                                                                size='medium'
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12}>
                                                        <Form.Item
                                                            label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                                End Meter Reading ({singleVehicleDetails?.distanceUnit ? singleVehicleDetails?.distanceUnit : ''})
                                                            </span>}
                                                            name="endMeterReading"
                                                            rules={[{ required: true, message: 'Please input end meter reading!' },
                                                            ({ getFieldValue }) => ({
                                                                validator(_, value) {
                                                                    if (value >= 0) {
                                                                        return Promise.resolve();
                                                                    }
                                                                    return Promise.reject(new Error('Please input a non-negative value for end meter reading!'));
                                                                },
                                                            }),
                                                            ]}
                                                            initialValue={journeyDetails.endMeterReading ? journeyDetails.endMeterReading : 0}
                                                        >
                                                            <Input
                                                                type="number"
                                                                style={{ width: "90%", borderRadius: "5px" }}
                                                                className='textStyles-small'
                                                                placeholder="Type End Meter Reading"
                                                                size='medium'
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>

                                                    <Col span={12}>
                                                        <Form.Item
                                                            label={<span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                                Remark
                                                            </span>}
                                                            name="adminRemark"
                                                        >
                                                            <TextArea
                                                                style={{ width: "90%", borderRadius: "5px", color: 'black' }}
                                                                placeholder="Type Admin Remark"
                                                                size='small'
                                                                className='textStyles-small'
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <Row>
                                                <Col span={24} style={{ textAlign: 'right', marginRight: '100px' }}>
                                                    <Form.Item>
                                                        {
                                                            (checkPermissions('FIXED-USER') || checkPermissions('VEHICLE-ADMIN') || checkPermissions('VEHICLE-SUPER-ADMIN')) ? (
                                                                <Button type="primary" htmlType="submit"
                                                                    size='medium'
                                                                    className='textStyles-small'
                                                                    style={{ marginLeft: '10px', borderRadius: '5px', width: '150px', backgroundColor: '#2D5FF4', borderColor: '#2D5FF4' }}>
                                                                    Approve Journey
                                                                </Button>
                                                            ) : null
                                                        }
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            <div
                                style={{
                                    padding: '20px',
                                    backgroundColor: '#ffffff',
                                    background: '#ffffff',
                                    borderRadius: 10,
                                    border: '1px solid #f0f0f0',
                                    padding: '20px',
                                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                                    marginTop: '20px'
                                }}
                            >
                                <span className='textStyle2'>
                                    <b>Journey Edit History</b>
                                </span>

                                <Descriptions
                                    title={
                                        <span>
                                            <span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                Edited By Admin
                                            </span>
                                        </span>
                                    }
                                    bordered
                                    size='small'
                                    column={2}
                                >
                                    {
                                        journeyDetails?.journeyEditHistoryAdmin?.startMeterReadingEditedByAdmin ? (
                                            <>
                                                <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Start Meter Reading Edited By Admin</span>} span={1}>
                                                    <span className='textStyles-small'>{journeyDetails?.journeyEditHistoryAdmin?.startMeterReadingEditedByAdmin ? 'Yes' : 'No'}</span>
                                                </Descriptions.Item>
                                                <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Admin Original Start Meter Reading</span>} span={1}>
                                                    <span className='textStyles-small'>{journeyDetails?.journeyEditHistoryAdmin?.adminOriginalStartMeterReading ? journeyDetails?.journeyEditHistoryAdmin?.adminOriginalStartMeterReading : 'N/A'}</span>
                                                </Descriptions.Item>
                                            </>
                                        ) : (
                                            <></>
                                        )
                                    }

                                    {
                                        journeyDetails?.journeyEditHistoryAdmin?.startMeterProofEditedByAdmin ? (
                                            <>
                                                <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Start Meter Proof Edited By Admin</span>} span={1}>
                                                    <span className='textStyles-small'>{journeyDetails?.journeyEditHistoryAdmin?.startMeterProofEditedByAdmin ? 'Yes' : 'No'}</span>
                                                </Descriptions.Item>
                                                <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Admin Original Start Meter Proof</span>} span={1}>
                                                    {/* <span className='textStyles-small'>{journeyDetails?.journeyEditHistoryAdmin?.adminOriginalStartMeterProof ? journeyDetails?.journeyEditHistoryAdmin?.adminOriginalStartMeterProof : 'N/A'}</span> */}
                                                    <Image
                                                        width={50}
                                                        height={50}
                                                        style={{ borderRadius: '5px' }}
                                                        src={baseImgURL + journeyDetails?.journeyEditHistoryAdmin?.adminOriginalStartMeterProof}
                                                        placeholder={
                                                            <Image
                                                                preview={false}
                                                                src={baseImgURL + journeyDetails?.journeyEditHistoryAdmin?.adminOriginalStartMeterProof}
                                                                width={50}
                                                            />
                                                        }
                                                    />
                                                </Descriptions.Item>
                                            </>
                                        ) : (
                                            <></>
                                        )
                                    }

                                    {
                                        journeyDetails?.journeyEditHistoryAdmin?.startDateTimeEditedByAdmin ? (
                                            <>
                                                <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Start Date Time Edited By Admin</span>} span={1}>
                                                    <span className='textStyles-small'>{journeyDetails?.journeyEditHistoryAdmin?.startDateTimeEditedByAdmin ? 'Yes' : 'No'}</span>
                                                </Descriptions.Item>
                                                <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Admin Original Start Date Time</span>} span={1}>
                                                    <span className='textStyles-small'>{journeyDetails?.journeyEditHistoryAdmin?.adminOriginalStartDateTime ? formateDate(journeyDetails?.journeyEditHistoryAdmin?.adminOriginalStartDateTime) : 'N/A'}</span>
                                                </Descriptions.Item>
                                            </>
                                        ) : (
                                            <></>
                                        )
                                    }

                                    {
                                        journeyDetails?.journeyEditHistoryAdmin?.endMeterReadingEditedByAdmin ? (
                                            <>
                                                <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>End Meter Reading Edited By Admin</span>} span={1}>
                                                    <span className='textStyles-small'>{journeyDetails?.journeyEditHistoryAdmin?.endMeterReadingEditedByAdmin ? 'Yes' : 'No'}</span>
                                                </Descriptions.Item>
                                                <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Admin Original End Meter Reading</span>} span={1}>
                                                    <span className='textStyles-small'>{journeyDetails?.journeyEditHistoryAdmin?.adminOriginalEndMeterReading ? journeyDetails?.journeyEditHistoryAdmin?.adminOriginalEndMeterReading : 'N/A'}</span>
                                                </Descriptions.Item>
                                            </>
                                        ) : (
                                            <></>
                                        )
                                    }

                                    {
                                        journeyDetails?.journeyEditHistoryAdmin?.endMeterProofEditedByAdmin ? (
                                            <>
                                                <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>End Meter Proof Edited By Admin</span>} span={1}>
                                                    <span className='textStyles-small'>{journeyDetails?.journeyEditHistoryAdmin?.endMeterProofEditedByAdmin ? 'Yes' : 'No'}</span>
                                                </Descriptions.Item>
                                                <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Admin Original End Meter Proof</span>} span={1}>
                                                    {/* <span className='textStyles-small'>{journeyDetails?.journeyEditHistoryAdmin?.adminOriginalEndMeterProof ? journeyDetails?.journeyEditHistoryAdmin?.adminOriginalEndMeterProof : 'N/A'}</span> */}
                                                    <Image
                                                        width={50}
                                                        height={50}
                                                        style={{ borderRadius: '5px' }}
                                                        src={baseImgURL + journeyDetails?.journeyEditHistoryAdmin?.adminOriginalEndMeterProof}
                                                        placeholder={
                                                            <Image
                                                                preview={false}
                                                                src={baseImgURL + journeyDetails?.journeyEditHistoryAdmin?.adminOriginalEndMeterProof}
                                                                width={50}
                                                            />
                                                        }
                                                    />
                                                </Descriptions.Item>
                                            </>
                                        ) : (
                                            <></>
                                        )
                                    }

                                    {
                                        journeyDetails?.journeyEditHistoryAdmin?.endDateTimeEditedByAdmin ? (
                                            <>
                                                <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>End Date Time Edited By Admin</span>} span={1}>
                                                    <span className='textStyles-small'>{journeyDetails?.journeyEditHistoryAdmin?.endDateTimeEditedByAdmin ? 'Yes' : 'No'}</span>
                                                </Descriptions.Item>
                                                <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Admin Original End Date Time</span>} span={1}>
                                                    <span className='textStyles-small'>{journeyDetails?.journeyEditHistoryAdmin?.adminOriginalEndDateTime ? formateDate(journeyDetails?.journeyEditHistoryAdmin?.adminOriginalEndDateTime) : 'N/A'}</span>
                                                </Descriptions.Item>
                                            </>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </Descriptions>

                                {journeyDetails?.journeyEditHistoryDriver?.map((item, index) => (
                                    <div key={index}>
                                        <Descriptions
                                            title={
                                                <span>
                                                    <span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                        Edit By Driver At :  {item?.createdOn ? formateDate(item?.createdOn) : 'N/A'}
                                                    </span>
                                                </span>
                                            }
                                            bordered
                                            size='small'
                                            column={2}
                                            style={{ marginTop: '20px' }}
                                        >
                                            {
                                                item.startMeterReadingEditedByDriver ? (
                                                    <>
                                                        <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Start Meter Reading Edited By Driver</span>} span={1}>
                                                            <span className='textStyles-small'>{item.startMeterReadingEditedByDriver ? 'Yes' : 'No'}</span>
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Original Start Meter Reading</span>} span={1}>
                                                            <span className='textStyles-small'>{item.originalStartMeterReading ? item.originalStartMeterReading : 'N/A'}</span>
                                                        </Descriptions.Item>
                                                    </>
                                                ) : (
                                                    <></>
                                                )
                                            }

                                            {
                                                item.startMeterProofEditedByDriver ? (
                                                    <>
                                                        <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Start Meter Proof Edited By Driver</span>} span={1}>
                                                            <span className='textStyles-small'>{item.startMeterProofEditedByDriver ? 'Yes' : 'No'}</span>
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Original Start Meter Proof</span>} span={1}>
                                                            <Image
                                                                width={50}
                                                                height={50}
                                                                style={{ borderRadius: '5px' }}
                                                                src={baseImgURL + item.originalStartMeterProof}
                                                                placeholder={
                                                                    <Image
                                                                        preview={false}
                                                                        src={baseImgURL + item.originalStartMeterProof}
                                                                        width={50}
                                                                    />
                                                                }
                                                            />
                                                        </Descriptions.Item>
                                                    </>
                                                ) : (
                                                    <></>
                                                )
                                            }

                                            {
                                                item.startDateTimeEditedByDriver ? (
                                                    <>
                                                        <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Start Date Time Edited By Driver</span>} span={1}>
                                                            <span className='textStyles-small'>{item.startDateTimeEditedByDriver ? 'Yes' : 'No'}</span>
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Original Start Date Time</span>} span={1}>
                                                            <span className='textStyles-small'>{item.originalStartDateTime ? formateDate(item.originalStartDateTime) : 'N/A'}</span>
                                                        </Descriptions.Item>
                                                    </>
                                                ) : (
                                                    <></>
                                                )
                                            }

                                            {
                                                item.endMeterReadingEditedByDriver ? (
                                                    <>
                                                        <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>End Meter Reading Edited By Driver</span>} span={1}>
                                                            <span className='textStyles-small'>{item.endMeterReadingEditedByDriver ? 'Yes' : 'No'}</span>
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Original End Meter Reading</span>} span={1}>
                                                            <span className='textStyles-small'>{item.originalEndMeterReading ? item.originalEndMeterReading : 'N/A'}</span>
                                                        </Descriptions.Item>
                                                    </>
                                                ) : (
                                                    <></>
                                                )
                                            }

                                            {
                                                item.endMeterProofEditedByDriver ? (
                                                    <>
                                                        <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>End Meter Proof Edited By Driver</span>} span={1}>
                                                            <span className='textStyles-small'>{item.endMeterProofEditedByDriver ? 'Yes' : 'No'}</span>
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Original End Meter Proof</span>} span={1}>
                                                            {/* <span className='textStyles-small'>{item.originalEndMeterProof ? item.originalEndMeterProof : 'N/A'}</span> */}
                                                            <Image
                                                                width={50}
                                                                height={50}
                                                                style={{ borderRadius: '5px' }}
                                                                src={baseImgURL + item.originalEndMeterProof}
                                                                placeholder={
                                                                    <Image
                                                                        preview={false}
                                                                        src={baseImgURL + item.originalEndMeterProof}
                                                                        width={50}
                                                                    />
                                                                }
                                                            />
                                                        </Descriptions.Item>
                                                    </>
                                                ) : (
                                                    <></>
                                                )
                                            }

                                            {
                                                item.endDateTimeEditedByDriver ? (
                                                    <>
                                                        <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>End Date Time Edited By Driver</span>} span={1}>
                                                            <span className='textStyles-small'>{item.endDateTimeEditedByDriver ? 'Yes' : 'No'}</span>
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Original End Date Time</span>} span={1}>
                                                            <span className='textStyles-small'>{item.originalEndDateTime ? formateDate(item.originalEndDateTime) : 'N/A'}</span>
                                                        </Descriptions.Item>
                                                    </>
                                                ) : (
                                                    <></>
                                                )
                                            }

                                            {
                                                item.travelingOfficerEditedByDriver ? (
                                                    <>
                                                        <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Traveling Officer Edited By Driver</span>} span={1}>
                                                            <span className='textStyles-small'>{item.travelingOfficerEditedByDriver ? 'Yes' : 'No'}</span>
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Original Traveling Officer</span>} span={1}>
                                                            <span className='textStyles-small'>{item.originalTravelingOfficer ? item.originalTravelingOfficer : 'N/A'}</span>
                                                        </Descriptions.Item>
                                                    </>
                                                ) : (
                                                    <></>
                                                )
                                            }

                                            {
                                                item.commentEditedByDriver ? (
                                                    <>
                                                        <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Comment Edited By Driver</span>} span={1}>
                                                            <span className='textStyles-small'>{item.commentEditedByDriver ? 'Yes' : 'No'}</span>
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Original Comment</span>} span={1}>
                                                            <span className='textStyles-small'>{item.originalComment ? item.originalComment : 'N/A'}</span>
                                                        </Descriptions.Item>
                                                    </>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                        </Descriptions>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>

                </Modal>

                <div className='row' style={{ backgroundColor: '#ffffff', borderRadius: '10px', width: '100%' }}>
                    <Row>
                        <Col span={12}>
                            <Descriptions title="Session Information" style={{ width: '100%', padding: '20px', borderRadius: '10px' }} bordered size='small'>
                                <Descriptions.Item label={<span className='textStyle'>Session Code</span>}
                                    span={3}><span className='descriptionStyle'>{sessionData?.sessionCode ? sessionData?.sessionCode : 'N/A'}</span></Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyle'>Checkin Date Time</span>}
                                    span={3}><span className='descriptionStyle'>
                                        {sessionData?.checkinDateTime
                                            ? (() => {
                                                const dateObject = new Date(sessionData.checkinDateTime);
                                                const options = {
                                                    year: 'numeric',
                                                    month: 'numeric',
                                                    day: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                };

                                                return dateObject.toLocaleString(undefined, options);
                                            })()
                                            : 'N/A'
                                        }
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyle'>Checkout Date Time</span>}
                                    span={3}>
                                    <span className='descriptionStyle'>{sessionData?.checkoutDateTime ?
                                        (() => {
                                            const dateObject = new Date(sessionData.checkoutDateTime);
                                            const options = {
                                                year: 'numeric',
                                                month: 'numeric',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                            };

                                            return dateObject.toLocaleString(undefined, options);
                                        }
                                        )()
                                        : 'N/A'}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyle'>Created On </span>}
                                    span={3}>
                                    <span className='descriptionStyle'>{sessionData?.createdOn ?
                                        (() => {
                                            const dateObject = new Date(sessionData.createdOn);
                                            const options = {
                                                year: 'numeric',
                                                month: 'numeric',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                            };
                                            return dateObject.toLocaleString(undefined, options);
                                        }
                                        )()
                                        : 'N/A'}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyle'>Updated On</span>}
                                    span={3}>
                                    <span className='descriptionStyle'>{sessionData?.updatedOn ?
                                        (() => {
                                            const dateObject = new Date(sessionData.updatedOn);
                                            const options = {
                                                year: 'numeric',
                                                month: 'numeric',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                            };

                                            return dateObject.toLocaleString(undefined, options);
                                        }
                                        )()
                                        : 'N/A'}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyle'>Hours Worked</span>}
                                    span={3}><span className='descriptionStyle'>{sessionData?.hoursWorked ? convertMinutesToHoursAndMinutes(sessionData?.hoursWorked) : '0 Hours'}</span></Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyle'>Session Status</span>}
                                    span={3}><span className='descriptionStyle'>{sessionData?.sessionStatus ? sessionData?.sessionStatus === 'CLOSED' ? 'CLOSED' : 'PENDING' : 'N/A'}</span></Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyle'>Approval Status</span>}
                                    span={3}>
                                    <span className='descriptionStyle'>{sessionData?.sessionApprovalStatus ?
                                        sessionData?.sessionApprovalStatus === 'PENDING_APPROVAL' ? 'PENDING' :
                                            sessionData?.sessionApprovalStatus === 'REJECTED' ? 'REJECTED' :
                                                'APPROVED' : 'N/A'}</span>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>

                        <Col span={12}>
                            <Descriptions title="Vehicle Information" style={{ width: '100%', padding: '20px' }} bordered size='small'>
                                <Descriptions.Item label={<span className='textStyle'>Vehicle Number</span>}
                                    span={3}><span className='descriptionStyle'>{singleVehicleDetails?.registrationNumber ? singleVehicleDetails?.registrationNumber : 'N/A'}</span></Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyle'>Driver Name </span>}
                                    span={3}><span className='descriptionStyle'>{singleVehicleDetails?.driverName ? singleVehicleDetails?.driverName : 'N/A'}</span></Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyle'>Driver Contact</span>}
                                    span={3}><span className='descriptionStyle'>{singleVehicleDetails?.driverContact ? singleVehicleDetails?.driverContact : 'N/A'}</span></Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyle'>Vehicle Category</span>}
                                    span={3}><span className='descriptionStyle'>{singleVehicleDetails?.vehicleMaster?.vehicleCategory ? singleVehicleDetails?.vehicleMaster?.vehicleCategory : 'N/A'}</span></Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyle'>Travelling Officer</span>}
                                    span={3}><span className='descriptionStyle'>{singleVehicleDetails?.vehicleMaster?.travellingOfficer ? singleVehicleDetails?.vehicleMaster?.travellingOfficer : 'N/A'}</span></Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyle'>Vehicle Type</span>}
                                    span={3}><span className='descriptionStyle'>{singleVehicleDetails?.vehicleType ? singleVehicleDetails?.vehicleType?.name : 'N/A'}</span></Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyle'>Distance Unit</span>}
                                    span={3}><span className='descriptionStyle'>{singleVehicleDetails?.distanceUnit ? singleVehicleDetails?.distanceUnit : 'N/A'}</span></Descriptions.Item>
                                <Descriptions.Item label={<span className='textStyle'>Vehicle Master</span>}
                                    span={3}><span className='descriptionStyle'>{singleVehicleDetails?.vehicleMaster?.code ? singleVehicleDetails?.vehicleMaster?.code : 'N/A'}</span></Descriptions.Item>
                            </Descriptions>
                        </Col>


                    </Row>
                </div>

                <div style={{ padding: '20px', backgroundColor: '#ffffff', marginTop: '20px', borderRadius: '10px' }}>
                    <div style={{ padding: 5, background: '#ffffff', borderRadius: 10 }}>
                        <Row>
                            <Col span={10}>
                                <h5>
                                    <b className='textStyle3'>
                                        Journey Information
                                    </b>
                                </h5>
                            </Col>
                        </Row>
                    </div>

                    <Table
                        className="table-striped-rows"
                        columns={columns}
                        dataSource={allJobs.reverse()}
                        size='medium'
                        pagination={false}
                    />

                    <div style={{ padding: 5, background: '#ffffff', borderRadius: 10, marginTop: '20px' }}>
                        <Row>
                            <Col span={10}>
                                <h5>
                                    <b className='textStyle3'>
                                        Session Information
                                    </b>
                                </h5>
                            </Col>
                        </Row>
                    </div>
                    <Table
                        columns={overtimeInfo}
                        dataSource={sessionData?.overtime ? [sessionData.overtime] : []}
                        size='medium'
                        pagination={false}
                        loading={sessionData?.overtime ? false : true}
                    />
                </div>

                <div style={{ padding: '20px', backgroundColor: '#ffffff', marginTop: '20px', borderRadius: '10px' }}>
                    <div style={{ padding: 5, background: '#ffffff', borderRadius: 10 }}>
                        <Row>
                            <Col span={10}>
                                <h5>
                                    <b className='textStyle3'>
                                        Session Edit By Admin
                                    </b>
                                </h5>
                            </Col>
                        </Row>
                        {
                            sessionData?.sessionEditByAdmin?.id !== 0 ? (
                                <Descriptions
                                    bordered
                                    size='small'
                                    column={2}
                                    style={{ marginTop: '20px' }}
                                >
                                    {sessionData?.sessionEditByAdmin?.checkinDateTimeEditedByAdmin ? (
                                        <>
                                            <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Checkin Date Time Edited By Admin</span>} span={1}>
                                                <span className='textStyles-small'>{sessionData?.sessionEditByAdmin?.checkinDateTimeEditedByAdmin ? 'Yes' : 'No'}</span>
                                            </Descriptions.Item>

                                            <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Original Checkin Date Time</span>} span={1}>
                                                <span className='textStyles-small'>{sessionData?.sessionEditByAdmin?.originalCheckinDateTime ? formateDate(sessionData?.sessionEditByAdmin?.originalCheckinDateTime) : 'N/A'} </span>
                                            </Descriptions.Item>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {
                                        sessionData?.sessionEditByAdmin?.checkoutDateTimeEditedByAdmin ? (
                                            <>
                                                <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Checkout Date Time Edited By Admin</span>} span={1}>
                                                    <span className='textStyles-small'>{sessionData?.sessionEditByAdmin?.checkoutDateTimeEditedByAdmin ? 'Yes' : 'No'}</span>
                                                </Descriptions.Item>

                                                <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Original Checkout Date Time</span>} span={1}>
                                                    <span className='textStyles-small'>{sessionData?.sessionEditByAdmin?.originalCheckoutDateTime ? formateDate(sessionData?.sessionEditByAdmin?.originalCheckoutDateTime) : 'N/A'}</span>
                                                </Descriptions.Item>
                                            </>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        sessionData?.sessionEditByAdmin?.normalOtEditedByAdmin ? (
                                            <>
                                                <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Normal OT Edited By Admin</span>} span={1}>
                                                    <span className='textStyles-small'>{sessionData?.sessionEditByAdmin?.normalOtEditedByAdmin ? 'Yes' : 'No'}</span>
                                                </Descriptions.Item>

                                                <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Original Normal OT</span>} span={1}>
                                                    <span className='textStyles-small'>{sessionData?.sessionEditByAdmin?.originalNormalOt ? Math.floor(sessionData?.sessionEditByAdmin?.originalNormalOt / 60) + ' Hours ' + sessionData?.sessionEditByAdmin?.originalNormalOt % 60 + ' Minutes' : 'N/A'}</span>
                                                </Descriptions.Item>
                                            </>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        sessionData?.sessionEditByAdmin?.doubleOtEditedByAdmin ? (
                                            <>
                                                <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Double OT Edited By Admin</span>} span={1}>
                                                    <span className='textStyles-small'>{sessionData?.sessionEditByAdmin?.doubleOtEditedByAdmin ? 'Yes' : 'No'}</span>
                                                </Descriptions.Item>

                                                <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Original Double OT</span>} span={1}>
                                                    <span className='textStyles-small'>{sessionData?.sessionEditByAdmin?.originalDoubleOt ? Math.floor(sessionData?.sessionEditByAdmin?.originalDoubleOt / 60) + ' Hours ' + sessionData?.sessionEditByAdmin?.originalDoubleOt % 60 + ' Minutes' : 'N/A'}</span>
                                                </Descriptions.Item>
                                            </>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </Descriptions>
                            ) : (<>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </>)
                        }
                    </div>
                </div>

                <div style={{ padding: '20px', backgroundColor: '#ffffff', marginTop: '20px', borderRadius: '10px' }}>
                    <div style={{ padding: 5, background: '#ffffff', borderRadius: 10 }}>
                        <Row>
                            <Col span={10}>
                                <h5>
                                    <b className='textStyle3'>
                                        Session Edit By Driver
                                    </b>
                                </h5>
                            </Col>
                        </Row>
                        {
                            sessionData?.sessionEditsByDriver?.map((element, index) => (
                                <div key={index}>
                                    <Descriptions
                                        title={
                                            <span>
                                                <span style={{ fontWeight: "bold" }} className='textStyles-small'>
                                                    Edit By Driver At :  {element?.createdOn ? formateDate(element?.createdOn) : 'N/A'}
                                                </span>
                                            </span>
                                        }
                                        bordered
                                        size='small'
                                        column={2}
                                        style={{ marginTop: '20px' }}
                                    >
                                        {element?.checkinDateTimeEditedByDriver ? (
                                            <>
                                                <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Checkin Date Time Edited By Driver</span>} span={1}>
                                                    <span className='textStyles-small'>{element?.checkinDateTimeEditedByDriver ? 'Yes' : 'No'}</span>
                                                </Descriptions.Item>

                                                <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Original Checkin Date Time</span>} span={1}>
                                                    <span className='textStyles-small'>{element?.originalCheckinDateTime ? formateDate(element?.originalCheckinDateTime) : 'N/A'}</span>
                                                </Descriptions.Item>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        {
                                            element?.checkoutDateTimeEditedByDriver ? (
                                                <>
                                                    <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Checkout Date Time Edited By Driver</span>} span={1}>
                                                        <span className='textStyles-small'>{element?.checkoutDateTimeEditedByDriver ? 'Yes' : 'No'}</span>
                                                    </Descriptions.Item>

                                                    <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Original Checkout Date Time</span>} span={1}>
                                                        <span className='textStyles-small'>{element?.originalCheckoutDateTime ? formateDate(element?.originalCheckoutDateTime) : 'N/A'}</span>
                                                    </Descriptions.Item>
                                                </>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        {
                                            element?.normalOtEditedByDriver ? (
                                                <>
                                                    <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Normal OT Edited By Driver</span>} span={1}>
                                                        <span className='textStyles-small'>{element?.normalOtEditedByDriver ? 'Yes' : 'No'}</span>
                                                    </Descriptions.Item>

                                                    <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Original Normal OT</span>} span={1}>
                                                        <span className='textStyles-small'>{element?.originalNormalOt ? Math.floor(element?.originalNormalOt / 60) + ' Hours ' + element?.originalNormalOt % 60 + ' Minutes' : 'N/A'}</span>
                                                    </Descriptions.Item>
                                                </>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        {
                                            element?.doubleOtEditedByDriver ? (
                                                <>
                                                    <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Double OT Edited By Driver</span>} span={1}>
                                                        <span className='textStyles-small'>{element?.doubleOtEditedByDriver ? 'Yes' : 'No'}</span>
                                                    </Descriptions.Item>

                                                    <Descriptions.Item label={<span style={{ fontWeight: "bold", width: "50%" }} className='textStyles-small'>Original Double OT</span>} span={1}>
                                                        <span className='textStyles-small'>{element?.originalDoubleOt ? Math.floor(element?.originalDoubleOt / 60) + ' Hours ' + element?.originalDoubleOt % 60 + ' Minutes' : 'N/A'}</span>
                                                    </Descriptions.Item>
                                                </>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </Descriptions>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </body>
    );
};
export default JobList;
