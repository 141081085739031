import React, { useState, useEffect } from 'react';
import {
    ClockCircleFilled,
    PlusCircleFilled,
    EditOutlined,
    DeleteOutlined,
    EyeOutlined,
    SearchOutlined
} from '@ant-design/icons';
import { Divider, Space, Card, Skeleton, Switch, Table, Select, Badge, Input, Button, Row, Upload, Col, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Image, Radio, Checkbox, Collapse, Spin } from 'antd';
import {
    deleteMasterDataAccess,
    insertMasterDataAccess,
    getAllRoleDataAccessFunction,
    getAllSubRegionsDepotCreate,
    updateRoleDataAccessFunction,
    getAllUsersDataREWAMPNewModule,
    getUserDetailsByUserID,
    createUserDetailsDataAccessFunction,
    updateUserDetailsDataAccessFunction
} from "../../api/index";
import './style.css';
const { Step } = Steps;

const RoleModule = () => {
    const [masterData, setMasterData] = useState([]);
    const [masterDataType, setMasterDataType] = useState("group");
    const [isLoading, setIsLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [subRegions, setSubRegions] = useState([]);
    const [fetchAllUsers, setFetchAllUsers] = useState([]);

    const [selectedRegionIdList, setSelectedRegionIdList] = useState([]);
    const [selectedRoleList, setSelectedRoleList] = useState([]);
    const [selectedGroupList, setSelectedGroupList] = useState([]);

    const [getAllRoleList, setGetAllRoleList] = useState([]);
    const [getAllGroupList, setGetAllGroupList] = useState([]);

    const [searchData, setSearchData] = useState([]);
    const [isView, setIsView] = useState(false);
    const [isCreate, setIsCreate] = useState(false);

    useEffect(() => {
        getMasterData('group');
        getMasterData('role');
        fetchAllSubRegionsDepotCreate();
        fetchAllUserDetails().then(r => console.log(r));
    }, []);

    const onSearchData = (searchText) => {
        if (searchText) {
            const filteredData = fetchAllUsers.filter((value) => {
                return value.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
                    value.lastName?.toLowerCase().includes(searchText.toLowerCase()) ||
                    value.email?.toLowerCase().includes(searchText.toLowerCase()) ||
                    value.mobileNumberOne?.toLowerCase().includes(searchText.toLowerCase()) ||
                    value.mobileNumberTwo?.toLowerCase().includes(searchText.toLowerCase()) ||
                    value.status?.toLowerCase().includes(searchText.toLowerCase());
            });
            setSearchData(filteredData);
        } else {
            setSearchData(fetchAllUsers);
        }
    }

    const fetchAllSubRegionsDepotCreate = async () => {
        const response = await getAllSubRegionsDepotCreate();
        if (response.success) {
            const responseData = response?.data;
            setSubRegions(responseData);
        }
    }

    const fetchUserDetailsByID = async (id) => {
        const response = await getUserDetailsByUserID(id);
        if (response.success) {
            const responseData = response?.data?.results;
            setSelectedData(responseData);
            setSelectedRegionIdList(responseData?.regions?.map(data => data.id));
            setSelectedRoleList(responseData?.roles?.map(data => data.id));
            setSelectedGroupList(responseData?.groups?.map(data => data.id));
            setModalOpen(true);
        }
    }

    const fetchAllUserDetails = async () => {
        setFetchAllUsers([]);
        setSearchData([]);
        setIsLoading(true);
        let pageCount = await fetchPageCount();
        for (let i = 1; i <= pageCount; i++) {
            const response = await getAllUsersDataREWAMPNewModule(i, 15);
            if (response.success) {
                const responseData = response?.data?.results?.data;
                setFetchAllUsers(prevState => [...prevState, ...responseData]);
                setSearchData(prevState => [...prevState, ...responseData]);
            }
        }
        setIsLoading(false);
    }

    const fetchPageCount = async () => {
        const response = await getAllUsersDataREWAMPNewModule(1, 15);
        if (response.success) {
            const responseData = response?.data?.results?.totalPages;
            return responseData;
        }
    }

    const getMasterData = async (data) => {
        setIsLoading(true);
        const response = await getAllRoleDataAccessFunction(data);
        if (response?.success) {
            if (data === 'role') {
                setGetAllRoleList(response?.data?.results);
            } else if (data === 'group') {
                setGetAllGroupList(response?.data?.results);
            }
        } else {
            message.error("Failed to fetch data");
        }
    }

    const insertMasterData = async (data) => {
        setIsLoading(true);
        const response = await createUserDetailsDataAccessFunction(data);
        console.log(response);
        if (response?.success) {
            setIsLoading(false);
            message.success('New user data inserted successfully');
            closeModal();
            fetchAllUserDetails().then(r => console.log(r));
        } else {
            setIsLoading(false);
            message.error(response?.data?.errors[0]?.errorMessage);
        }
    }

    const updateMasterData = async (id, data) => {
        setIsLoading(true);
        const response = await updateUserDetailsDataAccessFunction(id, data);
        if (response?.success) {
            setIsLoading(false);
            message.success('User data updated successfully');
            closeModal();
            setSelectedData({});
            fetchAllUserDetails().then(r => console.log(r));
        } else {
            setIsLoading(false);
            message.error("Failed to update data");
        }
    }

    const deleteMasterDataAccessMasterData = async (id) => {
        setIsLoading(true);
        console.log(id);
        const response = await deleteMasterDataAccess(masterDataType, id);
        if (response?.success) {
            setIsLoading(false);
            message.success(masterDataType + " data deleted successfully");
            closeModal();
            setSelectedData({});
            getMasterData(masterDataType);
        } else {
            setIsLoading(false);
            message.error("Failed to delete data");
        }
    }

    const closeModal = () => {
        setModalOpen(false);
        setIsUpdate(false);
        setSelectedData([]);
        setSelectedGroupList([]);
        setSelectedRoleList([]);
        setSelectedRegionIdList([]);
        setIsView(false);
        setIsCreate(false);
    }

    const data_Cols = [
        {
            title: <span className="textStyles-small">First Name</span>,
            dataIndex: 'firstName',
            key: 'firstName',
            render: (text, record) => (
                <span className="textStyles-small">{record.firstName ? record.firstName : 'Not Set Yet'}</span>
            )
        },
        {
            title: <span className="textStyles-small">Last Name</span>,
            dataIndex: 'lastName',
            key: 'lastName',
            render: (text, record) => (
                <span className="textStyles-small">{record.lastName ? record.lastName : 'Not Set Yet'}</span>
            )
        },
        {
            title: <span className="textStyles-small">Email</span>,
            dataIndex: 'email',
            key: 'email',
            render: (text, record) => (
                <span className="textStyles-small">{record.email ? record.email : 'Not Set Yet'}</span>
            )
        },
        {
            title: <span className="textStyles-small">Mobile Number</span>,
            dataIndex: 'mobileNumberOne',
            key: 'mobileNumberOne',
            render: (text, record) => (
                <span className="textStyles-small">{record.mobileNumberOne ? record.mobileNumberOne : 'Not Set Yet'}</span>
            )
        },
        {
            title: <span className="textStyles-small">Mobile Number 2</span>,
            dataIndex: 'mobileNumberTwo',
            key: 'mobileNumberTwo',
            render: (text, record) => (
                <span className="textStyles-small">{record.mobileNumberTwo ? record.mobileNumberTwo : 'Not Set Yet'}</span>
            )
        },
        {
            title: <span className="textStyles-small">Status</span>,
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <span className="textStyles-small">{record.status ? record.status : 'Not Set Yet'}</span>
            )
        },
        {
            title: <span className="textStyles-small">Action</span>,
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button
                        shape='circle'
                        size='small'
                        icon={<EditOutlined />}
                        type="primary"
                        onClick={() => {
                            setIsUpdate(true);
                            fetchUserDetailsByID(record.id).then(r => console.log(r));
                        }}
                    />
                    <Button
                        shape='circle'
                        size='small'
                        icon={<EyeOutlined />}
                        type="primary"
                        style={{ backgroundColor: '#38B36E', borderColor: '#38B36E' }}
                        onClick={() => {
                            setIsUpdate(false);
                            setIsView(true);
                            setModalOpen(true);
                            fetchUserDetailsByID(record.id).then(r => console.log(r));
                        }}
                    />
                </Space>
            )
        }
    ]

    const onFinishSubmit = async (values) => {
        const jsonRequest = {
            "firstName": values.firstName,
            "lastName": values.lastName,
            "email": values.email,
            "status": values.status,
            "mobileNumberOne": values.mobileNumberOne,
            "mobileNumberTwo": values.mobileNumberTwo,
            "roleIds": values.roles,
            "groupIds": values.groups,
            "regionIds": values.regions,
            "autoGenPassword": false
        }
        if (isUpdate) {
            updateMasterData(selectedData?.id, jsonRequest);
        } else {
            insertMasterData(jsonRequest);
        }
    }

    return (
        <body>
            <div style={{ margin: '0', padding: '10px', height: '100%' }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="row">
                                <div className="col">
                                    <h4 className="page-title" style={{ fontWeight: "bold" }}>
                                        User Module V 2.0
                                    </h4>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Admin </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">User Module V 2.0</a>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: '10px', padding: '10px', width: '100%', backgroundColor: 'white', borderRadius: '10px' }}>
                    <Row>
                        <Col span={12}>
                            <Button
                                type="primary"
                                icon={<PlusCircleFilled />}
                                style={{ marginBottom: '10px', backgroundColor: '#38B36E', borderColor: '#38B36E', borderRadius: 5 }}
                                onClick={() => {
                                    setIsUpdate(false);
                                    setModalOpen(true);
                                    setIsView(false);
                                    setIsCreate(true);
                                }}
                            >
                                <span className='textStyles-small'>Register New User</span>
                            </Button>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Input
                                prefix={<SearchOutlined />}
                                placeholder="Search User"
                                onChange={e => onSearchData(e.target.value)}
                                className='borderedSelect'
                                style={{ width: '300px', height: 32 }}
                            />
                        </Col>
                    </Row>
                    <Spin spinning={isLoading}>
                        <Table
                            className='table-striped-rows'
                            columns={data_Cols}
                            dataSource={searchData.reverse()}
                            search={true}
                            style={{ marginTop: 10 }}
                        />
                    </Spin>
                </div>
            </div>

            <Modal
                title={
                    <span className='textStyles-small' style={{ fontSize: '16px' }}>
                        {
                            isUpdate ? 'Update User Data' : isView ? 'View User Data' : 'Create User Data'
                        }
                    </span>
                }
                visible={modalOpen}
                onCancel={closeModal}
                footer={null}
                destroyOnClose={true}
                width={800}
            >
                {
                    (isUpdate || isCreate) && (
                        <Form
                            name="basic"
                            onFinish={onFinishSubmit}
                            layout='vertical'
                        >
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        label={<span className='textStyles-small'>First Name</span>}
                                        name="firstName"
                                        rules={[{ required: true, message: 'Please input your first name!' }]}
                                        initialValue={isUpdate ? selectedData?.firstName : ''}
                                    >
                                        <Input
                                            className='borderedSelect'
                                            style={{ width: '98%', height: 32 }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={<span className='textStyles-small'>Last Name</span>}
                                        name="lastName"
                                        initialValue={isUpdate ? selectedData?.lastName : ''}
                                    >
                                        <Input
                                            className='borderedSelect'
                                            style={{ width: '98%', height: 32 }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={<span className='textStyles-small'>Email</span>}
                                        name="email"
                                        rules={[{ required: true, message: 'Please input your email!' }]}
                                        initialValue={isUpdate ? selectedData?.email : ''}
                                    >
                                        <Input
                                            type='email'
                                            className='borderedSelect'
                                            style={{ width: '98%', height: 32 }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={<span className='textStyles-small'>Mobile Number 1</span>}
                                        name="mobileNumberOne"
                                        rules={[
                                            { required: true, message: 'Please input your mobile number 1!' },
                                            { pattern: /^\d{10}$/, message: 'Mobile number must be 10 digits!' }
                                        ]}
                                        initialValue={isUpdate ? selectedData?.mobileNumberOne : ''}
                                    >
                                        <Input
                                            className='borderedSelect'
                                            style={{ width: '98%', height: 32 }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={<span className='textStyles-small'>Mobile Number 2</span>}
                                        name="mobileNumberTwo"
                                        rules={[
                                            { pattern: /^\d{10}$/, message: 'Mobile number must be 10 digits!' }
                                        ]}
                                        initialValue={isUpdate ? selectedData?.mobileNumberTwo : ''}
                                    >
                                        <Input
                                            className='borderedSelect'
                                            style={{ width: '98%', height: 32 }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={<span className='textStyles-small'>Status</span>}
                                        name="status"
                                        rules={[{ required: true, message: 'Please input your status!' }]}
                                        initialValue={isUpdate ? selectedData?.status : ''}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select a Status"
                                            className='borderedSelect'
                                            style={{ width: '98%' }}
                                            bordered={false}
                                        >
                                            <Select.Option value="ACTIVE">Active</Select.Option>
                                            <Select.Option value="INACTIVE">Inactive</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={<span className='textStyles-small'>Roles</span>}
                                        name="roles"
                                        initialValue={isUpdate ? selectedRoleList : []}
                                    >
                                        <Select
                                            showSearch
                                            mode="multiple"
                                            placeholder="Select a Role"
                                            className='borderedSelect'
                                            style={{ width: '98%' }}
                                            bordered={false}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().includes(input.toLowerCase())
                                            }
                                        >
                                            {
                                                getAllRoleList.map((data, index) => {
                                                    return (
                                                        <Select.Option key={data.id} value={data.id}>{data.name}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={<span className='textStyles-small'>Groups</span>}
                                        name="groups"
                                        initialValue={isUpdate ? selectedGroupList : []}
                                    >
                                        <Select
                                            showSearch
                                            mode="multiple"
                                            placeholder="Select a Group"
                                            className='borderedSelect'
                                            style={{ width: '98%' }}
                                            bordered={false}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().includes(input.toLowerCase())
                                            }
                                        >
                                            {
                                                getAllGroupList.map((data, index) => {
                                                    return (
                                                        <Select.Option key={data.id} value={data.id}>{data.name}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={<span className='textStyles-small'>Regions</span>}
                                        name="regions"
                                        initialValue={isUpdate ? selectedRegionIdList : []}
                                    >
                                        <Select
                                            className='borderedSelect'
                                            style={{ width: '98%' }}
                                            bordered={false}
                                            showSearch
                                            mode="multiple"
                                            placeholder="Select a Region"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().includes(input.toLowerCase())
                                            }
                                        >
                                            {
                                                subRegions.map((data, index) => {
                                                    return (
                                                        <Select.Option key={data.id} value={data.id}>{data.name}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item style={{ textAlign: 'right' }}>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            style={{ width: '180px', backgroundColor: '#38B36E', borderColor: '#38B36E', borderRadius: '5px' }}
                                        >
                                            <span className='textStyles-small'>{isUpdate ? 'Update' : 'Create'} User Data</span>
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    )
                }

                {
                    isView ? (
                        <Descriptions
                            bordered
                            column={2}
                        >
                            <Descriptions.Item label={<span className='textStyles-small'>First Name</span>}>
                                <span className='textStyles-small'>{selectedData?.firstName ? selectedData?.firstName : 'Not Set Yet'}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Last Name</span>}>
                                <span className='textStyles-small'>{selectedData?.lastName ? selectedData?.lastName : 'Not Set Yet'}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Email</span>}>
                                <span className='textStyles-small'>{selectedData?.email ? selectedData?.email : 'Not Set Yet'}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Mobile Number 1</span>}>
                                <span className='textStyles-small'>{selectedData?.mobileNumberOne ? selectedData?.mobileNumberOne : 'Not Set Yet'}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Mobile Number 2</span>}>
                                <span className='textStyles-small'>{selectedData?.mobileNumberTwo ? selectedData?.mobileNumberTwo : 'Not Set Yet'}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Status</span>}>
                                <span className='textStyles-small'>{selectedData?.status}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Roles</span>}>
                                {
                                    selectedData?.roles?.map((data, index) => {
                                        return (
                                            <Tag
                                                color="blue"
                                                style={{ marginBottom: '5px' }}
                                                key={data.id}>
                                                <span className='textStyles-small'>{data.name}</span>
                                            </Tag>
                                        )
                                    })
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Groups</span>}>
                                {
                                    selectedData?.groups?.map((data, index) => {
                                        return (
                                            <Tag
                                                color="green"
                                                style={{ marginBottom: '5px' }}
                                                key={data.id}>
                                                <span className='textStyles-small'>{data.name}</span>
                                            </Tag>
                                        )
                                    })
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span className='textStyles-small'>Regions</span>}>
                                {
                                    selectedData?.regions?.map((data, index) => {
                                        return (
                                            <Tag
                                                color="orange"
                                                style={{ marginBottom: '5px' }}
                                                key={data.id}>
                                                <span className='textStyles-small'>{data.name}</span>
                                            </Tag>
                                        )
                                    })
                                }
                            </Descriptions.Item>
                        </Descriptions>
                    ) : null
                }
            </Modal>
        </body>
    );
}

export default RoleModule;