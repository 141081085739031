import React, { Component } from "react";
import { connect } from "react-redux";
import { getSiteListByPage, filterSiteData } from "../../api/Repos/SiteRepo";
import { Link } from "react-router-dom";
// import Select from 'react-select'
import { Collapse } from "reactstrap";
import { DatePicker, Table, List, Skeleton, Select, Tag, Row, Col, Input, Button, message, Menu, Dropdown, Modal, Card } from "antd";
import { getDonationCategories, getData } from "../../actions/index";
import {
  getAllDepotList,
  getSitesLiteForSiteManage,
  getAllSiteCategories,
  removeSite,
  getStatistics
} from "../../api/index";
import '../master-data/style.css';
import axios from 'axios';
import { checkServiceType } from "../../actions/service-type";
import {GlobalOutlined, HomeOutlined, WifiOutlined, ReloadOutlined, SearchOutlined, EnvironmentOutlined, PlusOutlined,
  FilterOutlined, CloseCircleOutlined, EditOutlined, DownOutlined, ExclamationCircleOutlined, DeleteFilled, DeleteOutlined, 
  MinusCircleOutlined, EditFilled, EyeFilled } from '@ant-design/icons';

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
const { Option } = Select;
class SiteAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      editVisibilty: false,
      site_list: [],
      site_name: "",
      site_id: "",
      depotList: [],
      depot_list_new: [],
      isOpen: false,
      loading: true,
      depotId: "",
      setLogin: false,
      AssetManagementPermission: false,
      filterButton: true,
      setSearchValue: "",
      filteredTable: null,
      setValues: null,
      depot_list: [],
      sitwCategory: [],
      filterRegion: null,
      filterDepot: null,
      filterCategory: null,
      fullAllSite: [],
      staticalData: [],
      allSiteList: []
    };
  }

  componentDidMount() {
    this.props.getData();
    // this.getAllDepotList();
    // this.basicDetailsRequest();
    this.setState({
      depot_list: this.props.depots,
    });
    checkServiceType(38).then((data) =>
      this.setState(
        {
          AssetManagementPermission: data,
        },
        () => {
          console.log(this.state.AssetManagementPermission);
        }
      )
    );
    if (JSON.parse(localStorage.getItem("serviceTypeSet")) == null) {
      this.setState({
        setLogin: true,
      });
    }
    // this.getSitesLiteForSiteManage();
    // this.getAllSiteCategories();
    // this.getStaticDetails();
    this.fetchStatisticsFunction();
    this.fetchSiteList();
  }

  fetchStatisticsFunction = async () => {
    try {
      const response = await axios.get('http://34.28.76.89:8802/api/v1/statistics');
      console.log(response?.data?.results);
      this.setState({
        staticalData: response?.data?.results,
      })
    } catch (err) {
      //message.error('Problem with the request');
    } finally {
      console.log('Problem With the request');
    }
  }

  fetchSiteList = async () => {
    this.setState({
      loading: true
    });
    let fetchData = [];
    const pageSize = 100;
    let hasNextPage = true;
    let lastSeenId = 0;
    while (hasNextPage) {
      try {
        const response = await axios.post(
          'http://34.28.76.89:8802/api/v1/site-view',
          {
            siteIds: [],
            regionIds: [],
            depotIds: [],
            status: null,
            siteOwnerIds: [],
            siteTypeIds: [],
            towerCategoryIds: [],
            towerTypeIds: []
          },
          {
            params: {
              lastSeenId,
              pageSize  
            }
          }
        );
        if (response.data?.results?.data) {
          fetchData = [...fetchData, ...response.data.results.data];
        }
        lastSeenId = response?.data?.results?.lastSeenId;
        hasNextPage = response?.data?.results?.hasNextPage;
        if (!hasNextPage) break;
  
      } catch (err) {
        console.error('Error fetching data:', err); 
        break;
      }
    }
  
    this.setState({
      loading: false,
      allSiteList: fetchData
    })
  };
  
  
  

  getStaticDetails = async () => {
    const response = await getStatistics();
    console.log(response);
  }

  basicDetailsRequest = async () => {
    const res = await getStatistics();
    console.log(res);
  };

  getAllSiteCategories = async () => {
    var res = await getAllSiteCategories();
    if (res.success) {
      this.setState({
        sitwCategory: res.data,
      });
    }
  };
  
  showErrorMessage = (message) => {
    Modal.error({
      title: 'Error',
      content: <span className='textStyles-small'>{message}</span>,
      onOk() { },
    });
  };

  removeSitesFromList = async (siteID) => {
    const fromData = new FormData();
    fromData.append("siteId", siteID);
    const res = await removeSite(fromData);
    console.log(res);
    if (res.success) {
      message.success("Site deactivated successfully");
      localStorage.removeItem("siteInfo");
      this.fetchSiteList();
    } else {
      this.showErrorMessage(res.data.data);
    }
  }

  localStorageSaverFunction = (data) => {
    const numberOfDaysCacheAvailable = 1;
    const currentTime = new Date().getTime();
    const cacheExpiryTime = localStorage.getItem("cacheExpiryTime");
    if (!localStorage.getItem("siteInfo") || currentTime > cacheExpiryTime) {
        localStorage.setItem("siteInfo", JSON.stringify(data));
        localStorage.setItem("cachingTime", currentTime);
        const expiryTime = currentTime + numberOfDaysCacheAvailable * 24 * 60 * 60 * 1000; 
        localStorage.setItem("cacheExpiryTime", expiryTime);
    }
};

checkLocalStorageCache = () => {
  const currentTime = new Date().getTime();
  const cacheExpiryTime = localStorage.getItem("cacheExpiryTime");
  const siteInfo = JSON.parse(localStorage.getItem("siteInfo"));
  if (siteInfo && Object.keys(siteInfo).length > 0 && currentTime <= cacheExpiryTime) {
      return true;
  }
  return false;
};

  getSitesLiteForSiteManage = async () => {
    this.setState({
        loading: true,
        site_list: [],
    });

    // Check if cache is available
    if (this.checkLocalStorageCache()) {
        // Use cached data
        var res = {
            success: true,
            data: JSON.parse(localStorage.getItem("siteInfo"))
        };
    } else {
        // Fetch data and save to cache
        var res = await getSitesLiteForSiteManage();
        console.log("getSitesLiteForSiteManage");
        console.log(res);
        if (res.success) {
            this.localStorageSaverFunction(res.data);
        }
    }

    if (res.success) {
        if (this.state.setValues == null) {
            this.setState({
                site_list: res.data,
                fullAllSite: res.data,
                loading: false,
            });
        } else {
            this.setState(
                {
                    site_list: res.data,
                    fullAllSite: res.data,
                    loading: false,
                },
                () => {
                    const filtered = this.state.site_list.filter(
                        (user) =>
                            user?.site_name
                                ?.toLowerCase()
                                .includes(this.state.setValues.toLowerCase()) ||
                            user?.site_id
                                ?.toLowerCase()
                                .includes(this.state.setValues.toLowerCase())
                    );
                    this.setState({
                        filteredTable: filtered,
                    });
                }
            );
        }
    }
};


  getSetPageData = (serviceTypeIs, Pageid) => {
    //console.log("this.props");
    //console.log(serviceTypeIs.serviceType);
    if (
      serviceTypeIs.serviceType.filter(
        (checkuser) => checkuser?.id == Pageid
      )[0] == null
    ) {
      this.setState({
        editVisibilty: false,
      });
    } else {
      this.setState({
        editVisibilty: true,
      });
    }
  };
  getAllDepotList = async () => {
    var res = await getAllDepotList();
    this.setState({
      loadingDepot: true,
      depot_list_new: [],
    });
    console.log(res);
    if (res.success) {
      this.setState(
        {
          depot_list_new: res.data,
          loadingDepot: false,
        },
        () => {
          var _depotList = [];

          this.state.depot_list_new.map((depot) => {
            _depotList.push({ value: depot.id, label: depot.name });
          });

          this.setState({
            depotList: _depotList,
          });
        }
      );
    }
  };

  getAllSites = async () => {
    var res = await getSiteListByPage(this.state.pageNo);
    this.setState({
      loading: true,
      site_list: [],
      fullAllSite: [],
    });
    if (res.success) {
      this.setState({
        site_list: res.data,
        loading: false,
        fullAllSite: res.data,
      });
    }
  };

  previousBtnClick = () => {
    if (this.state.pageNo > 1) {
      this.setState({
        loading: true,
        site_list: [],
      });
      var aa = this.state.pageNo - 1;
      this.setState(
        {
          pageNo: aa,
        },
        () => {
          this.getAllSites();
        }
      );
    }
  };

  nextBtnClick = () => {
    this.setState({
      loading: true,
      site_list: [],
    });
    var aa = this.state.pageNo + 1;
    this.setState(
      {
        pageNo: aa,
      },
      () => {
        this.getAllSites();
      }
    );
  };

  searchFormItemChange = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    //console.log(obj)
    this.setState(obj, () => {
      if (
        this.state.site_id != "" ||
        this.state.site_name != "" ||
        this.state.depotId != ""
      ) {
        this.setState({
          filterButton: true,
        });
      } else {
        this.setState({
          filterButton: false,
        });
      }
    });
  };

  searchDepottemChange = (event) => {
    this.setState(
      {
        depotId: event.value,
      },
      () => {
        if (
          this.state.site_id != "" ||
          this.state.site_name != "" ||
          this.state.depotId != ""
        ) {
          this.setState({
            filterButton: true,
          });
        } else {
          this.setState({
            filterButton: false,
          });
        }
      }
    );
  };

  viewFilterClick = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  filterBtnClick = async () => {
    this.setState({
      loading: true,
    });
    console.log(this.state.filterRegion);
    console.log(this.state.filterDepot);
    console.log(this.state.filterCategory);
    console.log(this.state.fullAllSite);
    let filtered = [];
    //const filtered = this.state.site_list.filter(site=>( site.patent_region_id==this.state.filterRegion && site.depot_id==this.state.filterDepot ));
    if (this.state.filterCategory != null) {
      filtered = this.state.fullAllSite
        .filter((site) =>
          this.state.filterDepot != null
            ? site.depot_id == this.state.filterDepot
            : site && this.state.filterRegion != null
            ? site.patent_region_id == this.state.filterRegion
            : site
        )
        .filter(
          (category) => category.site_category_id == this.state.filterCategory
        );
    } else {
      filtered = this.state.fullAllSite.filter((site) =>
        this.state.filterDepot != null
          ? site.depot_id == this.state.filterDepot
          : site && this.state.filterRegion != null
          ? site.patent_region_id == this.state.filterRegion
          : site
      );
    }
    console.log(filtered);
    this.setState({
      site_list: filtered,
      loading: false,
    });
  };

  clearBtnClick = () => {
    window.location.reload();
  };
  search = (value) => {
    this.setState({
      setValues: value,
    });

    const filtered = this.state.site_list.filter(
      (user) =>
        user?.site_name?.toLowerCase().includes(value.toLowerCase()) ||
        user?.site_id?.toLowerCase().includes(value.toLowerCase())
    );

    this.setState({
      filteredTable: filtered,
    });
  };
  onChangeRegion = (value) => {
    console.log(value);

    var dd = value;
    //console.log(dd)
    var subRegion = [];
    var _depotList1 = [];
    var _allSite = [];
    var subRegionIdArray = [];

    this.props.regions
      .filter((allret) => allret?.parentRegion?.id == dd)
      .map((subR) => {
        subRegionIdArray.push(subR.id);
      });

    this.setState(
      {
        selectRegion: subRegionIdArray,
        filterDepot: null,
        filterRegion: value == "undefined" ? null : value,
      },
      () => {
        if (
          this.state.filterRegion != null ||
          this.state.filterDepot != null ||
          this.state.filterCategory != null
        ) {
          this.setState({
            filterButton: true,
          });
        } else {
          this.setState({
            filterButton: true,
          });
        }
      }
    );

    //console.log(this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)));
    subRegion = this.props.regions.filter(
      (allret) => allret?.parentRegion?.id == dd
    );
    //console.log(this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)));
    this.props.depots
      .filter((alldate) =>
        subRegion.map((n) => n.id).includes(alldate.region?.id)
      )
      .map((depot) => {
        _depotList1.push({ id: depot?.id, name: depot?.name });
      });
    console.log("selectDepot");
    console.log(this.state.selectDepot);

    this.setState({
      depot_list: _depotList1,
    });
  };
  onChangeCategory = (value) => {
    this.setState(
      {
        // DepotName: dataa[value - 1]?.name,
        filterCategory: value == "undefined" ? null : value,
        selectType: 2,
        selectId: value,
        selectDepot: value,
      },
      () => {
        if (
          this.state.filterRegion != null ||
          this.state.filterDepot != null ||
          this.state.filterCategory != null
        ) {
          this.setState({
            filterButton: true,
          });
        } else {
          this.setState({
            filterButton: true,
          });
        }
      }
    );
  };
  onChangeDepot = (value) => {
    //console.log("onChangeDepot");
    console.log(value);

    this.setState(
      {
        // DepotName: dataa[value - 1]?.name,
        filterDepot: value == "undefined" ? null : value,
        selectType: 2,
        selectId: value,
        selectDepot: value,
      },
      () => {
        if (
          this.state.filterRegion != null ||
          this.state.filterDepot != null ||
          this.state.filterCategory != null
        ) {
          this.setState({
            filterButton: true,
          });
        } else {
          this.setState({
            filterButton: true,
          });
        }
      }
    );
  };

  render() {
    const columns123 = [
      {
        title: <span className='textStyles-small' style={{fontSize: 12}}>Site ID</span>,
        render: (text, record, index) => (
          <span className='textStyles-small' >
              <Link className='textStyles-small' to={"/site/new/" + text.id}>{text?.siteId}</Link>
          </span>
        ),
      },
      {
        title: <span className='textStyles-small' style={{fontSize: 12}}>Site Name</span>,
        key: "siteName",
        render: (item) => (
          <span className='textStyles-small' >
              <Link className='textStyles-small' 
              style={{fontSize: 12}}
              to={"/site/new/" + item.id}>{item?.siteName}</Link>
          </span>
        ),
      },
      {
        title: <span className='textStyles-small' style={{fontSize: 12}}>Region</span>,
        //   dataIndex: "accessPermissionCode",
        key: "accessPermissionCode",
        render: (item) => <span>{item.region}</span>,
      },
      {
        title: <span className='textStyles-small' style={{fontSize: 12}}>Depot</span>,
        //   dataIndex: "accessPermissionCode",
        key: "accessPermissionCode",
        render: (item) => <span className='textStyles-small' >{item?.depot}</span>,
      },

      {
        title: <span className='textStyles-small' style={{fontSize: 12}}>Status</span>,
        dataIndex: "status",
        key: "operationTypeName",
        render: (item) => (
          <>
            <Tag color="#03d87f" key={item} style={{borderRadius: 5}}>
              <span className='textStyles-small' style={{fontSize: 10}}>{item}</span>
            </Tag>
          </>
        ),
      },
      {
        title: <span className='textStyles-small' style={{fontSize: 12}}>Site Category</span>,
        align: "center",
        key: "siteCategory",
        render: (item) => (
          <span class="badge badge-outline-primary badge-pill textStyles-small">
            {item?.siteCategory}
          </span>
        ),
      },
      {
        title: <span className='textStyles-small' style={{fontSize: 12}}>Depot Officer</span>,
        key: "DepotOfficer ",
        render: (item) => <span className='textStyles-small'>{item?.depotOfficer}</span>,
      },
      {
        title: <span className='textStyles-small' style={{fontSize: 12}}>Contact Number</span>,
        key: "contact_no",
        align: "right",
        width: "9%",
        render: (item) => (
          <span className='textStyles-small'>
            {item?.contactNo}
          </span>
        ),
      },
      this.state.AssetManagementPermission ? {
        title: <span className='textStyles-small' style={{ fontSize: 12 }}>Action</span>,
        key: "ContactNo",
        align: "center",
        hidden: true,
        render: (item) => {
          const menu = (
            <Menu>
              <Menu.Item key="list-loadmore-edit-1" icon={<EditOutlined style={{ color: 'green' }} />}>
                <Link to={"/sites/edit/" + item.id}>
                  <span className='textStyles-small'>Edit Site</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="list-loadmore-edit-3" icon={<MinusCircleOutlined style={{ color: 'red' }} />}>
                <span className='textStyles-small' onClick={() => showUpdateConfirmationModal(item.id)}>Deactivate Site</span>
              </Menu.Item>
            </Menu>
          );
      
          return (
            <Dropdown overlay={menu}>
              <Button
                type="primary"
                size="small"
                icon={<DownOutlined />}
                shape="circle" />
            </Dropdown>
          );
        },
      }
      
        : {},
    ];

    const showUpdateConfirmationModal = (siteID) => {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: <span className='textStyles-small'>Do you want to deactivate this site?</span>,
        okText: 'Yes',
        cancelText: 'No',
        onOk: async () => this.removeSitesFromList(siteID),
      });
    };

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <div className="row">
                  <div class="col">
                    <h4 class="page-title">Sites</h4>
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="javascript:void(0);">Admin</a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="javascript:void(0);">Manage Sites </a>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Row>
            <Col span={8}>
              <div style={{ backgroundColor: 'white', borderRadius: '15px', margin: 10, padding: 20 }}>
                <Row>
                  <Col span={12}>
                    <span className="textStyles-small" style={{ fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                      Region Count
                    </span> <br />
                    <span className="textStyles-small" style={{ fontSize: 20, color: 'black', fontWeight: 'bold' }}>
                      {
                        this.state.staticalData.regionCount
                      }
                    </span>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',  // Aligns to the right
                      alignItems: 'center'         // Centers vertically
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#BA183A',
                        borderColor: '#BA183A',
                        borderRadius: 12,
                        padding: 10,
                        width: '40px',
                        height: '40px',
                      }}
                    >
                      <GlobalOutlined style={{ color: 'white', fontSize: 20 }} />
                    </div>
                  </Col>


                </Row>
              </div>
            </Col>

            <Col span={8}>
              <div style={{ backgroundColor: 'white', borderRadius: '15px', margin: 10, padding: 20 }}>
                <Row>
                  <Col span={12}>
                    <span className="textStyles-small" style={{ fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                      Depot Count
                    </span> <br />
                    <span className="textStyles-small" style={{ fontSize: 20, color: 'black', fontWeight: 'bold' }}>
                    {
                        this.state.staticalData.depotCount
                      }
                    </span>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',  // Aligns to the right
                      alignItems: 'center'         // Centers vertically
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#BA183A',
                        borderColor: '#BA183A',
                        borderRadius: 12,
                        padding: 10,
                        width: '40px',
                        height: '40px',
                      }}
                    >
                      <HomeOutlined style={{ color: 'white', fontSize: 20 }} />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col span={8}>
              <div style={{ backgroundColor: 'white', borderRadius: '15px', margin: 10, padding: 20 }}>
                <Row>
                  <Col span={12}>
                    <span className="textStyles-small" style={{ fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                      Site Count
                    </span> <br />
                    <span className="textStyles-small" style={{ fontSize: 20, color: 'black', fontWeight: 'bold' }}>
                    {
                        this.state.staticalData.siteCount
                      }
                    </span>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',  // Aligns to the right
                      alignItems: 'center'         // Centers vertically
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#BA183A',
                        borderColor: '#BA183A',
                        borderRadius: 12,
                        padding: 10,
                        width: '40px',
                        height: '40px',
                      }}
                    >
                      <WifiOutlined style={{ color: 'white', fontSize: 20 }} />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

          </Row>

          <div style={{ marginTop: 10, backgroundColor: "white", padding: 15, borderRadius: 15 }}>
            <div className="row">
              <Row>
                <Col span={12}>
                  <Row>
                    <span className="textStyles-small" style={{ fontSize: 18, fontWeight: 'bolder' }}>
                      Site List
                    </span>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row align="middle" justify="end">
                    {/* BUTTON FOR CREATE SITE */}
                    {this.state.AssetManagementPermission && (
                      <Link to="/sites/create">
                        <Button
                          style={{ marginLeft: 10, backgroundColor: '#BA183A', borderColor: '#BA183A', borderRadius: 5 }}
                          icon={<PlusOutlined style={{ color: 'white' }} />}
                          onClick={() => this.props.history.push("/sites/create")}
                        ><span className='textStyles-small' style={{ color: 'white' }}>Create Site</span></Button>
                      </Link>
                    )}
                  </Row>
                </Col>
              </Row>
              
              <Table
                size="small"
                className="table-striped-rows"
                style={{ marginTop: 10 }}
                columns={columns123}
                loading={this.state.loading}
                dataSource={
                  this.state.filteredTable == null
                    ? [...this.state.allSiteList].sort((a, b) => b.id - a.id)
                    : [...this.state.allSiteList].sort((a, b) => b.id - a.id)
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  //console.log("state", state);
  return {
    organization_count: state.organization.length,
    regions: state.regions,
    depots: state.depots,
    sites_count: state.sites.length,
    vendor_count: state.vendor.length,
    roles_count: state.roles.length,
  };
}

export default connect(mapStateToProps, { getDonationCategories, getData })(
  SiteAll
);

// export default connect(
// 	mapStateToProps,
// 	{}
// )(Site);
