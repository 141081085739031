import React, { Component, Fragment } from 'react'
import { DatePicker, Input, InputNumber, Tag, Space, Button, Row, Col, Drawer, Select, Spin, Switch, message } from "antd";
import { updateTrcslDetails } from "../../api/index";
import moment from 'moment';



class Trcsl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            tabaleLoading: false,
            switchStatus: true,
            objectSet:
            {
                "tRCSL5YearReportSubmittedDate": null
            }
        };
    }

    componentDidMount() {
        console.log('tytqwe')
        console.log(this.props.details)
        this.setState({
            objectSet:
            {
                "tRCSL5YearReportSubmittedDate": this.props.details.access_technologies_data?.trcsl5YearReportSubmittedDate 
            }
        });
    }

    onChangeInstalledDate = (date, dateString) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                tRCSL5YearReportSubmittedDate: dateString
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    };

    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }
    close = async () => {
        this.props.parentMethod();
        this.setState({
            objectSet:
            {
                "tRCSL5YearReportSubmittedDate": this.props.details.access_technologies_data?.trcsl5YearReportSubmittedDate 


            }
        });
        this.setState({
            switchStatus: true
        });
    }
    dataSetToUpdate = async () => {
        console.log(this.props.detailsSite)
        let formData = new FormData(); //formdata object
        let setobjectToUpate = {"trcsl5YearReportSubmittedDate":this.state.objectSet.tRCSL5YearReportSubmittedDate}
        formData.append("object", JSON.stringify(setobjectToUpate)); //append the values with key, value pair
        formData.append("id", this.props.details?.access_technologies_data?.id);
        var res = await updateTrcslDetails(formData);
        if(res.success){
            message.success('Successfully Update!')
            this.props.parentMethod();
            this.setState({
              switchStatus: true
          })
         }else{
          message.warning(' Something went wrong!')
         }





    }
    render() {
        const { details } = this.props.details
       
        return (
            <Fragment>
                {this.props.details.access_technologies_data != null ? (
                    // <div className='row'>
                    //     <div className='col-md-5'>
                    //         <div class="card">
                    //             <div class="card-header">
                    //             <div class="card-header">
                    //                 <div class="d-flex justify-content-between align-items-center">
                    //                     <h4 class="card-title">Information</h4>
                    //                     {this.state.switchStatus
                    //                         ? <button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.dataUpdate()}> <i class="mdi mdi-pencil-outline"></i></button>
                    //                         : <div class="d-flex justify-content-end align-items-center">
                    //                             <button type="button" class="btn btn-soft-secondary " onClick={() => this.close()} style={{ marginRight: "10px" }}> Cancel </button>
                    //                             <button type="button" class="btn btn-soft-primary " onClick={() => this.dataSetToUpdate()}> Submit to Update </button>
                    //                         </div>
                    //                     }
                    //                 </div>

                    //             </div>
                    //             </div>
                    //             <div class="card-body">
                    //                 <div class="row">


                    //                 </div>
                    //             </div>
                    //         </div>
                    //     </div>
                    // </div>

                    <div style={{ width: "100%", backgroundColor: "white", padding: "20px", borderRadius: "5px" }}>
                        <div className="d-flex justify-content-end align-items-center">
                            {this.state.switchStatus ? (
                                <button
                                    type="button"
                                    className="btn btn-soft-primary btn-icon-square-sm"
                                    onClick={() => this.dataUpdate()}
                                    style={{ borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                                >
                                    <i className="mdi mdi-pencil-outline" style={{color: 'white'}}></i>
                                </button>
                            ) : (
                                <div className="d-flex justify-content-end align-items-center">
                                    <button
                                        type="button"
                                        className="btn btn-soft-secondary"
                                        onClick={() => this.close()}
                                        style={{ marginRight: "10px" }}
                                    >
                                        <span className="textStyles-small">Cancel</span>
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-soft-primary"
                                        onClick={() => this.dataSetToUpdate()}
                                    >
                                        <span className="textStyles-small">Submit to Update</span>
                                    </button>
                                </div>
                            )}
                        </div>
                        <Row>
                            <Col span={12}>
                                <ul class="list-group">
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <div>
                                            <span className='textStyles-small'>5 Year Report Submitted Date</span>
                                        </div>
                                        {this.state.switchStatus ? (<> {this.props.details.access_technologies_data?.trcsl5YearReportSubmittedDate != null ? (
                                            <span class="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data?.trcsl5YearReportSubmittedDate}</span>) : (
                                            <span class="badge badge-outline-warning textStyles-small">Currently Not Set</span>)}</>) : (<>
                                                <DatePicker className='borderedSelect' style={{ width: "200px" }} defaultValue={this.state.objectSet?.tRCSL5YearReportSubmittedDate != null ?
                                                    (moment(this.state.objectSet?.tRCSL5YearReportSubmittedDate)) : ("")} onChange={this.onChangeInstalledDate} /> </>)}
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                ) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h4 style={{ color: "#c5c5c5" }}>No Dat Found Access Technologies</h4></div>)}

            </Fragment>
        )
    }
}
export default Trcsl;
