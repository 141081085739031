import { Col, InputNumber, message, Row } from 'antd';
import React, { Component, Fragment } from 'react'
import {updateDcLoad} from "../../api/index";

class DCLoad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            switchStatus: true,
            objectSet: {
                "dclDCLoad":0,
                "dclPeakDCLoad":0,
                "dclNominalOperatingVoltage":0
              }
        }
        this.handledclDCLoad = this.handledclDCLoad.bind(this);
        this.handledclPeakDCLoad = this.handledclPeakDCLoad.bind(this);
        this.handledclNominalOperatingVoltage = this.handledclNominalOperatingVoltage.bind(this);
    }
    handledclDCLoad(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                dclDCLoad: e
            }

        })
    }
    handledclPeakDCLoad(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                dclPeakDCLoad: e
            }

        })
    }
    handledclNominalOperatingVoltage(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                dclNominalOperatingVoltage: e
            }

        })
    }
    componentDidMount() {
        this.setState({
            objectSet: {
                "dclDCLoad": this.props.details.access_technologies_data?.dclDCLoad,
                "dclPeakDCLoad": this.props.details.access_technologies_data?.dclPeakDCLoad,
                "dclNominalOperatingVoltage": this.props.details.access_technologies_data?.dclNominalOperatingVoltage

            }

        }, () => {
            console.log("opopop")
            console.log(this.state.objectSet)
        })
    }
    dataSetToUpdate = async () => {

        var res = await updateDcLoad(this.props.details2.site_details.id, this.state.objectSet);

        if(res.success){
           message.success('Successfully Update!')
           this.props.parentMethod();
           this.setState({
             switchStatus: true
         })
        }else{
         message.warning(' Something went wrong!')
        }

    }
    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }
    close = async () =>{
        this.props.parentMethod();
        this.setState({
            objectSet: {
                "dclDCLoad": this.props.details.access_technologies_data?.dclDCLoad,
                "dclPeakDCLoad": this.props.details.access_technologies_data?.dclPeakDCLoad,
                "dclNominalOperatingVoltage": this.props.details.access_technologies_data?.dclNominalOperatingVoltage
            }
        });
        this.setState({
            switchStatus: true
        });
    }
    render() {
        const { details } = this.props.details
        return (
            <Fragment>
                {this.props.details.access_technologies_data !=null ? (
                    <div style={{ width: "100%", backgroundColor: "white", padding: "20px", borderRadius: "5px" }}>
                        {this.state.switchStatus ? (
                            <div className="d-flex justify-content-end align-items-center">
                                <button
                                    type="button"
                                    className="btn btn-soft-primary btn-icon-square-sm"
                                    onClick={() => this.dataUpdate()}
                                    style={{ borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                                >
                                    <i className="mdi mdi-pencil-outline" style={{color: 'white'}}></i>
                                </button>
                            </div>
                        ) : (
                            <div className="d-flex justify-content-end align-items-center">
                                <button
                                    type="button"
                                    className="btn btn-soft-secondary"
                                    onClick={() => this.close()}
                                    style={{ marginRight: "10px" }}
                                >
                                    <span className='textStyles-small'>Cancel</span>
                                </button>
                                {this.state.objectSet.dclDCLoad == null ||
                                    this.state.objectSet.dclDCLoad === "" ||
                                    this.state.objectSet.dclNominalOperatingVoltage == null ||
                                    this.state.objectSet.dclNominalOperatingVoltage === "" ? (
                                    <button type="button" className="btn btn-soft-primary" disabled>
                                        <span className='textStyles-small'>Submit to Update</span>
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn btn-soft-primary"
                                        onClick={() => this.dataSetToUpdate()}
                                    >
                                        <span className='textStyles-small'>Submit to Update</span>
                                    </button>
                                )}
                            </div>
                        )}
                        <ul class="list-group" style={{ marginTop: "10px" }}>
                            <Row>
                                <Col span={12}>
                                <div style={{ width: '99%' }}>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <div>
                                            <span className='textStyles-small'>DC Load (KW)</span>
                                            {this.state.switchStatus == false ? <pre style={{ color: 'red' }}> *Required</pre> : ""}
                                        </div>
                                        {this.state.switchStatus ? this.props.details.access_technologies_data?.dclDCLoad ? (
                                            <span class="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data.dclDCLoad}</span>) : (
                                            <span class="badge badge-outline-warning textStyles-small">{this.props.details.access_technologies_data.dclDCLoad}</span>) :
                                            <>
                                                <InputNumber className='borderedSelect' style={{ width: '20%' }} value={this.state.objectSet?.dclDCLoad} id="mpcNoOfPhases" onChange={this.handledclDCLoad} />
                                            </>
                                        }
                                    </li>

                                    <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                                        <div>
                                            <span className='textStyles-small'>Peak DC Load (KW)</span>
                                        </div>
                                        {this.state.switchStatus ? this.props.details.access_technologies_data?.dclPeakDCLoad ? (
                                            <span class="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data.dclPeakDCLoad}</span>) : (
                                            <span class="badge badge-outline-warning textStyles-small">{this.props.details.access_technologies_data.dclPeakDCLoad}</span>) :
                                            <InputNumber className='borderedSelect' style={{ width: '20%' }} value={this.state.objectSet?.dclPeakDCLoad} id="mpcNoOfPhases" onChange={this.handledclPeakDCLoad} />}
                                    </li>
                                </div>
                                </Col>

                                <Col span={12}>
                                <div style={{ width: '99%' }}>
                                <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                                        <div>
                                            <span className='textStyles-small'>Nominal Operating Voltage (V)</span>
                                            {this.state.switchStatus == false ? <pre style={{ color: 'red' }}> *Required</pre> : ""}
                                        </div>
                                        {this.state.switchStatus ? this.props.details.access_technologies_data?.dclNominalOperatingVoltage ? (
                                            <span class="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data.dclNominalOperatingVoltage}</span>) : (
                                            <span class="badge badge-outline-warning textStyles-small">{this.props.details.access_technologies_data.dclNominalOperatingVoltage}</span>) :
                                            <>
                                                <InputNumber className='borderedSelect' style={{ width: '20%' }} value={this.state.objectSet?.dclNominalOperatingVoltage} id="mpcNoOfPhases" onChange={this.handledclNominalOperatingVoltage} />
                                            </>
                                        }
                                    </li>
                                </div>
                                </Col>
                            </Row>
                        </ul>
                    </div>
                ) : ( <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h4 style={{ color: "#c5c5c5" }}>No Dat Found DC Load</h4></div>)}

            </Fragment>
        )
    }
}
export default DCLoad;
