import { InputNumber, message, Row, Col } from 'antd';
import React, { Component, Fragment } from 'react'
import {updatePowerCapacity} from "../../api/index";
import { Background } from 'react-flow-renderer';

class PowerCapacityKw extends Component {
    constructor(props) {
        super(props);
        this.state = {
            switchStatus: true,
            objectSet: {
                "pcTotalCapacityAvailable":0,
                "pcUsedCapacityWithBatteryCharging":0,
                "pcRemainingCapacity":0
              }
        }
        this.handlepcTotalCapacityAvailable = this.handlepcTotalCapacityAvailable.bind(this);
        this.handlepcUsedCapacityWithBatteryCharging = this.handlepcUsedCapacityWithBatteryCharging.bind(this);
        this.handlepcRemainingCapacity = this.handlepcRemainingCapacity.bind(this);
    }
    handlepcTotalCapacityAvailable(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                pcTotalCapacityAvailable: e
            }

        })
    }
    handlepcUsedCapacityWithBatteryCharging(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                pcUsedCapacityWithBatteryCharging: e
            }

        })
    }
    handlepcRemainingCapacity(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                pcRemainingCapacity: e
            }

        })
    }
    componentDidMount() {
        console.log(this.props.details)
        console.log('powerCon')
        this.setState({
            objectSet: {
                "pcTotalCapacityAvailable": this.props.details.access_technologies_data?.pcTotalCapacityAvailable,
                "pcUsedCapacityWithBatteryCharging": this.props.details.access_technologies_data?.pcUsedCapacityWithBatteryCharging,
                "pcRemainingCapacity": this.props.details.access_technologies_data?.pcRemainingCapacity

            }

        }, () => {
            console.log("opopop")
            console.log(this.state.objectSet)
        })
    }
    dataSetToUpdate = async () => {

        var res = await updatePowerCapacity(this.props.details2.site_details.id, this.state.objectSet);

        if(res.success){
           message.success('Successfully Update!')
           this.props.parentMethod();
           this.setState({
             switchStatus: true
         })
        }else{
         message.warning(' Something went wrong!')
        }

    }
    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }
    close = async () =>{
        this.props.parentMethod();
        this.setState({
            objectSet: {
                "pcTotalCapacityAvailable": this.props.details.access_technologies_data?.pcTotalCapacityAvailable,
                "pcUsedCapacityWithBatteryCharging": this.props.details.access_technologies_data?.pcUsedCapacityWithBatteryCharging,
                "pcRemainingCapacity": this.props.details.access_technologies_data?.pcRemainingCapacity
            }
        });
        this.setState({
            switchStatus: true
        });
    }
    render() {
        const { details } = this.props.details
        return (
            <Fragment>
                {this.props.details.access_technologies_data !=null ? (
                    // <div className='row'>
                    //     <div className='col-md-6'>
                    //         <div class="card">
                    //             <div class="card-header">
                    //                 <div class="d-flex justify-content-between align-items-center">
                    //                     <h4 class="card-title">Information</h4>
                    //                     {/* {this.state.switchStatus
                    //                         ?   <button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.dataUpdate()}> <i class="mdi mdi-pencil-outline"></i></button>
                    //                         :   <div class="d-flex justify-content-end align-items-center">
                    //                                 <button type="button" class="btn btn-soft-secondary " onClick={() => this.close()} style={{marginRight:"10px"}}> Cancel </button>    
                    //                                 <button type="button" class="btn btn-soft-primary " onClick={() => this.dataSetToUpdate()}> Submit to Update </button>
                    //                             </div>
                    //                     } */}
                    //                 </div>
                    //             </div>
                    //             <div class="card-body">
                    //                 <div class="row">                                     
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     </div>
                    // </div>
                    <div style={{ width: "100%", backgroundColor: "white", padding: "20px", borderRadius: "5px" }}>
                        <ul class="list-group">
                            <Row>
                                <Col span={12}>
                                    <div style={{ width: '99%' }}>
                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                            <div>
                                                <span className='textStyles-small'>Total Capacity Available (kW)</span>
                                            </div>
                                            {this.state.switchStatus ? this.props.details.access_technologies_data?.pcTotalCapacityAvailable ?
                                                (<span class="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data.pcTotalCapacityAvailable.toFixed(2)}</span>) : (
                                                    <span class="badge badge-outline-warning textStyles-small">{this.props.details.access_technologies_data.pcTotalCapacityAvailable.toFixed(2)}</span>) :
                                                <InputNumber style={{ width: '20%' }} value={this.state.objectSet?.pcTotalCapacityAvailable} id="mpcNoOfPhases" onChange={this.handlepcTotalCapacityAvailable} />}

                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa'}}>
                                            <div>
                                                <span className='textStyles-small'>Used Capacity with Battery Charging (10%)</span>
                                            </div>
                                            {this.state.switchStatus ? this.props.details.access_technologies_data?.pcUsedCapacityWithBatteryCharging ? (
                                                <span class="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data.pcUsedCapacityWithBatteryCharging.toFixed(2)}</span>) : (
                                                <span class="badge badge-outline-warning textStyles-small">{this.props.details.access_technologies_data.pcUsedCapacityWithBatteryCharging.toFixed(2)}</span>) :
                                                <span class="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data.pcUsedCapacityWithBatteryCharging.toFixed(2)}</span>}

                                        </li>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div style={{ width: '99%' }}>
                                        <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa'}}>
                                            <div>
                                                <span className='textStyles-small'>Remaining Capacity (kW)</span>
                                            </div>
                                            {this.state.switchStatus ? this.props.details.access_technologies_data?.pcRemainingCapacity ? (
                                                <span class="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data.pcRemainingCapacity.toFixed(2)}</span>) : (
                                                <span class="badge badge-outline-warning textStyles-small">{this.props.details.access_technologies_data.pcRemainingCapacity.toFixed(2)}</span>) :
                                                <span class="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data.pcRemainingCapacity.toFixed(2)}</span>}
                                        </li>
                                    </div>
                                </Col>
                            </Row>
                        </ul>
                    </div>
                ) : ( <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h4 style={{ color: "#c5c5c5" }}>No Dat Found Power Capacity</h4></div>)}

            </Fragment>
        )
    }
}
export default PowerCapacityKw;
