import React, { Component, Fragment } from "react";
import {
  Table,
  Input,
  Popconfirm,
  Tag,
  Space,
  Button,
  Row,
  Col,
  Drawer,
  Select,
  Spin,
  Switch,
  message,
  Modal,

} from "antd";
import { updateAcTechnologies, getAllDropdowns } from "../../api/index";
const { Option } = Select;

class AccessTechnologies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switchStatus: true,
      objectSet: {
        atGSM: true,
        atDCS: false,
        at3G: true,
        at4GFDD900: false,
        at4GFDD1800: true,
        at4GFDD2100: false,
        at4GTDD2300: true,
        at5G: false,
        atCAMBIUM: true,
        atWIBASS: false,
        atGPON: true,
        atCombinedTechnologies: false,
        atAccessTechnologyVendor: null,
      },
      dropDownSet: {
        atGSM: [],
        atDCS: [],
        at3G: [],
        at4GFDD900: [],
        at4GFDD1800: [],
        at4GFDD2100: [],
        at4GTDD2300: [],
        at5G: [],
        atCAMBIUM: [],
        atWIBASS: [],
        atGPON: [],
        atCombinedTechnologies: [],
      },
      dropdownOnchangeDataSet: {
        atGSM: null,
        atDCS: null,
        at3G: null,
        at4GFDD900: null,
        at4GFDD1800: null,
        at4GFDD2100: null,
        at4GTDD2300: null,
        at5G: null,
        atCAMBIUM: null,
        atWIBASS: null,
        atGPON: null,
        atCombinedTechnologies: null,
        atCombinedTechnologies: null,
      },
    };
  }
  // access technlogies data binding

  componentDidMount() {
    console.log("AcTec");
    console.log(this.props.details2.site_details.id);
    this.getAccessTechnologiesDropDownData();
    this.setState(
      {
        objectSet: {
          atGSM: this.props.details.access_technologies_data?.atGSM,
          atDCS: this.props.details.access_technologies_data?.atDCS,
          at3G: this.props.details.access_technologies_data?.at3G,
          at4GFDD900: this.props.details.access_technologies_data?.at4GFDD900,
          at4GFDD1800: this.props.details.access_technologies_data?.at4GFDD1800,
          at4GFDD2100: this.props.details.access_technologies_data?.at4GFDD2100,
          at4GTDD2300: this.props.details.access_technologies_data?.at4GTDD2300,
          at5G: this.props.details.access_technologies_data?.at5G,
          atCAMBIUM: this.props.details.access_technologies_data?.atCAMBIUM,
          atWIBASS: this.props.details.access_technologies_data?.atWIBASS,
          atGPON: this.props.details.access_technologies_data?.atGPON,
          atCombinedTechnologies: this.props.details.access_technologies_data?.atCombinedTechnologies,
          atAccessTechnologyVendor: this.props.details.access_technologies_data?.atAccessTechnologyVendor,
        },
        dropdownOnchangeDataSet: {
          atGSM: this.props.details.access_technologies_data?.atGSM,
          atDCS:
            this.props.details.access_technologies_data?.atDCS,
          at3G:
            this.props.details.access_technologies_data?.at3G,
          at4GFDD900:
            this.props.details.access_technologies_data?.at4GFDD900,
          at4GFDD1800:
            this.props.details.access_technologies_data?.at4GFDD1800,
          at4GFDD2100:
            this.props.details.access_technologies_data?.at4GFDD2100,
          at4GTDD2300:
            this.props.details.access_technologies_data?.at4GTDD2300,
          at5G:
            this.props.details.access_technologies_data?.at5G,
          atCAMBIUM:
            this.props.details.access_technologies_data?.atCAMBIUM,
          atWIBASS:
            this.props.details.access_technologies_data?.atWIBASS,
          atGPON:
            this.props.details.access_technologies_data?.atGPON,
          atCombinedTechnologies:
            this.props.details.access_technologies_data
              ?.atCombinedTechnologies,
          atCombinedTechnologies: this.props.details.access_technologies_data?.atCombinedTechnologies,
        },
      },
      () => {
        console.log("AccessTechnologies");
        console.log(Object.entries(this.state.objectSet));
      }
    );
  }

  getAccessTechnologiesDropDownData = async () => {
    var res = await getAllDropdowns();
    if (res.success) {
      console.log(JSON.stringify(res.data["Access Technologies"]["GSM"]));
      this.setState({
        dropDownSet: {
          atGSM: res.data["Access Technologies"]["GSM"],
          atDCS: res.data["Access Technologies"]["DCS"],
          at3G: res.data["Access Technologies"]["3G"],
          at4GFDD900: res.data["Access Technologies"]["5G"],
          at4GFDD1800: res.data["Access Technologies"]["5G"],
          at4GFDD2100: res.data["Access Technologies"]["5G"],
          at4GTDD2300: res.data["Access Technologies"]["5G"],
          at5G: res.data["Access Technologies"]["5G"],
          atCAMBIUM: res.data["Access Technologies"]["CAMBIUM"],
          atWIBASS: res.data["Access Technologies"]["WIBASS"],
          atGPON: res.data["Access Technologies"]["GPON"],
          atCombinedTechnologies: ["Yes", "No"],
        },
      });
    } else {
      message.warning("Something went wrong!");
    }
  };

  showUpdateConfirm = () => {
    const { confirm } = Modal;
    confirm({
      title:
        "Are You Want To Update These Data, Related To Access Technologies?",
      onOk: async () => {
        this.dataSetToUpdate();
      },
      onCancel() { },
    });
  };

  dataSetToUpdate = async () => {
    console.log(
      "AccessTechnologiesDropDownData--------------------------------------------------"
    );
    console.log(this.state.dropdownOnchangeDataSet);
    console.log(this.state.objectSet);
    var res = await updateAcTechnologies(
      this.props.details2.site_details.id,
      this.state.dropdownOnchangeDataSet
    );
    if (res.success) {
      message.success("Successfully Update!");
      this.props.parentMethod();
      this.setState({
        switchStatus: true,
      });
    } else {
      message.warning(" Something went wrong!");
    }
  };

  switchTo(id) {
    this.state.objectSet[id] = !this.state.objectSet[id];
  }
  dataUpdate() {
    console.log("ssss");
    this.setState({
      switchStatus: false,
    });
  }
  close = async () => {
    this.props.parentMethod();
    this.setState({
      objectSet: {
        atGSM: this.props.details.access_technologies_data?.atGSM,
        atDCS: this.props.details.access_technologies_data?.atDCS,
        at3G: this.props.details.access_technologies_data?.at3G,
        at4GFDD900: this.props.details.access_technologies_data?.at4GFDD900,
        at4GFDD1800: this.props.details.access_technologies_data?.at4GFDD1800,
        at4GFDD2100: this.props.details.access_technologies_data?.at4GFDD2100,
        at4GTDD2300: this.props.details.access_technologies_data?.at4GTDD2300,
        at5G: this.props.details.access_technologies_data?.at5G,
        atCAMBIUM: this.props.details.access_technologies_data?.atCAMBIUM,
        atWIBASS: this.props.details.access_technologies_data?.atWIBASS,
        atGPON: this.props.details.access_technologies_data?.atGPON,
        atCombinedTechnologies:
          this.props.details.access_technologies_data?.atCombinedTechnologies,
        atAccessTechnologyVendor: this.props.details.access_technologies_data?.atAccessTechnologyVendor,
      },
    });
    this.setState({
      switchStatus: true,
    });
  };

  render() {
    const { details } = this.props.details;
    return (
      <Fragment>
        {this.props.details.access_technologies_data != null ? (
          <div className="row">
            <div style={{ width: "100%", backgroundColor: "white", padding: "20px", borderRadius: "5px" }}>
              <div class="row">
                <div className="d-flex justify-content-end align-items-center" style={{ marginBottom: "10px", marginRight: '20px' }}>
                  {this.state.switchStatus ? (
                    <button
                      type="button"
                      className="btn btn-soft-primary btn-icon-square-sm"
                      onClick={() => this.dataUpdate()}
                      style={{ borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                    >
                      <i className="mdi mdi-pencil-outline" style={{color: 'white'}}></i>
                    </button>
                  ) : (
                    <div className="d-flex justify-content-end align-items-center">
                      <button
                        type="button"
                        className="btn btn-soft-secondary"
                        onClick={() => this.close()}
                        style={{ marginRight: "10px" }}
                      >
                        <span className="textStyles-small">Cancel</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-soft-primary"
                        onClick={() => this.showUpdateConfirm()}
                      >
                        <span className="textStyles-small">Update Details</span>
                      </button>
                    </div>
                  )}
                </div>
                <ul class="list-group">
                  <Row>
                    <Col span={12}>
                      <div style={{ width: "99%" }}>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                          <div>
                            <span className='textStyles-small'>
                              GSM
                            </span>
                          </div>
                          <div>
                            {this.state.switchStatus.toString() === "true" ? (this.props.details.access_technologies_data?.atGSM?.toLowerCase() === "yes" ? (
                              <Tag color="green" style={{ borderRadius: "5px" }}><span className="textStyles-small">Yes</span></Tag>
                            ) : (
                              <Tag color="red" style={{ borderRadius: "5px" }}><span className="textStyles-small">No</span></Tag>
                            )
                            ) : (
                              <Select
                                className='borderedSelect'
                                bordered={false}
                                defaultValue={
                                  this.props.details.access_technologies_data
                                    ?.atGSM
                                }
                                style={{ width: 120 }}
                                onChange={(e) => {
                                  this.state.dropdownOnchangeDataSet.atGSM = e;
                                }}
                              >
                                {this.state.dropDownSet.atGSM.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            )}
                          </div>
                        </li>

                        <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#FAFAFA' }}>
                          <div>
                            <span className='textStyles-small'>
                              DCS
                            </span>
                          </div>
                          <div>
                            {this.state.switchStatus.toString() == "true" ? (
                              this.props.details.access_technologies_data?.atDCS?.toLowerCase() === "yes" ? (
                                <Tag color="green" style={{ borderRadius: "5px" }}><span className="textStyles-small">Yes</span></Tag>
                              ) : (
                                <Tag color="red" style={{ borderRadius: "5px" }}><span className="textStyles-small">No</span></Tag>
                              )
                            ) : (
                              <Select
                                className='borderedSelect'
                                bordered={false}
                                defaultValue={
                                  this.props.details.access_technologies_data
                                    ?.atDCS
                                }
                                style={{ width: 120 }}
                                onChange={(e) => {
                                  this.state.dropdownOnchangeDataSet.atDCS = e;
                                }}
                              >
                                {this.state.dropDownSet.atDCS.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            )}
                          </div>
                        </li>

                        <li class="list-group-item d-flex justify-content-between align-items-center">
                          <div>
                            <span className='textStyles-small'>
                              3G
                            </span>
                          </div>
                          <div>
                            {this.state.switchStatus.toString() == "true" ? (this.props.details.access_technologies_data?.at3G?.toLowerCase() ===
                              "yes" ? (
                              <Tag color="green" style={{ borderRadius: "5px" }}><span className="textStyles-small">Yes</span></Tag>
                            ) : (
                              <Tag color="red" style={{ borderRadius: "5px" }}><span className="textStyles-small">No</span></Tag>
                            )
                            ) : (
                              <Select
                                className='borderedSelect'
                                bordered={false}
                                defaultValue={
                                  this.props.details.access_technologies_data
                                    ?.at3G
                                }
                                style={{ width: 120 }}
                                onChange={(e) => {
                                  this.state.dropdownOnchangeDataSet.at3G = e;
                                }}
                              >
                                {this.state.dropDownSet.at3G.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            )}
                          </div>
                        </li>

                        <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#FAFAFA' }}>
                          <div>
                            <span className='textStyles-small'>
                              4GFDD_900
                            </span>
                          </div>
                          <div>
                            {this.state.switchStatus.toString() == "true" ? (
                              this.props.details.access_technologies_data?.at4GFDD900?.toLowerCase() ===
                                "yes" ? (
                                <Tag color="green" style={{ borderRadius: "5px" }}><span className="textStyles-small">Yes</span></Tag>
                              ) : (
                                <Tag color="red" style={{ borderRadius: "5px" }}><span className="textStyles-small">No</span></Tag>
                              )
                            ) : (
                              <Select
                                className='borderedSelect'
                                bordered={false}
                                defaultValue={
                                  this.props.details.access_technologies_data
                                    ?.at4GFDD900
                                }
                                style={{ width: 120 }}
                                onChange={(e) => {
                                  this.state.dropdownOnchangeDataSet.at4GFDD900 =
                                    e;
                                }}
                              >
                                {this.state.dropDownSet.at4GFDD900.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            )}
                          </div>
                        </li>

                        <li class="list-group-item d-flex justify-content-between align-items-center">
                          <div>
                            <span className='textStyles-small'>
                              4GFDD_1800
                            </span>
                          </div>
                          <div>
                            {this.state.switchStatus.toString() == "true" ? (
                              this.props.details.access_technologies_data?.at4GFDD1800?.toLowerCase() ===
                                "yes" ? (
                                <Tag color="green" style={{ borderRadius: "5px" }}><span className="textStyles-small">Yes</span></Tag>
                              ) : (
                                <Tag color="red" style={{ borderRadius: "5px" }}><span className="textStyles-small">No</span></Tag>
                              )
                            ) : (
                              <Select
                                className='borderedSelect'
                                bordered={false}
                                defaultValue={
                                  this.props.details.access_technologies_data
                                    ?.at4GFDD1800
                                }
                                style={{ width: 120 }}
                                onChange={(e) => {
                                  this.state.dropdownOnchangeDataSet.at4GFDD1800 =
                                    e;
                                }}
                              >
                                {this.state.dropDownSet.at4GFDD1800.map(
                                  (x, y) => {
                                    return <Option value={x}>{x}</Option>;
                                  }
                                )}
                              </Select>
                            )}
                          </div>
                        </li>

                        <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#FAFAFA' }}>
                          <div>
                            <span className='textStyles-small'>
                              4GFDD2100
                            </span>
                          </div>
                          <div>
                            {this.state.switchStatus.toString() == "true" ? (
                              this.props.details.access_technologies_data?.at4GFDD2100?.toLowerCase() ===
                                "yes" ? (
                                <Tag color="green" style={{ borderRadius: "5px" }}><span className="textStyles-small">Yes</span></Tag>
                              ) : (
                                <Tag color="red" style={{ borderRadius: "5px" }}><span className="textStyles-small">No</span></Tag>
                              )
                            ) : (
                              <Select
                                className='borderedSelect'
                                bordered={false}
                                defaultValue={
                                  this.props.details.access_technologies_data
                                    ?.at4GFDD2100
                                }
                                style={{ width: 120 }}
                                onChange={(e) => {
                                  this.state.dropdownOnchangeDataSet.at4GFDD2100 =
                                    e;
                                }}
                              >
                                {this.state.dropDownSet.at4GFDD2100.map(
                                  (x, y) => {
                                    return <Option value={x}>{x}</Option>;
                                  }
                                )}
                              </Select>
                            )}
                          </div>
                        </li>
                      </div>
                    </Col>

                    <Col span={12}>
                      <div style={{ width: "99%" }}>
                        <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#FAFAFA' }}>
                          <div>
                            <span className='textStyles-small'>
                              4GTDD2300
                            </span>
                          </div>
                          {/* {this.props.details.access_technologies_data?.at4GTDD2300 ? (<span class="badge badge-outline-success">{JSON.stringify(this.props.details.access_technologies_data.at4GTDD2300)}</span>) : (<span class="badge badge-outline-warning">{JSON.stringify(this.props.details.access_technologies_data?.at4GTDD2300)}</span>)} */}
                          <div>
                            {this.state.switchStatus.toString() == "true" ? (
                              this.props.details.access_technologies_data?.at4GTDD2300?.toLowerCase() ===
                                "yes" ? (
                                <Tag color="green" style={{ borderRadius: "5px" }}><span className="textStyles-small">Yes</span></Tag>
                              ) : (
                                <Tag color="red" style={{ borderRadius: "5px" }}><span className="textStyles-small">No</span></Tag>
                              )
                            ) : (
                              <Select
                                className='borderedSelect'
                                bordered={false}
                                defaultValue={
                                  this.props.details.access_technologies_data
                                    ?.at4GTDD2300
                                }
                                style={{ width: 120 }}
                                onChange={(e) => {
                                  this.state.dropdownOnchangeDataSet.at4GTDD2300 =
                                    e;
                                }}
                              >
                                {this.state.dropDownSet.at4GTDD2300.map(
                                  (x, y) => {
                                    return <Option value={x}>{x}</Option>;
                                  }
                                )}
                              </Select>
                            )}
                          </div>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                          <div>
                            <span className='textStyles-small'>
                              5G
                            </span>
                          </div>
                          {/* {this.props.details.access_technologies_data?.at5G ? (<span class="badge badge-outline-success">{JSON.stringify(this.props.details.access_technologies_data?.at5G)}</span>) : (<span class="badge badge-outline-warning">{JSON.stringify(this.props.details.access_technologies_data?.at5G)}</span>)} */}
                          <div>
                            {this.state.switchStatus.toString() == "true" ? (
                              this.props.details.access_technologies_data?.at5G?.toLowerCase() ===
                                "yes" ? (
                                <Tag color="green" style={{ borderRadius: "5px" }}><span className="textStyles-small">Yes</span></Tag>
                              ) : (
                                <Tag color="red" style={{ borderRadius: "5px" }}><span className="textStyles-small">No</span></Tag>
                              )
                            ) : (
                              <Select
                                className='borderedSelect'
                                bordered={false}
                                defaultValue={
                                  this.props.details.access_technologies_data
                                    ?.at5G
                                }
                                style={{ width: 120 }}
                                onChange={(e) => {
                                  this.state.dropdownOnchangeDataSet.at5G = e;
                                }}
                              >
                                {this.state.dropDownSet.at5G.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            )}
                          </div>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#FAFAFA' }}>
                          <div>
                            <span className='textStyles-small'>
                              WIBASS
                            </span>
                          </div>
                          {/* {this.props.details.access_technologies_data?.atWIBASS ? (<span class="badge badge-outline-success">{JSON.stringify(this.props.details.access_technologies_data.atWIBASS)}</span>) : (<span class="badge badge-outline-warning">{JSON.stringify(this.props.details.access_technologies_data?.atWIBASS)}</span>)} */}
                          <div>
                            {this.state.switchStatus.toString() == "true" ? (
                              this.props.details.access_technologies_data?.atWIBASS?.toLowerCase() ===
                                "yes" ? (
                                <Tag color="green" style={{ borderRadius: "5px" }}><span className="textStyles-small">Yes</span></Tag>
                              ) : (
                                <Tag color="red" style={{ borderRadius: "5px" }}><span className="textStyles-small">No</span></Tag>
                              )
                            ) : (
                              <Select
                                className='borderedSelect'
                                bordered={false}
                                defaultValue={
                                  this.props.details.access_technologies_data
                                    ?.atWIBASS
                                }
                                style={{ width: 120 }}
                                onChange={(e) => {
                                  this.state.dropdownOnchangeDataSet.atWIBASS = e;
                                }}
                              >
                                {this.state.dropDownSet.atWIBASS.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            )}
                          </div>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                          <div>
                            <span className='textStyles-small'>
                              CAMBIUM
                            </span>
                          </div>
                          {/* {this.props.details.access_technologies_data?.atCAMBIUM ? (<span class="badge badge-outline-success">{JSON.stringify(this.props.details.access_technologies_data.atCAMBIUM)}</span>) : (<span class="badge badge-outline-warning">{JSON.stringify(this.props.details.access_technologies_data?.atCAMBIUM)}</span>)} */}
                          <div>
                            {this.state.switchStatus.toString() == "true" ? (
                              this.props.details.access_technologies_data?.atCAMBIUM?.toLowerCase() ===
                                "yes" ? (
                                <Tag color="green" style={{ borderRadius: "5px" }}><span className="textStyles-small">Yes</span></Tag>
                              ) : (
                                <Tag color="red" style={{ borderRadius: "5px" }}><span className="textStyles-small">No</span></Tag>
                              )
                            ) : (
                              <Select
                                className='borderedSelect'
                                bordered={false}
                                defaultValue={
                                  this.props.details.access_technologies_data
                                    ?.atCAMBIUM
                                }
                                style={{ width: 120 }}
                                onChange={(e) => {
                                  this.state.dropdownOnchangeDataSet.atCAMBIUM =
                                    e;
                                }}
                              >
                                {this.state.dropDownSet.atCAMBIUM.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            )}
                          </div>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#FAFAFA' }}>
                          <div>
                            <span className='textStyles-small'>
                              GPON
                            </span>
                          </div>
                          {/* {this.props.details.access_technologies_data?.atGPON ? (<span class="badge badge-outline-success">{JSON.stringify(this.props.details.access_technologies_data.atGPON)}</span>) : (<span class="badge badge-outline-warning">{JSON.stringify(this.props.details.access_technologies_data?.atGPON)}</span>)} */}
                          <div>
                            {this.state.switchStatus.toString() == "true" ? (
                              this.props.details.access_technologies_data?.atGPON?.toLowerCase() ===
                                "yes" ? (
                                <Tag color="green" style={{ borderRadius: "5px" }}><span className="textStyles-small">Yes</span></Tag>
                              ) : (
                                <Tag color="red" style={{ borderRadius: "5px" }}><span className="textStyles-small">No</span></Tag>
                              )
                            ) : (
                              <Select
                                className='borderedSelect'
                                bordered={false}
                                defaultValue={
                                  this.props.details.access_technologies_data
                                    ?.atGPON
                                }
                                style={{ width: 120 }}
                                onChange={(e) => {
                                  this.state.dropdownOnchangeDataSet.atGPON = e;
                                }}
                              >
                                {this.state.dropDownSet.atGPON.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            )}
                          </div>
                        </li>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                          <div>
                            <span className='textStyles-small'>
                              Access Technology Vendor
                            </span>
                          </div>
                          <div>
                            {this.state.switchStatus?.toString() == "true" ? (
                              this.props.details.access_technologies_data?.atAccessTechnologyVendor === null || this.props.details.access_technologies_data?.atAccessTechnologyVendor == undefined ||
                                this.props.details.access_technologies_data?.atAccessTechnologyVendor == "" ? (
                                <Tag color="red" style={{ borderRadius: "5px" }}><span className="textStyles-small">Not Found</span></Tag>
                              ) : (<Tag color="green" style={{ borderRadius: "5px" }}><span className="textStyles-small">
                                {this.props.details.access_technologies_data?.atAccessTechnologyVendor}</span></Tag>)
                            ) : (
                              <Input
                                style={{ borderRadius: 5, width: '120px', borderColor: '#356796' }}
                                onChange={(e) => {
                                  this.state.dropdownOnchangeDataSet.atAccessTechnologyVendor = e.target.value;
                                }
                                }
                                defaultValue={this.props.details.access_technologies_data?.atAccessTechnologyVendor}
                              />
                            )}
                          </div>
                        </li>
                      </div>
                    </Col>
                  </Row>
                </ul>
              </div>
            </div>

          </div>
        ) : (
          <div
            style={{
              height: "120px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ color: "#c5c5c5" }}>
              Data NotFound Access Technologies
            </h4>
          </div>
        )}
      </Fragment>
    );
  }
}
export default AccessTechnologies;