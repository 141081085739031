import React, { Component, useState } from "react";
import SideBar from "./app/pages/common/sideBar";
import NotAllowed from "./app/pages/common/not-allowed";
import Header from "./app/pages/common/header";
import { BrowserRouter, Switch, Route, Link, Redirect } from "react-router-dom";
import Login from "./app/pages/login";
import Organization from "./app/pages/organization";
// site
import SiteCreate from "./app/pages/site/site-create";
import SiteEdit from "./app/pages/site/site-edit";
import Site from "./app/pages/site/index";
import SiteInfor from "./app/pages/site-infor/site-index";
import SiteCategory from "./app/pages/site/site_categories";
import SiteSummary from "./app/pages/site/site_summary";

import QueryBuilder from "./app/pages/filter/index"; 
import MapView from "./app/pages/site/map/map_view";
import Sld from "./app/pages/site/sld";
import Sld2 from "./app/pages/site/sld2";

import RegionList from "./app/pages/region/region-list";
import RegionSummary from "./app/pages/region/region-summary";

import DepotList from "./app/pages/depot/depot-list";
import DepotSummary from "./app/pages/depot/depot-summary";

import RegionWiseUsers from "./app/pages/report/region-wise-users";
import RegionWiseVendorTeams from "./app/pages/report/region-wise-vendor-teams";
import UserWisePgCount from "./app/pages/report/user-wise-pg-count";
import RegionWiseSiteList from "./app/pages/report/region-wise-site-list";
import RegionWiseDepotList from "./app/pages/report/region-wise-depot-list";
import FullExport from "./app/pages/report/full-export";
import RequestReport from "./app/pages/report/request-report";
import ReportIndex from "./app/pages/report/index";

import "antd/dist/antd.css";
import Dashboard from "./app/pages/dashboard";
import DashboardGen from "./app/pages/dashboard-gen";
import Inbox from "./app/pages/inbox";

import DashboardPg from "./app/pages/dashboard-pg";
import DashboardPgPendingStatus from "./app/pages/dashboard-pg-pending-status";
import DashboardPgPendingStatusDepot from "./app/pages/dashboard-pg-pending-status-depot";
import DashboardPgCost from "./app/pages/dashboard-pg-cost";
import DashboardPgCostDepot from "./app/pages/dashboard-pg-cost-depot";
import DashboardPgLive from "./app/pages/dashboard-pg-live";
import DashboardAllPgLive from "./app/pages/dashboard-all-pg-live";
import DashboardPgStatus from "./app/pages/dashboard-pg-status";
import DashboardMapView from "./app/pages/dashboard-map-view";
import DashboardExceptional from "./app/pages/dashboard-exceptional";
//pg
import PgView from "./app/pages/pg/pg-view";



// refule
import DashboardRefuel from "./app/pages/refuel/dashboard-refuel";
import RefuelFullExport from "./app/pages/refuel/refuel-full-export";
import ProcurementRefuelFullExport from "./app/pages/refuel/procurement-refuel-full-export";
import FuelBalanceExport from "./app/pages/refuel/fuel-balance-export";

//vehicle
import VehicleFullExport from "./app/pages/vehicle/vehicle-full-export"
import PaymentFullExport from "./app/pages/vehicle/payment-full-export"
import VehicleIndex from "./app/pages/vehicle/index"
import VehicleMaster from "./app/pages/vehicle/vehicle-master"
import VehicleDashboard from "./app/pages/vehicle/dashboard"
//site-access
import Create from "./app/pages/site-access/create"
import SiteAccessList from "./app/pages/site-access/site-access-list"
import SiteAccessHandler from "./app/pages/site-access/site-access-handler"
import SiteAccessEdit from "./app/pages/site-access/edit"
import SiteAccessEditHandler from "./app/pages/site-access/edit-handler"
import AllRequests from "./app/pages/site-access/all-requests"
import SiteAccessFullExport from "./app/pages/site-access/full-export"
import SiteAccessTeamMemberFullExport from "./app/pages/site-access/team-member-full-export"
import SiteAccessDashboard from "./app/pages/site-access/dashboard"
import MasterData from "./app/pages/rewamp/access/access-masterData";
import AccessReports from "./app/pages/rewamp/access/access-reports";

//PowerDb
import PowerDataExport from "./app/pages/power-db/power-data-export"
import UploadPowerdb from "./app/pages/power-db/upload-powerdb"
import PowerDataView from "./app/pages/power-db/power-data-view"
import DataManage from "./app/pages/power-db/data-manage"
import PowerDataDashboard from "./app/pages/power-db/power-data-dashboard"

//image
import ImageCaptureList from "./app/pages/image-capture/index"
import ImageFullExport from "./app/pages/image-capture/full-export"
import ImageCaptureView from "./app/pages/image-capture/view"
import ImageAllRequests from "./app/pages/image-capture/all-requests"
import ImagesView from "./app/pages/image-capture/image-view"
import RequestsView from "./app/pages/image-capture/requests-view"

//PIS
import Scheduler from "./app/pages/pis/scheduler";
import PisHistoryView from "./app/pages/pis/detailView/all-requests";
import PisRequestDetails from "./app/pages/pis/detailView/pis-request-details"; 
import SCDetailsView from "./app/pages/pis/detailView/dataViewSC";

import PISDashboardGen from "./app/pages/pis/dashboard/dashboard-gen";
import PISDashboardAC from "./app/pages/pis/dashboard/dashboard-ac";
import DashboardSiteCleaning from "./app/pages/pis/dashboard/dashboard-site-cleaning";
import DashboardCivilRoutine from "./app/pages/pis/dashboard/dashboard-civil-routine";

// routing
import RoutindHistoryView from "./app/pages/routing/detailView/all-requests";
import RoutineRequestDetails from "./app/pages/routing/detailView/routine-request-details";
import RoutingDataExport from "./app/pages/routing/routing-data-export";
import RoutineDashboard from "./app/pages/routing/dashboard";
import RoutineObservation from "./app/pages/routing/master-data/observation/index"

// PIS
import PisGenServiceFullExport from "./app/pages/pis/gen-service-export"
import PisACServiceFullExport from "./app/pages/pis/ac-service-export"
import PisCivilServiceFullExport from "./app/pages/pis/civil-service-export"
import PisScServiceFullExport from "./app/pages/pis/sc-service-export"
import PisGenServiceCheklistExport from "./app/pages/pis/cheklist-export/gen-service"
import CRDetailsView from "./app/pages/pis/detailView/dataViewCivil";
import DashboardMOM from "./app/pages/pis/dashboard/dashboard-all-mom";

//App Version
import AppVersion from "./app/pages/app-version/index"
import TargetValues from "./app/pages/dashboard-settings/target-values"

// Vehicle Module New
import JobList from "./app/pages/vehicle-module/job-list";
import VehicleList from "./app/pages/vehicle-module/vehicle-list";
import SessionsList from "./app/pages/vehicle-module/sessions-list";
import DashboardVehicle from "./app/pages/vehicle-module/dashboard-vehicle";
import VehicleMasters from "./app/pages/vehicle-module/vehicle-master";
import ExportVehicle from "./app/pages/vehicle-module/export-vehicle";
import VendorPool from "./app/pages/vehicle-module/vendor-pool";
import ConfigVehicle from "./app/pages/vehicle-module/vehicle-config";
import ExportMaster from "./app/pages/vehicle-module/vehicle-master-export";

// rewamp access permission module 
import AccessRequest from "./app/pages/rewamp/access/access-request";
import AccessManage from "./app/pages/rewamp/access/access-manage";
import AcessDashboard from "./app/pages/rewamp/access/access-dashboard";
import AccessApproval from "./app/pages/rewamp/access/access-handler";
import EditHistory from "./app/pages/rewamp/access/access-history";
import BlacklistAcc from "./app/pages/rewamp/access/access-blacklist";
import AccMasterData from "./app/pages/rewamp/access/access-masterData";
import AccessReportsData from "./app/pages/rewamp/access/access-reports";
import AccessDash from "./app/pages/rewamp/access/dashboard";
import AccessRequestOverview from "./app/pages/rewamp/access/access-requestOverview";
import AccessRequesterOverview from "./app/pages/rewamp/access/access-requestOverviewRequester";
// import AccessApprovalView from "./app/pages/rewamp/access/access-handler-view";



import Profile from "./app/pages/profile";
import Region from "./app/pages/region";
import Users from "./app/pages/users";
import DepotUsers from "./app/pages/depotUsers";
import Pg from "./app/pages/pg";
import Depot from "./app/pages/depot";
import Vendor from "./app/pages/vendor/vendor";
import VendorTeam from "./app/pages/vendor/vendor_team";
import VendorSLATerm from "./app/pages/vendor/vendor_sla_term";
import ServiceTypes from "./app/pages/service/service_types";
import DynamicForm from "./app/pages/dynamic_form";
import Contract from "./app/pages/contract";
import ShelterTypes from "./app/pages/shelter_types";
import SiteCreation from "./app/pages/site/site_creation";
import TrackPg from "./app/pages/track-pg";
import { msalConfig, loginRequest } from "./app/api/authConfig";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { getUserRolesAndPermission } from "./app/api/index";
import StatisticData from "./app/pages/report/statistic-data";
import { getDonationCategories, getData } from ".";
import site from "./app/pages/site/index";
import UserExport from "./app/pages/user-export";


import UserModule from "./app/pages/master-data/roles-management";
import RoleModule from "./app/pages/master-data/permission-management";
import DropdownManagement from "./app/pages/master-data/dropdown-management";
import SiteExport from "./app/pages/site/site-Export";
import MasterdataExport from "./app/pages/master-data/masterdata-export";
import MigrateSites from "./app/pages/site/migrateSites";

import PgHistoryView from "./app/pages/pg/pg-details";
import FillingView from "./app/pages/refuel/fuel-fillingView";
import ProcurementRefuelFullView from "./app/pages/refuel/fuel-procurementView";
import PgDetailsAllView from "./app/pages/pg/pg-detailsAll";


import SiteAll from "./app/pages/siteView/index";
import SiteInfo from "./app/pages/siteView/site-info";

// import { ProfileData } from "./components/ProfileData";
// import { callMsGraph } from "./graph";
// import Button from "react-bootstrap/Button";
var isAuthenticated = false;

const ProfileContent = () => {

  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  RequestProfileData();

  function RequestProfileData() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        console.log("set token");
        console.log({ response });
        //  setlocts()
        localStorage.setItem("access_token", response.accessToken);
        localStorage.setItem("logged_user_email", response.account.username);

        isAuthenticated = true;

        // callMsGraph(response.accessToken).then(response => setGraphData(response));
      });
  }
  function setlocts() {
    // //console.log("fuck2")
  }

  return (
    <>
      {/* <span variant="secondary" onClick={RequestProfileData}>Request Profile Information</span> */}

      {/* <h5 className="card-title"><i class="fa fa-circle" style={{ color: '#4bd74b' }}></i> Welcome, {accounts[0].name}</h5> */}
      <a class="nav-link dropdown-toggle waves-effect waves-light nav-user" data-bs-toggle="dropdown" href="#" role="button"
        aria-haspopup="false" aria-expanded="false" style={{ padding: 0 }}>
        <span class="ms-1 nav-user-name hidden-sm" style={{ fontSize: 'small' }}>{accounts[0].name}{console.log('sdfgggg')} {console.log(accounts)}</span>

      </a>

      {/* {graphData ? 
                <ProfileData graphData={graphData} />
                :
                <span variant="secondary" onClick={RequestProfileData}>Request Profile Information</span>
            } */}
    </>
  );
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
  //console.log("MainContent")
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <ProfileContent />
      </AuthenticatedTemplate>

      {/* <UnauthenticatedTemplate>
                <h5 className="card-title">Please sign-in to see your DNS account.</h5>
            </UnauthenticatedTemplate> */}
    </div>
  );
};
export const Do = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      //console.log(e);
    });
  };
  handleLogin();
  return <div></div>;
};
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isAuthenticated: false,
      user: {},
      loginType: ''
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage?.getItem('setRole'))?.length == 1 && JSON.parse(localStorage?.getItem('setRole'))[0]?.id == 19) {
      this.setState({
        loginType: JSON?.parse(localStorage.getItem('setRole'))[0]?.id
      });
    }
    <SignInButton />;
  }


  render() {
    const { match } = this.props;
    return (
      <>
        {true ? (
          <AuthenticatedTemplate>
            {window.location.pathname !== "/dashboard-pg-live" &&
            window.location.pathname !== "/dashboard-all-pg-live" &&
            window.location.pathname !== "/dashboard-pg-status" ? (
              <div>
                <div className="page-wrapper">
                  <Header
                    mainContent={MainContent}
                    signOutButton={SignOutButton}
                  />

                  <div className="page-content">
                    <BrowserRouter>
                      <Switch>
                      <Route path={`/refuel/procurement-view`}>
                        <ProcurementRefuelFullView />
                      </Route>

                      <Route path={`/site/new/:id`}>
                        <SiteInfo />
                      </Route>

                      <Route path={`/site/all/new`}>
                      <SiteAll />
                      </Route>
                      <Route path={`/pg/details/:id`}>
                        <PgDetailsAllView />
                      </Route>
                      <Route path={`/vehicle/management`}>
                        <VehicleList />
                      </Route>
                      <Route path={`/pg/all-jobs`}>
                        <PgHistoryView />
                      </Route>
                      <Route path={`/refuel/filling-view`}>
                        <FillingView />
                      </Route>
                      <Route path={`/pis/detailView/sc`}>
                        <SCDetailsView />
                      </Route>
                      <Route path={`/vehicle/session/:id`}>
                        <JobList />
                      </Route>
                      <Route path={`/pis/all/mom`}>
                        <DashboardMOM />
                      </Route>
                      <Route path={`/vehicle/sessions`}>
                        <SessionsList />
                      </Route>
                      <Route path={`/vehicle/new/dashboard`}>
                        <DashboardVehicle />
                      </Route>
                      <Route path={`/vehicle/new/master`}>
                        <VehicleMasters />
                      </Route>
                      <Route path={`/vehicle/new/export`}>
                        <ExportVehicle />
                      </Route>
                      <Route path={`/vehicle/new/vendor-pool`}>
                        <VendorPool />
                      </Route>
                      <Route path={`/vehicle/new/config`}>
                        <ConfigVehicle />
                      </Route>

                      <Route path={`/new/role/management`}>
                        <UserModule />
                      </Route>

                      <Route path={`/new/permission/management`}>
                        <RoleModule />
                      </Route>

                      <Route path={`/new/dropdown/management`}>
                        <DropdownManagement />
                      </Route>

                      <Route path={`/site/export`}>
                        <SiteExport />
                      </Route>

                      <Route path={`/site/migrate`}>
                        <MigrateSites />
                      </Route>

                      <Route path={`/masterdata/export`}>
                        <MasterdataExport />
                      </Route>
                      
                      <Route path={`/pis/detailView/civil/:id/:type`}>
                        <CRDetailsView />
                      </Route>

                      <Route path={`/vehicle/new/export-master`}>
                        <ExportMaster />
                      </Route>

                      <Route path={`/access/dev/request`}>
                        <AccessRequest />
                      </Route>

                      <Route path={`/access/dev/manage`}>
                        <AccessManage />
                      </Route>

                      {/* <Route path={`/access/dev/view/:id`}>
                        <AcessDashboard />
                      </Route> */}

                      <Route path={`/access/dev/history`}>
                        <EditHistory />
                      </Route>
                      
                      <Route path={`/access/dev/approval`}>
                        <AccessApproval />
                      </Route>

                      <Route path={`/access/dev/blacklist`}>
                        <BlacklistAcc />
                      </Route>
                      <Route path={`/access/dev/dashboard`}>
                        <AccessDash />
                      </Route>
                      <Route path={`/access/dev/master`}>
                        <AccMasterData />
                      </Route>
                      <Route path={`/access/dev/requester/overview`}>
                        <AccessRequesterOverview />
                      </Route>

                      <Route path={`/access/dev/reports`}>
                        <AccessReportsData />
                      </Route>

                      <Route path={`/access/dev/overview`}>
                        <AccessRequestOverview />
                      </Route>
                        
                        <Route path={`/access/dev/view/:id/:type`}>
                          <AcessDashboard />
                        </Route>

                        <Route path={`/login`}>
                          <Login />
                        </Route>
                        <Route path={`/organization`}>
                          <Organization />
                        </Route>
                        <Route path={`/depot`}>
                          <Depot />
                        </Route>
                        <Route path={`/region`}>
                          <Region />
                        </Route>
                        <Route path={`/site`}>
                          <Site />
                        </Route>
                        <Route path={`/site-info`}>
                          <SiteInfor />
                        </Route>
                        <Route path={`/site-summary/:id`}>
                          <SiteSummary />
                        </Route>
                        <Route path={`/region-list`}>
                          <RegionList />
                        </Route>
                        <Route path={`/region-summary/:id`}>
                          <RegionSummary />
                        </Route>
                        <Route path={`/depot-list`}>
                          <DepotList />
                        </Route>
                        <Route path={`/depot-summary/:id`}>
                          <DepotSummary />
                        </Route>
                        <Route path={`/dynamic`}>
                          <DynamicForm />
                        </Route>
                        <Route path={`/vendor`}>
                          <Vendor />
                        </Route>
                        <Route path={`/vendor_team`}>
                          <VendorTeam />
                        </Route>
                        <Route path={`/service_types`}>
                          <ServiceTypes />
                        </Route>
                        <Route path={`/vendor_sla_term`}>
                          <VendorSLATerm />
                        </Route>
                        <Route path={`/contracts`}>
                          <Contract />
                        </Route>
                        <Route path={`/site-category`}>
                          <SiteCategory />
                        </Route>
                        <Route path={`/shelter-types`}>
                          <ShelterTypes />
                        </Route>
                        <Route path={`/site-creation`}>
                          <SiteCreation />
                        </Route>
                        <Route path={`/users`}>
                          <Users />
                        </Route>
                        <Route path={`/user-export`}>
                          <UserExport />
                        </Route>
                        <Route path={`/depot-users`}>
                          <DepotUsers />
                        </Route>
                        <Route path={`/pg`}>
                          <Pg />
                        </Route>
                        <Route path={`/pg-view`}>
                          <PgView />
                        </Route>

                        <Route path={`/report/region-wise-users`}>
                          <RegionWiseUsers />
                        </Route>
                        <Route path={`/report/region-wise-vendor-teams`}>
                          <RegionWiseVendorTeams />
                        </Route>
                        <Route path={`/report/region-wise-pg-count`}>
                          <UserWisePgCount />
                        </Route>
                        <Route path={`/report/region-wise-site-list`}>
                          <RegionWiseSiteList />
                        </Route>
                        <Route path={`/report/region-wise-depot-list`}>
                          <RegionWiseDepotList />
                        </Route>
                        <Route path={`/report/statistic-data`}>
                          <StatisticData />
                        </Route>
                        <Route path={`/report/full-export`}>
                          <FullExport />
                        </Route>
                        <Route path={`/fuel-balance-export`}>
                          <FuelBalanceExport />
                        </Route>
                        <Route path={`/report/request-report`}>
                          <RequestReport />
                        </Route>
                        <Route path={`/pis/dashboard/gen`}>
                          <PISDashboardGen />
                        </Route>
                        <Route path={`/pis/dashboard/civil_routine`}>
                          <DashboardCivilRoutine />
                        </Route>
                        <Route path={`/pis/dashboard/site_cleaning`}>
                          <DashboardSiteCleaning />
                        </Route>

                        <Route path={`/pis/dashboard/ac`}>
                          <PISDashboardAC />
                        </Route>
                        <Route path={`/dashboard-refuel`}>
                          <DashboardRefuel />
                        </Route>
                        <Route path={`/refuel-full-export`}>
                          <RefuelFullExport />
                        </Route>
                        <Route path={`/vehicle-full-export`}>
                          <VehicleFullExport />
                        </Route>
                        <Route path={`/payment-full-export`}>
                          <PaymentFullExport />
                        </Route>
                        <Route path={`/vehicles`}>
                          <VehicleIndex />
                        </Route>
                        <Route path={`/vehicle/master`}>
                          <VehicleMaster />
                        </Route>
                        <Route path={`/vehicle/dashboard`}>
                          <VehicleDashboard />
                        </Route>
                        <Route path={`/dashboard/gen`}>
                          <DashboardGen />
                        </Route>
                        <Route path={`/routine/dashboard`}>
                          <RoutineDashboard />
                        </Route>
                        <Route path={`/routine/observation`}>
                          <RoutineObservation />
                        </Route>
                        <Route path={`/site-access/dashboard`}>
                          <SiteAccessDashboard />
                        </Route>
                        <Route path={`/site-access/create`}>
                          <Create />
                        </Route>
                        <Route path={`/site-access/full-export`}>
                          <SiteAccessFullExport />
                        </Route>
                        <Route path={`/site-access/full-export-team-member`}>
                          <SiteAccessTeamMemberFullExport />
                        </Route>
                        <Route path={`/not-allowed`}>
                          <NotAllowed />
                        </Route>
                        <Route path={`/power-db/upload-powerdb`}>
                          <UploadPowerdb />
                        </Route>
                        <Route path={`/power-db/power-data-export`}>
                          <PowerDataExport />
                        </Route>
                        <Route path={`/power-db/power-data-manage`}>
                          <DataManage />
                        </Route>
                        <Route path={`/power-db/dashboard`}>
                          <PowerDataDashboard />
                        </Route>
                        <Route path={`/power-db/power-data-view`}>
                          <PowerDataView />
                        </Route>
                        <Route path={`/image-capture/view`}>
                          <ImageCaptureView />
                        </Route>
                        <Route path={`/image-capture/all-requests`}>
                          <ImageAllRequests />
                        </Route>
                        <Route path={`/image-capture/images-view`}>
                          <ImagesView />
                        </Route>
                        <Route path={`/image-capture/list`}>
                          <ImageCaptureList />
                        </Route>
                        <Route path={`/image-capture/full-export`}>
                          <ImageFullExport />
                        </Route>
                        <Route path={`/routing/data-export`}>
                          <RoutingDataExport />
                        </Route>
                        <Route path={`/pis/gen-service-export`}>
                          <PisGenServiceFullExport />
                        </Route>
                        <Route path={`/pis/cheklist-export`}>
                          <PisGenServiceCheklistExport />
                        </Route>
                        <Route path={`/dashboard/target-values`}>
                          <TargetValues />
                        </Route>
                        <Route path={`/app-version`}>
                          <AppVersion />
                        </Route>
                        <Route path={`/pis/ac-service-export`}>
                          <PisACServiceFullExport />
                        </Route>
                        <Route path={`/pis/civil-service-export`}>
                          <PisCivilServiceFullExport />
                        </Route>
                        <Route path={`/pis/sc-service-export`}>
                          <PisScServiceFullExport />
                        </Route>
                        <Route path={`/image-capture/requests-view/:id`}>
                          <RequestsView />
                        </Route>
                        <Route path={`/site-access/list`}>
                          <SiteAccessList />
                        </Route>
                        <Route path={`/site-access/handler`}>
                          <SiteAccessHandler />
                        </Route>
                        <Route path={`/sites/create`}>
                          <SiteCreate />
                        </Route>
                        <Route path={`/sites/edit/:id`}>
                          <SiteEdit />
                        </Route>
                        <Route path={`/site-access/edit/:id`}>
                          <SiteAccessEdit />
                        </Route>
                        <Route path={`/site-access/edit-handler/:id`}>
                          <SiteAccessEditHandler />
                        </Route>
                        <Route path={`/site-access/all-requests`}>
                          <AllRequests />
                        </Route>
                        <Route path={`/procurement-refuel-full-export`}>
                          <ProcurementRefuelFullExport />
                        </Route>
                        <Route path={`/dashboard-pg`}>
                          <DashboardPg />
                        </Route>
                        <Route path={`/dashboard-pg-cost`}>
                          <DashboardPgCost />
                        </Route>
                        <Route path={`/dashboard-pg-cost-depot`}>
                          <DashboardPgCostDepot />
                        </Route>

                        <Route path={`/dashboard-pg-live`}>
                          <DashboardPgLive />
                        </Route>
                        <Route path={`/dashboard-pg-pending-status`}>
                          <DashboardPgPendingStatus />
                        </Route>
                        <Route path={`/dashboard-pg-pending-status-depot`}>
                          <DashboardPgPendingStatusDepot />
                        </Route>
                        <Route path={`/.`}>
                          <DashboardPgPendingStatusDepot />
                        </Route>
                        <Route path={`/dashboard-all-pg-live`}>
                          <DashboardAllPgLive />
                        </Route>
                        <Route path={`/dashboard-pg-status`}>
                          <DashboardPgStatus />
                        </Route>
                        <Route path={`/dashboard-exceptional`}>
                          <DashboardExceptional />
                        </Route>
                        <Route path={`/pis-history-view`}>
                          <PisHistoryView />
                        </Route>
                        <Route path={`/routine-history-view`}>
                          <RoutindHistoryView />
                        </Route>
                        <Route path={`/pis-detail-view/:id/:type`}>
                          <PisRequestDetails />
                        </Route>
                        <Route path={`/routine-detail-view/:id/:type`}>
                          <RoutineRequestDetails />
                        </Route>
                        <Route path={`/dashboard-map`}>
                          <DashboardMapView />
                        </Route>
                        <Route path={`/inbox`}>
                          <Inbox />
                        </Route>
                        <Route path={`/profile`}>
                          <Profile />
                        </Route>
                        <Route path={`/report`}>
                          <ReportIndex />
                        </Route>
                        <Route path={`/track-pg`}>
                          <TrackPg />
                        </Route>
                        <Route path={`/scheduler`}>
                          <Scheduler />
                        </Route>
                        <Route path={`/map`}>
                          <MapView />
                        </Route>
                        <Route path={`/power-db/query`}>
                          <QueryBuilder />
                        </Route>
                        <Route path={`/sites/sld`}>
                          <Sld />
                        </Route>
                        <Route path={`/sites/sld2`}>
                          <Sld2 />
                        </Route>
                        {this.state.loginType == 19 ? (
                          <Route path={`/`}>
                            <SiteAccessList />
                          </Route>
                        ) : (
                          <Route path={`/`}>
                            <DashboardGen />
                          </Route>
                        )}
                        <Route path="*">{/* <NotFound /> */}</Route>
                      </Switch>
                    </BrowserRouter>
                  </div>
                </div>
                {localStorage.getItem("access_token") != null ? (
                  <div className="left-sidenav">
                    <SideBar />
                  </div>
                ) : (
                  <div className="left-sidenav">
                    <SideBar />
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div
                  style={{
                    marginTop: "16px",
                    marginLeft: "16px",
                    marginRight: "16px",
                  }}
                >
                  {/* <Breadcrumb style={{ margin: '16px 0' }}>
						
						</Breadcrumb> */}
                  <BrowserRouter>
                    <Switch>
                      <Route path={`/vehicle/vehicle-list`}>
                        <VehicleList />
                      </Route>
                      <Route path={`/vehicle/job-list`}>
                        <JobList />
                      </Route>
                      <Route path={`/login`}>
                        <Login />
                      </Route>
                      <Route path={`/organization`}>
                        <Organization />
                      </Route>
                      <Route path={`/depot`}>
                        <Depot />
                      </Route>
                      <Route path={`/region`}>
                        <Region />
                      </Route>
                      <Route path={`/site`}>
                        <Site />
                      </Route>
                      <Route path={`/site/site-info`}>
                        <SiteInfor />
                      </Route>
                      <Route path={`/site-summary/:id`}>
                        <SiteSummary />
                      </Route>
                      <Route path={`/region-list`}>
                        <RegionList />
                      </Route>
                      <Route path={`/region-summary/:id`}>
                        <RegionSummary />
                      </Route>
                      <Route path={`/depot-list`}>
                        <DepotList />
                      </Route>
                      <Route path={`/depot-summary/:id`}>
                        <DepotSummary />
                      </Route>
                      <Route path={`/dynamic`}>
                        <DynamicForm />
                      </Route>
                      <Route path={`/vendor`}>
                        <Vendor />
                      </Route>
                      <Route path={`/vendor_team`}>
                        <VendorTeam />
                      </Route>
                      <Route path={`/service_types`}>
                        <ServiceTypes />
                      </Route>
                      <Route path={`/vendor_sla_term`}>
                        <VendorSLATerm />
                      </Route>
                      <Route path={`/contracts`}>
                        <Contract />
                      </Route>
                      <Route path={`/site-category`}>
                        <SiteCategory />
                      </Route>
                      <Route path={`/shelter-types`}>
                        <ShelterTypes />
                      </Route>
                      <Route path={`/site-creation`}>
                        <SiteCreation />
                      </Route>
                      <Route path={`/users`}>
                        <Users />
                      </Route>
                      <Route path={`/user-export`}>
                        <UserExport />
                      </Route>
                      <Route path={`/depot-users`}>
                        <DepotUsers />
                      </Route>
                      <Route path={`/pg`}>
                        <Pg />
                      </Route>
                      <Route path={`/pg-view`}>
                        <PgView />
                      </Route>

                      <Route path={`/report/region-wise-users`}>
                        <RegionWiseUsers />
                      </Route>
                      <Route path={`/report/region-wise-vendor-teams`}>
                        <RegionWiseVendorTeams />
                      </Route>
                      <Route path={`/report/region-wise-pg-count`}>
                        <UserWisePgCount />
                      </Route>
                      <Route path={`/report/region-wise-site-list`}>
                        <RegionWiseSiteList />
                      </Route>
                      <Route path={`/report/region-wise-depot-list`}>
                        <RegionWiseDepotList />
                      </Route>
                      <Route path={`/report/statistic-data`}>
                        <StatisticData />
                      </Route>
                      <Route path={`/report/full-export`}>
                        <FullExport />
                      </Route>
                      <Route path={`/vehicle-full-export`}>
                        <VehicleFullExport />
                      </Route>
                      <Route path={`/payment-full-export`}>
                        <PaymentFullExport />
                      </Route>
                      <Route path={`/vehicles`}>
                        <VehicleIndex />
                      </Route>
                      <Route path={`/vehicle/master`}>
                        <VehicleMaster />
                      </Route>
                      <Route path={`/vehicle/dashboard`}>
                        <VehicleDashboard />
                      </Route>
                      <Route path={`/dashboard/gen`}>
                        <DashboardGen />
                      </Route>
                      <Route path={`/routine/dashboard`}>
                        <RoutineDashboard />
                      </Route>
                      <Route path={`/routine/observation`}>
                        <RoutineObservation />
                      </Route>
                      <Route path={`/site-access/dashboard`}>
                        <SiteAccessDashboard />
                      </Route>
                      <Route path={`/site-access/create`}>
                        <Create />
                      </Route>
                      <Route path={`/not-allowed`}>
                        <NotAllowed />
                      </Route>
                      <Route path={`/power-db/upload-powerdb`}>
                        <UploadPowerdb />
                      </Route>
                      <Route path={`/power-db/power-data-export`}>
                        <PowerDataExport />
                      </Route>
                      <Route path={`/power-db/power-data-manage`}>
                        <DataManage />
                      </Route>
                      <Route path={`/power-db/dashboard`}>
                        <PowerDataDashboard />
                      </Route>
                      <Route path={`/power-db/power-data-view`}>
                        <PowerDataView />
                      </Route>
                      <Route path={`/image-capture/list`}>
                        <ImageCaptureList />
                      </Route>
                      <Route path={`/image-capture/full-export`}>
                        <ImageFullExport />
                      </Route>
                      <Route path={`/image-capture/requests-view/:id`}>
                        <RequestsView />
                      </Route>
                      <Route path={`/image-capture/view`}>
                        <ImageCaptureView />
                      </Route>
                      <Route path={`/routing/data-export`}>
                        <RoutingDataExport />
                      </Route>
                      <Route path={`/pis/gen-service-export`}>
                        <PisGenServiceFullExport />
                      </Route>
                      <Route path={`/app-version`}>
                        <AppVersion />
                      </Route>
                      <Route path={`/pis/ac-service-export`}>
                        <PisACServiceFullExport />
                      </Route>
                      <Route path={`/pis/civil-service-export`}>
                        <PisCivilServiceFullExport />
                      </Route>
                      <Route path={`/pis/sc-service-export`}>
                        <PisScServiceFullExport />
                      </Route>
                      <Route path={`/site-access/list`}>
                        <SiteAccessList />
                      </Route>
                      <Route path={`/site-access/handler`}>
                        <SiteAccessHandler />
                      </Route>
                      <Route path={`/sites/create`}>
                        <SiteCreate />
                      </Route>
                      <Route path={`/sites/edit`}>
                        <SiteEdit />
                      </Route>
                      <Route path={`/site-access/edit/:id`}>
                        <SiteAccessEdit />
                      </Route>
                      <Route path={`/site-access/edit-handler/:id`}>
                        <SiteAccessEditHandler />
                      </Route>
                      <Route path={`/report/request-report`}>
                        <RequestReport />
                      </Route>
                      <Route path={`/dashboard-pg-cost`}>
                        <DashboardPgCost />
                      </Route>
                      <Route path={`/dashboard-pg-cost-depot`}>
                        <DashboardPgCostDepot />
                      </Route>
                      <Route path={`/dashboard-pg`}>
                        <DashboardPg />
                      </Route>
                      <Route path={`/pis/dashboard/gen`}>
                        <PISDashboardGen />
                      </Route>
                      <Route path={`/pis/dashboard/ac`}>
                        <PISDashboardAC />
                      </Route>
                      <Route path={`/dashboard-refuel`}>
                        <DashboardRefuel />
                      </Route>
                      <Route path={`/procurement-refuel-full-export`}>
                        <ProcurementRefuelFullExport />
                      </Route>
                      <Route path={`/refuel-full-export`}>
                        <RefuelFullExport />
                      </Route>
                      <Route path={`/fuel-balance-export`}>
                        <FuelBalanceExport />
                      </Route>
                      <Route path={`/dashboard-pg-pending-status`}>
                        <DashboardPgPendingStatus />
                      </Route>
                      <Route path={`/dashboard-pg-pending-status-depot`}>
                        <DashboardPgPendingStatusDepot />
                      </Route>
                      <Route path={`/dashboard-pg-live`}>
                        <DashboardPgLive />
                      </Route>
                      <Route path={`/dashboard-all-pg-live`}>
                        <DashboardAllPgLive />
                      </Route>
                      <Route path={`/dashboard-pg-status`}>
                        <DashboardPgStatus />
                      </Route>
                      <Route path={`/dashboard-exceptional`}>
                        <DashboardExceptional />
                      </Route>
                      <Route path={`/dashboard-map`}>
                        <DashboardMapView />
                      </Route>
                      <Route path={`/inbox`}>
                        <Inbox />
                      </Route>
                      <Route path={`/profile`}>
                        <Profile />
                      </Route>
                      <Route path={`/report`}>
                        <ReportIndex />
                      </Route>
                      <Route path={`/scheduler`}>
                        <Scheduler />
                      </Route>
                      <Route path={`/map`}>
                        <MapView />
                      </Route>
                      <Route path={`/sites/sld`}>
                        <Sld />
                      </Route>

                      {this.state.loginType == 19 ? (
                        <Route path={`/`}>
                          <SiteAccessList />
                        </Route>
                      ) : (
                        <Route path={`/`}>
                          <DashboardGen />
                        </Route>
                      )}

                      <Route path="*">{/* <NotFound /> */}</Route>
                    </Switch>
                  </BrowserRouter>
                </div>
              </div>
            )}
          </AuthenticatedTemplate>
        ) : (
          ""
        )}
        <UnauthenticatedTemplate>
          <div
            class="unathenticate-block"
            style={{
              margin: 0,
              textAlign: "center",
              marginBlock: 200,
            }}
          >
            <Do />
            {/* <SignInButton /> */}
            {/* <h5 className="card-title">Please sign-in to your DNS account.</h5> */}
          </div>
        </UnauthenticatedTemplate>
      </>
    );
  }
}

export default App;
