import React, { Component } from 'react'
import { getRoutingDataExport, getUserSites, getCkecklist } from "../../api/index";
import { getDonationCategories, getData } from "../../actions/index";
import { Table, Input, Tag, Space, DatePicker, Select, Row, Col, Button, message } from "antd";
import { FileExcelOutlined } from '@ant-design/icons';

import ReactExport from "react-data-export";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import './style.css';

import  generateExcel  from './excelGen';
import { get } from '../../api/api.client';

const moment = require('moment');

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
const { Option } = Select;
class RoutingDataExport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullArray: [],
            reduceArrary: null,
            startDate: "",
            endDate: "",
            loading: false,
            visitingData: [],
            acData: [],
            generatorData: [],
            batteryBankData: [],
            cabinetData: [],
            DCDUData: [],
            atsData: [],
            fcbData: [],
            iDBatteryRack: [],
            rectifierData: [],
            sharedOutOperatorsData: [],
            spdData: [],
            allSite: [],
            depotList: [],
            regionsList: [],
            regionIds: '',
            depotIds: '',
            siteIds: '',
            allSite_loading: false,
            buttonLodading: false,
            myArray: [],
            fullNewArray: [],
            allCheckList: null,

            responseData : [],

            exportInformation: []



        };
    }
    componentDidMount() {
        this.props.getData();
        this.getDataSitesByuser();
        this.getDataCkecklist();
        var _depotList = [];
        var _regionsList = [];
        this.props.depots.map((depot) => {
            _depotList.push({ value: depot.id, label: depot.name })
        })
        this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((regions) => {
            _regionsList.push({ value: regions.id, label: regions.name })
        })
        this.setState({
            depotList: _depotList,
            regionsList: _regionsList
        })
    }

    getDataCkecklist = async () => {
        var res = await getCkecklist();
        var _allSite = [];
        if (res.success) {
            this.setState({
                allCheckList: res.data
            })
        }
    };

    getDataSitesByuser = async () => {
        var res = await getUserSites();
        var _allSite = [];
        if (res.success) {
            res.data.map((sites) => {
                _allSite.push({ value: sites.id, label: sites.siteName })
            })
            this.setState({
                allSite_loading: false,
                allSite: _allSite,
            });
        }
    };

    fecthPageCount = async (data) => {
        var res = await getRoutingDataExport(data);
        if (res.success) {
            let keyArray = Object.keys(res.data.data);
            let pageCount = res.data.pageCount;
            return {
                pageCount: pageCount,
                keyArray: keyArray
            }
        }
    }

    formatDate = (dateString) => {
        return moment(dateString).format('YYYY:MM:DD HH:mm');
    };

    fetchDataForExport = async (data) => {
        this.setState({
            loading: true,
            exportInformation: []
        });
        var form = new FormData();
        form.append("RequestDateFrom", this.state.startDate);
        form.append("RequestDateTo", this.state.endDate);
        form.append("teamMember", 0);
        form.append("pageNo", 0);
        form.append("siteIds", this.state.siteIds);
        form.append("depotIds", this.state.depotIds); 
        form.append("regionIds", this.state.regionIds);
        form.append("recodesPerPage", 100);
        let pageCount = await this.fecthPageCount(form);
        let jsonDataInformation = {
            "ATS": [],
            "Rectifier": [],
            "Wind": [],
            "AC": [],
            "FCB": [],
            "Solar": [],
            "Cabinet": [],
            "SPD": [],
            "Bulk Tank": [],
            "Shared Out": [],
            "DCDU": [],
            "visitiInfo": [],
            "ID Battery Rack": [],
            "Battery Bank": [],
            "Generator": [],
            "bss": [],
            "comments": [],
            "mw": [],
            "ipCore": [],
            "ofn": [],
            "physicalObservetion": []
        };
        for (let i = 0; i < pageCount.pageCount; i++) {
            var form = new FormData();
            form.append("RequestDateFrom", this.state.startDate);
            form.append("RequestDateTo", this.state.endDate);
            form.append("teamMember", 0);
            form.append("pageNo", i);
            form.append("siteIds", this.state.siteIds);
            form.append("depotIds", this.state.depotIds); 
            form.append("regionIds", this.state.regionIds);
            form.append("recodesPerPage", 100);
            let pageData = await getRoutingDataExport(form);
            if (pageData.success) {
                let data = pageData.data.data;
                Object.keys(data).forEach((key) => {
                    if (jsonDataInformation[key]) {
                        if (key === 'visitiInfo') {
                            jsonDataInformation[key].push(
                                ...data[key].map((x) => {
                                    const mainProperties = {
                                        VisitCode: x?.visitCode,
                                        User: x?.user,
                                        SiteID: x?.siteId,
                                        Site: x?.siteName,
                                        RoutingType: x?.routingType,
                                        CreatedOn: this.formatDate(x?.createdOn),
                                        Visit_Status: x?.visit_status,
                                        Region: x?.region,
                                        SubRegion: x?.subRegion,
                                    };

                                    delete x.visitCode;
                                    delete x.user;
                                    delete x.siteId;
                                    delete x.siteName;
                                    delete x.routingType;
                                    delete x.createdOn;
                                    delete x.visit_status;
                                    delete x.region;
                                    delete x.subRegion;

                                    const additionalProperties = Object.keys(x).reduce((acc, curr) => {
                                        if (!curr.includes('Image') && !Object.keys(mainProperties).includes(curr)) {
                                            acc[curr] = x[curr];
                                        }
                                        return acc;
                                    }, {});

                                    return {
                                        ...mainProperties,
                                        ...additionalProperties,
                                    };
                                })
                            );
                        } else {
                            jsonDataInformation[key].push(...data[key].map((x) => {
                                return {
                                    visitCode: x?.visitCode,
                                    ...x
                                };
                            }));
                        }
                    } else {
                        console.warn(`Key ${key} does not exist in jsonDataInformation`);
                    }
                });
            }
        }
        this.setState({
            loading: false,
            exportInformation: jsonDataInformation
        });
    };


    getDataexpot = async (data) => {
        this.setState({
            visitingData: [],
            acData: [],
            generatorData: [],
            batteryBankData: [],
            cabinetData: [],
            DCDUData: [],
            fcbData: [],
            iDBatteryRack: [],
            rectifierData: [],
            spdData: [],
            sharedOutOperatorsData: [],
            loading: true
        });
        let responseData = [];
        var res = await getRoutingDataExport(data);
        if (res.success == true) {
            let pageCount = res?.data?.pageCount;
            if (pageCount == 0) {
                this.setNewArray(res.data.data);
                this.setState({
                    visitingData: [],
                    acData: [],
                    generatorData: [],
                    batteryBankData: [],
                    cabinetData: [],
                    DCDUData: [],
                    atsData: [],
                    fcbData: [],
                    iDBatteryRack: [],
                    rectifierData: [],
                    spdData: [],
                    sharedOutOperatorsData: [],
                    buttonLodading: false,
                    loading: false
                });
            } else if (pageCount == 1) {
                responseData.push(res.data.data);
                this.setState({
                    visitingData: res.data.data.visitiInfo,
                    acData: res.data.data.AC,
                    generatorData: res.data.data.Generator,
                    batteryBankData: res.data.data['Battery Bank'],
                    cabinetData: res.data.data.Cabinet,
                    DCDUData: res.data.data.DCDU,
                    atsData: res.data.data.ATS,
                    fcbData: res.data.data.FCB,
                    iDBatteryRack: res.data.data['ID Battery Rack'],
                    rectifierData: res.data.data.Rectifier,
                    spdData: res.data.data.SPD,
                    sharedOutOperatorsData: res.data?.data['Shared Out -Operators'],
                    buttonLodading: false,
                    loading: false
                }, () => {
                    if (this.state.sharedOutOperatorsData == undefined) {}
                });
            } else if (pageCount > 1) {
                for (let i = 0; i < pageCount; i++) {
                    let pageData = await this.getDataexpot2(i, pageCount);
                    responseData.push(...pageData);
                    if (i == pageCount - 1) {
                        this.setState({
                            loading: false,
                        });
                    }
                }
            }
        }
        // console.log(responseData);
        
        this.setState({
            exportInformation: responseData
        });
    };


    getDataexpot2 = async (data, pagecout) => {
        var form = new FormData();
        form.append("RequestDateFrom", this.state.startDate);
        form.append("RequestDateTo", this.state.endDate);
        form.append("teamMember", 0);
        form.append("pageNo", data);
        form.append("siteIds", this.state.siteIds);
        form.append("depotIds", this.state.depotIds);
        form.append("regionIds", this.state.regionIds);
        form.append("recodesPerPage", 100);
        var res = await getRoutingDataExport(form);
        //console.log("getsiteAccessExport");




        if (res.success) {
            console.log(res);
            this.setState({
                fullArray: [...this.state.fullArray, ...res.data.data.visitiInfo],
                acData: [...this.state.acData, ...res.data.data.AC],
                generatorData: [...this.state.generatorData, ...res.data.data.Generator]
            }, () => {
                if (data + 1 == pagecout) {
                    console.log('data+1')
                    console.log(data + 1)
                    //this.setNewArray(this.state.fullArray)
                    this.setState({
                        buttonLodading: false
                    })


                }
                console.log("343434")
                console.log(this.state.fullArray)
                this.setState({


                    visitingData: this.state.fullArray,
                });

            })

        }
    };
    searchRegionmChange = (event) => {
        var dd = event
        //console.log(dd)
        var subRegion = [];
        var _depotList1 = [];
        var subRegionIdArray = [];

        this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)).map((subR) => {
            subRegionIdArray.push(subR.id)
        })

        this.setState({
            regionIds: subRegionIdArray
        });

        //console.log(this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)));
        subRegion = this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id));
        //console.log(this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)));
        this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)).map((depot) => {
            _depotList1.push({ value: depot.id, label: depot.name })
        })

        this.setState({
            depotList: _depotList1

        })

    }
    searchDepottemChange = (event) => {

        this.setState({
            depotIds: event
        });
    }
    searchSiteemChange = (event) => {

        this.setState({
            siteIds: event
        });
    }
    onChangeDate = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
            startDate: dateString,
        });
    };
    onChangeDate2 = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
            endDate: dateString,
        });
    };

    searchClick = () => {
        this.setState({ buttonLodading: true })
        if (this.state.startDate == "" || this.state.endDate == "") {
            message.error("Please select date range");
            this.setState({ buttonLodading: false })
            return;
        } else {
            this.fetchDataForExport();
        }
    };

    setSectionName = (data) => {
        console.log(data)
        const cats = data.reduce((acc, curr) => {
            if (curr.sectionName) {
                const { sectionName } = curr;
                const currentItems = acc[sectionName];

                return {
                    ...acc,
                    [sectionName]: currentItems ? [...currentItems, curr] : [curr]
                };
            }
            return acc;

        }, {});
        console.log(cats);

    }
    thirdSetArray = (data) => {
        console.log(data)
        const arrayResult = Object.keys(data).map(room => {

            return {

                data: data[room].checklist?.map(e => ({
                    ...e, reqId: data[room].reqId, siteId: data[room].siteId, siteName: data[room].siteName, visitCode: data[room].visitCode, sectionId: data[room].sectionId, sectionName: data[room].sectionName, itemId: data[room].itemId, routingVisitStatus: data[room].routingVisitStatus, createdOn: data[room].createdOn, updatedOn: data[room].updatedOn, userName: data[room].userName
                }))
            }
        });
        var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], [])



        const filtered = alldata.filter(obj => {
            return obj.id;
        }, () => {

        });

        console.log(filtered)



        this.setState({
            reduceArrary: filtered,
            loading: false
        })



    }

    secondSetAarray = (data) => {
        console.log(data)
        const arrayResult = Object.keys(data).map(room => {
            return {
                data: data[room]?.item?.map(e => ({
                    ...e, reqId: data[room].reqId, siteId: data[room].siteId, siteName: data[room].siteName, visitCode: data[room].visitCode, sectionId: data[room].sectionId, sectionName: data[room].sectionName, routingVisitStatus: data[room].routingVisitStatus, createdOn: data[room].createdOn, updatedOn: data[room].updatedOn, userName: data[room].userName
                }))
            }
        });
        var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], [])


        // console.log(alldata)
        this.thirdSetArray(alldata)


    }
    redioOption(data) {

        if (Object.keys(data.extraData).length > 0) {
            const filtered12 = JSON.parse(data.extraData?.option_choices).filter(obj => {
                return obj.value == data.answer;
            });

            return filtered12.length > 0 ? (filtered12[0].name) : (data.answer);

        } else { return data.answer; }





    }
    singleChoiceOption(data) {

        if (Object.keys(data.extraData).length > 0) {
            const filtered12 = JSON.parse(data.extraData?.option_choices).filter(obj => {
                return obj.value == data.answer;
            });

            return filtered12.length > 0 ? (filtered12[0].name) : (data.answer);

        } else { return data.answer; }
    }
    exportToCSV = () => {
        const timestamp = Date.now();
        var wscols = [
            { wch: 30 }, // "characters"
            { wpx: 100 }, // "pixels"
            { wch: 20 }, // "pixels"
            ,
           // { hidden: true } // hide column
        ];
        var ws2cols = [
            { wch: 30 }, // "characters"

            ,
           // { hidden: true } // hide column
        ];
        var wsGencols = [
            { wch: 30 }, // "characters"

            ,
        ];
        const myHeader = ["visitCode", "user", "site"];
        const myHeaderAc = ["visitCode"];
        const myHeaderGen = ["visitCode"];
        const myHeaderbb = ["visitCode"];
        const myHeadercab = ["visitCode"];
        const myHeaderdcdu = ["visitCode"];
        const myHeaderdCommon = ["visitCode"];
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(this.state.visitingData, { header: myHeader });
        const worksheetAc = XLSX.utils.json_to_sheet(this.state.acData!==undefined?(this.state.acData):([]),{ header: myHeaderAc });
        const worksheetGen = XLSX.utils.json_to_sheet(this.state.generatorData!==undefined?(this.state.generatorData):([]), { header: myHeaderGen });
        const worksheetBB = XLSX.utils.json_to_sheet(this.state.batteryBankData!==undefined?(this.state.batteryBankData):([]), { header: myHeaderbb });
        const worksheetCab = XLSX.utils.json_to_sheet(this.state.cabinetData!==undefined?(this.state.cabinetData):([]), { header: myHeadercab });
        const worksheetDCDU = XLSX.utils.json_to_sheet(this.state.DCDUData!==undefined?(this.state.DCDUData):([]), { header: myHeaderdcdu });
        const worksheetAts = XLSX.utils.json_to_sheet(this.state.atsData!==undefined?(this.state.atsData):([]), { header: myHeaderdCommon });
        const worksheetFcb = XLSX.utils.json_to_sheet(this.state.fcbData!==undefined?(this.state.fcbData):([]), { header: myHeaderdCommon });
        const worksheetIdBR = XLSX.utils.json_to_sheet(this.state.iDBatteryRack!==undefined?(this.state.iDBatteryRack):([]), { header: myHeaderdCommon });
        const worksheetIdrectifire = XLSX.utils.json_to_sheet(this.state.rectifierData!==undefined?(this.state.rectifierData):([]), { header: myHeaderdCommon });
        const worksheetspd = XLSX.utils.json_to_sheet(this.state.spdData!== undefined?(this.state.spdData):([]), { header: myHeaderdCommon });
        const worksheetShareOutOperators = XLSX.utils.json_to_sheet(this.state.sharedOutOperatorsData!== undefined?(this.state.sharedOutOperatorsData):([]), { header: myHeaderdCommon });
        worksheet['!cols'] = wscols;
        worksheetAc['!cols'] = ws2cols;
        worksheetGen['!cols'] = wsGencols;
        worksheetBB['!cols'] = ws2cols;
        worksheetCab['!cols'] = ws2cols;
        worksheetDCDU['!cols'] = ws2cols;
        worksheetAts['!cols'] = ws2cols;
        worksheetFcb['!cols'] = ws2cols;
        worksheetIdBR['!cols'] = ws2cols;
        worksheetIdrectifire['!cols'] = ws2cols;
        worksheetspd['!cols'] = ws2cols;
        worksheetShareOutOperators['!cols'] = ws2cols;

        // File Genaration Logic Write Here
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Visiting Data');        
        XLSX.utils.book_append_sheet(workbook, worksheetAc, 'AC');
        XLSX.utils.book_append_sheet(workbook, worksheetGen, 'Generator');
        XLSX.utils.book_append_sheet(workbook, worksheetBB, 'Battery Bank');
        XLSX.utils.book_append_sheet(workbook, worksheetCab, 'Cabinet');
        XLSX.utils.book_append_sheet(workbook, worksheetDCDU, 'DCDU');
        XLSX.utils.book_append_sheet(workbook, worksheetAts, 'ATS');
        XLSX.utils.book_append_sheet(workbook, worksheetFcb, 'FBC');
        XLSX.utils.book_append_sheet(workbook, worksheetIdBR, 'ID Battery Rack');
        XLSX.utils.book_append_sheet(workbook, worksheetIdrectifire, 'Rectifire');
        XLSX.utils.book_append_sheet(workbook, worksheetspd, 'SPD');
        XLSX.utils.book_append_sheet(workbook, worksheetShareOutOperators, 'Shared Out -Operators');
        XLSX.writeFile(workbook, 'routine_export'+timestamp+'.xlsx');
        // const ws = XLSX.utils.json_to_sheet(this.state.visitingData);
        // const ws2 = XLSX.utils.json_to_sheet(this.state.visitingData);
        // /* custom headers */
        // XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
        // XLSX.utils.sheet_add_aoa(ws2, [["visitCode", "Birthday", "Age", "City"]], { origin: "A1" });
        // const wb = { Sheets: { data: ws, data2: ws2 }, SheetNames: ["data", "data2"] };
        // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        // const data = new Blob([excelBuffer], { type: fileType });
        // FileSaver.saveAs(data, 'dddd' + fileExtension);

    }
    setNewArray = (data) => {
        console.log('newArrat');
        console.log(data);
        data.map((x, y) => {
            var genArray = [];
            let isSetGeneral_id = false
            let isSite_Status = ''
            x?.checklists?.map((a, b) => {
                a = { ...a, recode: x.routingVisitCode }

                isSetGeneral_id = true

                this.setState(prevState => ({
                    fullNewArray: [a, ...this.state.fullNewArray]
                }), () => {

                })

            })
        })
        this.setSectionName(this.state.fullNewArray)
    }

    render() {
        const columns = [
            {
                title: <span className="textStyles-small">Visit Code</span>,
                dataIndex: "VisitCode",
                key: "VisitCode",
                render: (item) => <span className='textStyles-small'>{item}</span>,
            },
            {
                title: <span className="textStyles-small">Site</span>,
                dataIndex: "Site",
                key: "Site",
                render: (item) => <span className='textStyles-small'>{item}</span>,
            },
            {
                title: <span className="textStyles-small">Routing Type</span>,
                dataIndex: "RoutingType",
                key: "RoutingType",
                render: (item) => <span className='textStyles-small'>{item}</span>,
            },
            {
                title: <span className="textStyles-small">Created On</span>,
                dataIndex: "CreatedOn",
                key: "CreatedOn",
                render: (item) => <span className='textStyles-small'>{moment(item).format('YYYY-MM-DD HH:mm:ss')}</span>,
            },

            {
                title: <span className="textStyles-small">User</span>,
                dataIndex: "User",
                key: "User",
                render: (item) => <span className='textStyles-small'>{item}</span>,
            },
        ];
       

        return (
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">Routine Data Export</h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Routine</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Data Export</a></li>

                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div style={{ marginTop: 20, padding: 10, borderRadius: 10, backgroundColor: 'white' }}>
                    <Row>
                        <Col span={6}>
                            <DatePicker
                                placeholder="Start Date"
                                style={{ width: "98%", height: 32 }}
                                onChange={this.onChangeDate}
                                className='borderedSelect'
                            />
                        </Col>

                        <Col span={6}>
                            <DatePicker
                                placeholder="End Date"
                                style={{ width: "98%", height: 32 }}
                                onChange={this.onChangeDate2}
                                className='borderedSelect'
                            />
                        </Col>

                        <Col span={6}>
                            <Select
                                mode="multiple"
                                showSearch
                                name="regionId"
                                onChange={(e) => this.searchRegionmChange(e)}
                                placeholder="Region"
                                style={{ width: "98%", height: 32 }}
                                className='borderedSelect'
                                bordered={false}

                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {this.state.regionsList.map((item, index) => {
                                    return <Option value={item.value}>{item.label}</Option>;
                                })}
                            </Select>
                        </Col>

                        <Col span={6}>
                            <Select
                                mode="multiple"
                                showSearch
                                className='borderedSelect'
                                bordered={false}
                                name="depotId"
                                onChange={(e) => this.searchDepottemChange(e)}
                                placeholder="Depot"
                                style={{ width: "98%", height: 32 }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {this.state.depotList.map((item, index) => {
                                    return <Option value={item.value}>{item.label}</Option>;
                                })}
                            </Select>
                        </Col>

                        <Col span={6} style={{ marginTop: 10 }}>
                            <Select
                                mode="multiple"
                                placeholder="Site"
                                style={{ width: "98%", height: 32 }}
                                className='borderedSelect'
                                bordered={false}
                                loading={this.state.allSite_loading}
                                name="siteId"
                                onChange={(e) => this.searchSiteemChange(e)}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {this.state.allSite.map((item, index) => {
                                    return <Option value={item.value}>{item.label}</Option>;
                                })}
                            </Select>
                        </Col>

                        <Col span={18} style={{ marginTop: 10 }}>
                            <Button
                                type='primary'
                                onClick={this.searchClick}
                                class="btn btn-primary"
                                style={{ marginBottom: '10px', backgroundColor: '#38B36E', borderColor: '#38B36E', borderRadius: 5 }}
                            >
                                <span className='textStyles-small'>
                                    Fetch Data
                                </span>
                            </Button>
                            
                        </Col>
                    </Row>
                </div>

                <div className='row' style={{ marginTop: 20 }}>
                    <div className="col-lg-12 col-xl-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row ">
                                    <div className="col">
                                        <h4 className="card-title">
                                        <span className='textStyles-small'
                                        style={{fontSize: 14}}
                                        >Routine Full Export</span>
                                    <Button
                                        className="btn btn-primary"
                                        style={{ marginBottom: '10px', backgroundColor: '#38B36E', borderColor: '#38B36E', borderRadius: 5, marginLeft: 3, float: 'right' }}
                                        onClick={() => generateExcel(this.state.exportInformation)}>
                                        <FileExcelOutlined style={{color: 'white'}}/> <span 
                                        style={{color: 'white'}}
                                        className='textStyles-small'>Export</span>
                                    </Button>

                                        </h4>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">

                                <Table
                                    size="small"
                                    columns={columns} 
                                    loading={this.state.loading}
                                    dataSource={this.state.exportInformation?.visitiInfo}
                                />

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    //console.log("state", state);
    return {
        organization_count: state.organization.length,
        regions: state.regions,
        depots: state.depots,
        sites_count: state.sites.length,
        vendor_count: state.vendor.length,
        roles_count: state.roles.length,
    };
}
export default withRouter(connect(mapStateToProps, { getDonationCategories, getData })(
    RoutingDataExport
));

