import React, { Component, Fragment } from 'react'
import { DatePicker, Input, InputNumber, Tag, Space, Button, Row, Col, Drawer, Select, Spin, Switch, message } from "antd";
import { updateCaeDetails } from "../../api/index";
import moment from 'moment';



class Cae extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            tabaleLoading: false,
            switchStatus: true,
            objectSet:
            {
                "caeReportSubmitDate": null
            }
        };
    }

    componentDidMount() {
        console.log('tytqwe')
        console.log(this.props.details)
        this.setState({
            objectSet:
            {
                "caeReportSubmitDate": this.props.details.access_technologies_data?.caeReportSubmitDate,


            }
        });
        // console.log(this.props.details2)
    }
    onChangeInstalledDate = (date, dateString) => {
        // console.log(date);
        // console.log(dateString);
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                caeReportSubmitDate: dateString
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    };
    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }
    close = async () => {
        this.props.parentMethod();
        this.setState({
            objectSet:
            {
                "caeReportSubmitDate": this.props.details.access_technologies_data?.caeReportSubmitDate,


            }
        });
        this.setState({
            switchStatus: true
        });
    }
    dataSetToUpdate = async () => {
        console.log(this.props.detailsSite)
        let formData = new FormData(); //formdata object
        let setobjectToUpate = { "caeReportSubmitDate": this.state.objectSet.caeReportSubmitDate }
        formData.append("object", JSON.stringify(setobjectToUpate)); //append the values with key, value pair
        formData.append("id", this.props.details?.access_technologies_data?.id);
        var res = await updateCaeDetails(formData);
        if (res.success) {
            message.success('Successfully Update!')
            this.props.parentMethod();
            this.setState({
                switchStatus: true
            })
        } else {
            message.warning(' Something went wrong!')
        }
    }

    render() {
        const { details } = this.props.details
        return (
            <Fragment>
                {this.props.details.access_technologies_data != null ? (
                    <div style={{ width: "100%", backgroundColor: "white", padding: "20px", borderRadius: "5px" }}>
                        <div className="d-flex justify-content-end align-items-center" style={{ marginBottom: "10px", marginRight: "10px" }}>
                            {this.state.switchStatus
                                ? <button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.dataUpdate()}
                                style={{ borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                                > <i class="mdi mdi-pencil-outline" style={{color: 'white'}}></i></button>
                                : <div class="d-flex justify-content-end align-items-center">
                                    <button type="button" class="btn btn-soft-secondary  textStyles-small" onClick={() => this.close()} style={{ marginRight: "10px" }}> Cancel </button>
                                    <button type="button" class="btn btn-soft-primary  textStyles-small" onClick={() => this.dataSetToUpdate()}> Submit to Update </button>
                                </div>
                            }
                        </div>
                        <ul class="list-group">
                            <Row span={24}>
                                <Col span={12}>
                                    <div style={{ width: '98%' }}>
                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                            <div>
                                                <span className="textStyles-small">Latest CEA Report Submission Date</span>
                                            </div>
                                            {this.state.switchStatus ? (<>
                                                {this.props.details.access_technologies_data?.caeReportSubmitDate != null ?
                                                    (<span class="badge badge-outline-success textStyles-small">{this.props.details.access_technologies_data?.caeReportSubmitDate}</span>) :
                                                    (<span class="badge badge-outline-warning textStyles-small">Currently Not Set</span>)}</>) : (<>
                                                        <DatePicker
                                                            style={{ width: '150px', height: 34 }}
                                                            className='borderedSelect'
                                                            defaultValue={this.state.objectSet?.caeReportSubmitDate != null ? (moment(this.state.objectSet?.caeReportSubmitDate)) : ("")}
                                                            onChange={this.onChangeInstalledDate} /> </>)}
                                        </li>
                                    </div>
                                </Col>
                            </Row>
                        </ul>
                    </div>
                ) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h4 style={{ color: "#c5c5c5", fontSize: 16 }} className="textStyles-small">No Dat Found Access Technologies</h4></div>)}
            </Fragment>
        )
    }
}
export default Cae;
